import * as actionTypes from "../actions/actionTypes";

const initialState: ReRunSearchState = {
  caseId: null,
  address: "",
  ageRangeFrom: "",
  ageRangeTo: "",
  city: "",
  dateOfBirth: "",
  dateOfLoss: "",
  emailFormValues: [],
  employer: "",
  firstName: "",
  lastName: "",
  phoneFormValues: [],
  state: "",
  usernameFormValues: [],
  zipCode: "",
};

const reducer = (state: ReRunSearchState = initialState, action: ActionType): ReRunSearchState => {
  switch (action.type) {
    case actionTypes.SET_ReRun_Search:
          return {
              address: action.payload?.address,
              ageRangeFrom: action.payload?.ageRangeFrom?.toString() ?? "",
              ageRangeTo: action.payload?.ageRangeTo?.toString() ?? "",
              caseId: action.payload?.caseId,
              state: action.payload?.state,
              dateOfBirth: action.payload?.dateOfBirth == null || action.payload?.dateOfBirth == undefined ? "" :
                      new Date(new Date(action.payload?.dateOfBirth).getTime() - new Date(action.payload?.dateOfBirth).getTimezoneOffset() * 60000).toISOString().split("T")[0],
              dateOfLoss: action.payload?.dateOfLoss == null || action.payload?.dateOfLoss == undefined ? "" :
                      new Date(new Date(action.payload?.dateOfLoss).getTime() - new Date(action.payload?.dateOfLoss).getTimezoneOffset() * 60000).toISOString().split("T")[0],
              emailFormValues: action.payload?.emails ?? [""],
              employer: action.payload?.employer,
              firstName: action.payload?.firstName,
              lastName: action.payload?.lastName,
              phoneFormValues: action.payload?.phones?.map((p:string) => p.replace(/[\s()+-]/g, '')) || [""],
              usernameFormValues: action.payload?.userNames ?? [""],
              zipCode: action.payload?.zipCode,
              city: action.payload.city,
          };

    default:
      return state;
  }
};

export default reducer;
