import React from "react";
import withTranslate from "../../infrastructure/HOC/withTranslate";
import useTranslate from "../../infrastructure/hooks/useTranslate";


const ServerError: React.FC = () => {

    const { t } = useTranslate();
    return (
        <div className="container">
            <div className="align-middle mt-5">
                <h2 className="title text-center">{t('ServerError')}</h2>
            </div>
        </div>
    );
}
export default withTranslate(ServerError);
