import React, { useEffect, useState } from "react";
import GlbaDppaOptionsModel from "../../domain/entities/auth/models/GlbaDppaOptionsModel";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import RegistrationDataApi from "../../data/registrationData/RegistrationDataApi";
import { Col, Row } from "react-bootstrap";

type Props = {
    glbaSelectedOption?: number;
    dppaSelectedOption?: number;
    disabled?: boolean;
    onChangeGlba: (id: number) => void;
    onChangeDppa: (id: number) => void;
};

const GlbaDppaOptions: React.FC<Props> = ({ disabled = false, ...props }: Props) => {

    const [options, setOptions] = useState({dppaOptions: [], glbaOptions: []} as GlbaDppaOptionsModel);

    useEffect(() => {
        const registrationDataRepo = new RegistrationDataApi(new HttpClient())
        registrationDataRepo.GetGlbaDppaData().then(res => {
            setOptions(res.value ?? {} as GlbaDppaOptionsModel);
        });
    }, []);

    return (
        <div className="glba-dppa-options">
            <div>
                <div className="glba-dppa-intro">
                    Social Eprofiler may contain data governed by the Gramm-Leach-Bliley Act, (GLBA) and the Driver&apos;s Privacy Protection Act (DPPA),
                    or similar state laws. If your intended use is permitted by GLBA or DPPA&apos; select the applicable permissible use below.
                    If you do not have a permissible use, your account will be reviewed to determine your eligibility to access our database.
                    <br />
                    <br/>
                </div>
                <Row className="option-title">
                    <Col md="auto" className="p-0 section-title">GLBA selection - Grahamm-Leach-Blily Act</Col>
                    <Col><hr /></Col>
                </Row>
                {
                    (options as GlbaDppaOptionsModel).glbaOptions.map(o =>
                        <Row key={o.id}>
                            <Col md="auto" className="p-0"><input disabled={disabled} type="radio" id={`glba-${o.id}`} name='glba' value={o.text} checked={props.glbaSelectedOption ? o.id == props.glbaSelectedOption : true} onChange={() => props.onChangeGlba(o.id)} /></Col>
                            <Col><label htmlFor={`glba-${o.id}`} >{o.text}</label></Col>
                        </Row>
                    )
                }
            </div>
            <div>
                <Row className="option-title">
                    <Col md="auto" className="p-0 section-title">DPPA selection - Driver&apos;s Privacy Protection Act</Col>
                    <Col><hr /></Col>
                </Row>
                {
                    (options as GlbaDppaOptionsModel).dppaOptions.map(o =>
                        <Row key={o.id}>
                            <Col md="auto" className="p-0"><input disabled={disabled} type="radio" id={`dppa-${o.id}`} name='dppa' value={o.text} checked={props.dppaSelectedOption ? o.id == props.dppaSelectedOption : true} onChange={() => props.onChangeDppa(o.id)} /></Col>
                            <Col><label htmlFor={`dppa-${o.id}`} >{o.text}</label></Col>
                        </Row>
                    )
                }
            </div>
        </div>
    );
};

export default GlbaDppaOptions;
