import { ProgressBar } from "react-bootstrap";
import React from "react";

type StatusProgressBarProps = {
    completePercentage: number,
    creationDate: Date
}

function StatusProgressBar(props: StatusProgressBarProps) {
    const timeDiffrence = (new Date().getTime() - props.creationDate.getTime() / 1000);
    let html = <span key={Math.random()} className="status-span-inprogress">
        Created
    </span>
    if (timeDiffrence <= 30 && props.completePercentage > 0) {
        html = <span key={Math.random()} className="status-span-inprogress">
            In Progress
        </span>
    }
    if (timeDiffrence > 30 && props.completePercentage > 0) {
        if (props.completePercentage < 20) {
            html = <ProgressBar now={props.completePercentage} variant="completed" >
            </ProgressBar>
        }
        else {
            html = <ProgressBar now={props.completePercentage} label={`${props.completePercentage}%`} variant="completed" >
            </ProgressBar>
        }
    }
    return (
        <>
            {
                html
            }
        </>
    );
}
export default StatusProgressBar;