import React from "react";

const BackArrowSvgIcon: React.FC = () => {
    return (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20L11.41 18.59L3.83 11H24V9H3.83L11.41 1.41L10 0L0 10L10 20Z" fill="black"/>
        </svg>
    );
};

export default BackArrowSvgIcon;