import * as actionTypes from '../actions/actionTypes';

const initialState: LocalizationState = {
    locale: "",
    translations: {}
};

const reducer = (state: LocalizationState = initialState, action: ActionType): LocalizationState => {
    switch (action.type) {
        case actionTypes.INIT_LOCALIZATION:
            return {
                ...state,
                locale: action.payload.locale,
                translations: action.payload.translations
            };

        default: return state;
    }
};

export default reducer;