import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable, {
  ColumnDescription,
  TableChangeState,
} from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import ErrorAlert from "../../components/ErrorAlert";
import FloatingLabelControl from "../../components/FloatingLabelControl";
import SocialConnectionListViewModel from "../../view-model/usersettings/SocialConnectionListViewModel";
import CreateSocialConnection from "./CreateSocialConnection";
import UserSocialConnectionModel from "../../../domain/entities/usersettings/UserSocialConnectionModel";

type SocialConnectionListComponentState = {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  pageData: UserSocialConnectionModel[];
  showConfirmationModal: boolean;
  showPasswordModal: boolean;
  showAddModal: boolean;
  userPassword: string;
  selectedItemId: string;
  currentConnectionPassword: string;
  isLoading: boolean;
  isSuccess: boolean;
  isShowError: boolean;
  errorMessages: string[];
};

const SocialConnectionList: React.FC = () => {
  const { viewModel, subscription } = useViewModel(
    SocialConnectionListViewModel,
    [new UserSettingApi(new HttpClient())]
  );
  const [state, setState] = useState<SocialConnectionListComponentState>({
    pageSize: viewModel.pageSize,
    pageNumber: viewModel.pageNumber,
    selectedItemId: "",
    currentConnectionPassword: "",
    totalCount: viewModel.totalCount,
    pageData: viewModel.pageData,
    showConfirmationModal: false,
    showPasswordModal: false,
    userPassword: "",
    showAddModal: false,
    isSuccess: false,
    isLoading: false,
    isShowError: false,
    errorMessages: [],
  });


  const search = useLocation().search;
  const urlPageNumber = new URLSearchParams(search).get("pg");

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );
    
    if (urlPageNumber) {
      let pageNumber = parseInt(urlPageNumber);
      viewModel.getPage(--pageNumber);
    } else {
      viewModel.getPage(0);
    }
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  const passwordFormatter = (
    cell: any,
    row: any,
    rowIndex: any,
    formatExtraData: any
  ) => {
    return (
      <>
        <span
          className=""
          onClick={(): void => viewModel.openPasswordModal(row.id)}
        >
          {formatExtraData.modelItemId &&
            formatExtraData.modelItemId === row.id &&
            formatExtraData.currentConnectionPassword ? (
            formatExtraData.currentConnectionPassword
          ) : (
            <div className="password-td">
              <p className="password-td-text">**************</p>
              <i className="password-td-icon fa fa-eye"></i>
            </div>
          )}
          {/* <a key={row.id} className="btn btn-primary px-3" onClick={(): void => viewModel.openPasswordModal(row.id)}>
                        <i className="bi bi-eye"></i>
                    </a> */}
        </span>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: "socialConnectionTypeName",
      text: "Social Media Name",
    },
    {
      dataField: "username",
      text: "Username",
    },
    {
      dataField: "password",
      text: "Password",
      formatter: passwordFormatter,
      formatExtraData: {
        modelItemId: state.selectedItemId,
        currentConnectionPassword: state.currentConnectionPassword,
      },
    },
    {
      dataField: "VerificationStatus",
      text: "Status",
      formatter: (cell, row) => (
        <>
          {row.verifiedStatusEnum == 0 && (
            <h4>
              <span className="status-td pending">Pending</span>
            </h4>
          )}
          {row.verifiedStatusEnum == 1 && (
            <h4>
              <span className="status-td verified">Verified</span>
            </h4>
          )}
          {row.verifiedStatusEnum == 2 && (
            <h4>
              <span className="status-td failed">Failed</span>
            </h4>
          )}
        </>
      ),
    },
    {
      dataField: "",
      text: "Actions",
      formatter: (cell, row) => (
        <div className="actions-td">
          <i
            className="bi bi-pen"
            onClick={() => viewModel.openAddModal(row.id)}
          ></i>
          <i
            className="bi bi-trash"
            onClick={(): void => viewModel.openDeleteModal(row.id)}
          ></i>
        </div>
      ),
    },
  ];

  const onTableChange = (type: any, newState: TableChangeState<any>) => {
    viewModel.getPage(--newState.page);
    let oldUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    oldUrl += "?pg=" + ++newState.page;
    window.history.pushState({ path: oldUrl }, "", oldUrl);
  };

  const onPageSizeChange = (pageSize: number, page: number) => {
    viewModel.pageSize = pageSize;
    viewModel.getPage(--page);
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  return (
    <>
      {state.isShowError && (
        <div className="row g-3 mt-5">
          <ErrorAlert
            headerMessage="ListingErrorHeaderMessage"
            bodyMessage="ListingErrorBodyMessage"
          />
        </div>
      )}
      {
        <div className="row g-3 mt-5">
          {!state.isLoading && (
            <>
              <div className="row title-div social-connection mb-5">
                <button
                  className="add-btn"
                  onClick={() => viewModel.openAddModal("")}
                >
                  <span className="add-btn-content">
                    <p className="add-btn-text">New Connection</p>
                    <img
                      src="/images/add.svg"
                      className="add-btn-icon"
                      alt=""
                    />
                  </span>
                </button>
              </div>
              <div className="table-style">
                <BootstrapTable
                  keyField="id"
                  id="id"
                  key={"id"}
                  data={state.pageData}
                  columns={columns}
                  pagination={paginationFactory({
                    page: state.pageNumber + 1,
                    sizePerPage: state.pageSize,
                    totalSize: state.totalCount,
                    firstPageText: "First",
                    prePageText: "Back",
                    nextPageText: "Next",
                    lastPageText: "Last",
                    nextPageTitle: "First page",
                    prePageTitle: "Pre page",
                    firstPageTitle: "Next page",
                    lastPageTitle: "Last page",
                    paginationTotalRenderer: customTotal,
                    onSizePerPageChange: onPageSizeChange,
                    sizePerPageList: [
                      { text: "10", value: 10},
                      { text: "25", value: 25},
                      { text: "50", value: 50},
                      { text: "100", value: 100},
                    ],
                  })}
                  onTableChange={onTableChange}
                  filter={filterFactory()}
                  bordered={false}
                  headerClasses="header-tr"
                  remote
                />
              </div>
            </>
          )}
          {state.isLoading && (
            <TailSpin
              wrapperClass="cover-spin"
              visible={state.isLoading}
              height={50}
              width="50"
              color="#FF5733"
              ariaLabel="loading"
            />
          )}
        </div>
      }
      <Modal show={state.showAddModal} onHide={viewModel.closeAddModal}>
        <Modal.Body className="modal-card connection">
          <CreateSocialConnection
            id={state.selectedItemId}
            closeModal={viewModel.closeAddModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={state.showPasswordModal}
        onHide={viewModel.closePasswordModal}
      >
        <Modal.Body className="modal-card connection">
          <div className="case-modal-header">
            <h2 className="new-case-title">View Password</h2>
            <img
              className="modal-close-icon"
              src="/images/close.svg"
              alt=""
              onClick={viewModel.closePasswordModal}
            />
          </div>
          {/* Enter your account password to view this connection password! */}
          <div className="">
            <div className="case-form col-12">
              <div className="input-group-meta form-group form-floating">
                <input
                  type="password"
                  placeholder="Password"
                  name="userPassword"
                  id="userPassword"
                  className=" form-control case-form-input"
                  onChange={viewModel.onUserPasswordQueryChanged}
                  value={state.userPassword}
                />
                <label className="case-form-label">Password</label>
              </div>
            </div>
            {/* <FloatingLabelControl disabled={false} type='password' label='Password' id='password' onChange={viewModel.onUserPasswordQueryChanged} value={state.userPassword} /> */}
            <div className="case-modal-footer">
              <button
                className="case-close-modal-btn"
                onClick={viewModel.closePasswordModal}
              >
                <p className="close-modal-btn-content">Cancel</p>
              </button>
              <button
                type="button"
                className="confirm-modal-btn"
                onClick={(): Promise<void> => viewModel.onClickViewPassword()}
              >
                <p className="confirm-modal-btn-content">View Password</p>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={state.showConfirmationModal}
        onHide={viewModel.closeDeleteModal}
      >
        <Modal.Body className="modal-card connection">
          <div className="case-modal-header">
            <h2 className="new-case-title">Delete Connection</h2>
            <img
              className="modal-close-icon"
              src="/images/close.svg"
              alt=""
              onClick={viewModel.closeDeleteModal}
            />
          </div>
          <div className="">
            <p className="case-modal-footer">
              Are you sure you want to delete this connection!
            </p>
            <div className="case-modal-footer">
              <button
                className="case-close-modal-btn"
                onClick={viewModel.closeDeleteModal}
              >
                <p className="close-modal-btn-content">Cancel</p>
              </button>
              <button
                type="button"
                className="confirm-modal-btn"
                onClick={(): Promise<void> => viewModel.onClickDelete()}
              >
                <p className="confirm-modal-btn-content">Delete</p>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <br></br>
      <br></br>
    </>
  );
};

export default SocialConnectionList;
