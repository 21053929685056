import React from "react";

const PhoneSvgIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.625 15.3125H13.5294C2.47624 14.6769 0.906862 5.35063 0.687487 2.50438C0.66981 2.28308 0.695974 2.06046 0.764479 1.84929C0.832984 1.63812 0.942483 1.44255 1.0867 1.27377C1.23092 1.10499 1.40703 0.966324 1.60493 0.865722C1.80283 0.76512 2.01864 0.704556 2.23999 0.687501H5.33936C5.56468 0.687282 5.78486 0.754725 5.97141 0.881091C6.15795 1.00746 6.30225 1.18693 6.38561 1.39625L7.24061 3.5C7.32293 3.70449 7.34336 3.92867 7.29936 4.14468C7.25536 4.36068 7.14887 4.559 6.99311 4.715L5.79499 5.92438C5.98214 6.98792 6.49146 7.96831 7.25405 8.73291C8.01664 9.49751 8.99569 10.0094 10.0587 10.1994L11.2794 8.99C11.4377 8.83596 11.6379 8.73195 11.855 8.69094C12.072 8.64993 12.2964 8.67373 12.5 8.75937L14.6206 9.60875C14.8268 9.69475 15.0027 9.84017 15.1259 10.0265C15.2491 10.2128 15.3141 10.4316 15.3125 10.655V13.625C15.3125 14.0726 15.1347 14.5018 14.8182 14.8182C14.5018 15.1347 14.0725 15.3125 13.625 15.3125ZM2.37499 1.8125C2.2258 1.8125 2.08273 1.87176 1.97724 1.97725C1.87175 2.08274 1.81249 2.22582 1.81249 2.375V2.42C2.07124 5.75 3.73061 13.625 13.5912 14.1875C13.6651 14.1921 13.7392 14.182 13.8092 14.1579C13.8792 14.1338 13.9438 14.096 13.9991 14.0469C14.0545 13.9978 14.0997 13.9382 14.132 13.8716C14.1643 13.805 14.1832 13.7327 14.1875 13.6587V10.655L12.0669 9.80562L10.4525 11.4087L10.1825 11.375C5.28874 10.7619 4.62499 5.86812 4.62499 5.8175L4.59124 5.5475L6.18874 3.93313L5.34499 1.8125H2.37499Z" fill="#76787B" />
        </svg>
    );
};

export default PhoneSvgIcon;