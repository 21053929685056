import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import PageResult from '../../domain/common/PageResult';
import HelperMethods from '../../presentation/utils/HelperMethods';
import PaginationQuery from '../../domain/common/PaginationQuery';
import PendingRequestModel from '../../domain/entities/admin/models/PendingRequestModel';
import IPendingUserRequestRepo from '../../domain/repositories/admin/IPendingUserRequestRepo';
import UserViewPermissionsPaginationQueryModel from '../../domain/entities/admin/models/UserViewPermissionsPaginationQueryModel';
import UserViewPermissionModel from '../../domain/entities/admin/models/UserViewPermissionModel';


export default class PendingUserRequestApi implements IPendingUserRequestRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getPendingUserRequests = (model: PaginationQuery): Promise<Result<PageResult<PendingRequestModel>>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PageResult<PendingRequestModel>>(await res.json());
            else if (res.status == 401)
                return Result.Fail<PageResult<PendingRequestModel>>('Unauthorized', 401);

            return Result.Fail<PageResult<PendingRequestModel>>('Server Error', res.status);

        }).catch(e => {
            return Result.Fail<PageResult<PendingRequestModel>>(e.message, 500);
        });
    }

    ApproveViewRequest = (id: number): Promise<Result> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ requestId: id })
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/ApproveSearchRequestViewRequest`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    DenyViewRequest = (id: number): Promise<Result> => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ requestId: id })
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/DenySearchRequestViewRequest`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    approveGlbaDppaChangeRequest = (id: number): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ requestId: id })
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/ApproveGlbaDppaChangeRequest`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    denyGlbaDppaChangeRequest = (id: number): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ requestId: id })
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/DenyGlbaDppaChangeRequest`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    getUserViewPermissions = (model: UserViewPermissionsPaginationQueryModel): Promise<Result<PageResult<UserViewPermissionModel>>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/GetUserViewPermissions` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PageResult<UserViewPermissionModel>>(await res.json());
            else if (res.status == 401)
                return Result.Fail<PageResult<UserViewPermissionModel>>('Unauthorized', 401);

            return Result.Fail<PageResult<UserViewPermissionModel>>('Server Error', res.status);

        }).catch(e => {
            return Result.Fail<PageResult<UserViewPermissionModel>>(e.message, 500);
        });
    }

    ToggleSearchRequestViewRequest = (requestId: number): Promise<Result<string>> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/PendingUserRequest/ToggleSearchRequestViewRequest/${requestId}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}
