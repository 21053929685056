import React, { useContext } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import HomeViewModelContext from "../../../../infrastructure/Contexts/HomeViewModelContext";
import PrinterSvgIcon from "../../svg-icons/PrinterSvgIcon";
import HomeViewModel from "../../../view-model/searchoutput/HomeViewModel";
import ReactDOMServer from "react-dom/server";
import PDFSearchResults from "../../search-result-pdf/search-result-pdf";
import jsPDF from "jspdf";
import { delay } from "rxjs";

const Actions: React.FC = () => {

    const vm = useContext(HomeViewModelContext);
    const renderCsvTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Save as CSV</Tooltip>);
    const renderPdfTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Save as PDF</Tooltip>);

    const imgConvert = async (parentElement: HTMLElement) => {
        const imgs = Array.from(parentElement.querySelectorAll(".b64"));
        const promises = imgs.map(e => {
            const i = e as HTMLImageElement;
            return new Promise(res => {
                i.onload = () => res("");
                const cnv = document.createElement('canvas');
                const ctx = cnv.getContext("2d");
                cnv.width = i.width;
                cnv.height = i.height;
                ctx?.drawImage(i, 0, 0, i.width, i.height);
                const dataURL = cnv?.toDataURL("image/png");
                dataURL.replace(/^data:image\/?[A-z]*;base64,/, "");
                i.src = dataURL;
            })
        });
        await Promise.all(promises);
    }

    const addLinks = (doc: jsPDF, element: HTMLElement) => {
        const parentRect = element.getBoundingClientRect();
        const anchors = element.querySelectorAll("a");
        anchors.forEach((a, n) => {
            const r = a.getBoundingClientRect();
            let y = r.y - parentRect.y + 30;
            const p = Math.ceil(y / 1350);
            y = y % 1350;
            doc.setPage(p);
            doc.link(r.x, y, r.width, r.height, { url: a?.getAttribute("href") });
        });
    }

    const generatePDF = async (vm: HomeViewModel) => {
        vm?.setShowPDF(true);
        setTimeout(async () => {
            try {
                const pdfElement: HTMLElement = document.querySelector('.search-result.pdf') ?? new HTMLElement();
                await imgConvert(pdfElement);
                const doc = new jsPDF({
                    orientation: 'p',
                    format: [1350, 1350],
                    unit: 'px',
                    hotfixes: ['px_scaling']
                });
                await doc.html(pdfElement, {
                    x: 0,
                    width: 1350,
                    windowWidth: 1350,
                    html2canvas: {
                        backgroundColor: "#FBFBFB",
                        svgRendering: true,
                        width: 1350,
                        windowWidth: 1350,
                    }
                });
                addLinks(doc, pdfElement);
                doc.save(`${vm?.headerModel.id}-search-results.pdf`);
                vm?.setShowPDF(false);
            } catch (e) {
                vm?.setShowPDF(false);
            }
        }, 2000);
    }

    return (
        <div className="actions">
            <Row className="print-view">
                <Col className="printer-icon p-0"> <PrinterSvgIcon /> </Col>
                <Col className="col-auto">Print View</Col>
                <Col className="p-0"><Form.Switch className="print-view-toggle" checked={vm?.isPrintView ?? false} onChange={() => vm?.togglePrintView()} /></Col>
            </Row>
            <Row className="icon-imgs">
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderCsvTooltip}>
                    <img src="/images/csv.svg" alt="img" className="icon-img p-0" onClick={() => {
                        if (vm?.headerModel?.id) vm?.downloadExcelReport(+vm.headerModel.id);
                    }} />
                </OverlayTrigger>
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderPdfTooltip}>
                    <img src="/images/pdf.svg" alt="img" className="icon-img p-0" onClick={() => {
                        if (vm?.headerModel?.id) generatePDF(vm);
                    }} />
                </OverlayTrigger>
            </Row>
        </div>
    );
};
export default Actions;