import React, { Fragment } from "react";
import { Col, Row } from 'react-bootstrap';
import NameModel from '../../../../domain/entities/generic/NameModel';
import PDFPersonalInfo from "./PDFPersonalInfo";
import PDFRequestDetails from "./PDFRequestDetails";

export type Props = {
    headerInfo: HeaderInfo;
}

export type HeaderInfo = {
    id?: number | undefined;
    caseId?: number | undefined;
    caseName?: string | undefined;
    imageUrl?: string | undefined;
    name?: NameModel | undefined;
    age?: string | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
};

const PDFHeader: React.FC<Props> = ({ headerInfo }: Props) => {

    return (
        <Fragment>
            <Row className="header bg-white box-shadow">
                <Col xs="auto">
                    <PDFPersonalInfo imageUrl={headerInfo.imageUrl} name={headerInfo.name} age={headerInfo.age} />
                </Col>
                <Col>
                    <PDFRequestDetails id={headerInfo.id} address={headerInfo.address} phone={headerInfo.phone} email={headerInfo.email} />
                </Col>
            </Row>
        </Fragment>
    );
};

export default PDFHeader;
