import Result from "../../domain/common/Result";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import SearchOutputRepository from "../../domain/repositories/searchoutput/ISearchOutputRepository";
import SearchOutputModel from "../../domain/entities/searchoutput/SearchOutputModel";

export default class SearchOutputApi implements SearchOutputRepository {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  downloadPdfReport(id: number): Promise<Result<Blob>> {
    const requestOptions = {
      method: "GET",
      responseType: "blob",
    };
    return this.httpClient
      .httpFetch(`/api/searchoutput/DownloadPdf/${id}`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<Blob>(await res.blob());
        return Result.FailWithValue<Blob>(
          "Server Error",
          new Blob(),
          res.status
        );
      })
      .catch((e) => {
        return Result.FailWithValue<Blob>(e.message, new Blob(), 500);
      });
  }

  downloadExcelReport(id: number): Promise<Result<Blob>> {
    const requestOptions = {
      method: "GET",
      responseType: "blob",
    };
    return this.httpClient
      .httpFetch(
        `/api/searchoutput/DownloadSearchOutputExcelFile/${id}`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<Blob>(await res.blob());
        return Result.FailWithValue<Blob>(
          "Server Error",
          new Blob(),
          res.status
        );
      })
      .catch((e) => {
        return Result.FailWithValue<Blob>(e.message, new Blob(), 500);
      });
  }

  get(id: number): Promise<Result<SearchOutputModel>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(`/api/searchoutput/get/${id}`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<SearchOutputModel>(await res.json());
        return Result.FailWithValue<SearchOutputModel>(
          "Server Error",
          {} as SearchOutputModel,
          res.status
        );
      })
      .catch((e) => {
        return Result.FailWithValue<SearchOutputModel>(
          e.message,
          {} as SearchOutputModel,
          500
        );
      });
    }

    softDeleteEmail(id: number): Promise<Result<string>> {
        const requestOptions = {
            method: 'DELETE'
        };
        return this.httpClient.httpFetch(`/api/email/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<string>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
}
