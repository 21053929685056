import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import { TableChangeState } from "react-bootstrap-table-next";
import PaginationQuery from "../../../domain/common/PaginationQuery";
import CompanyModel from "../../../domain/entities/admin/models/CompanyModel";
import ICompanyRepo from "../../../domain/repositories/admin/ICompanyRepo";
import PageResult from "../../../domain/common/PageResult";


export default class CompaniesViewModel implements IBaseViewModel {
    //#region props
    public companies: CompanyModel[];
    public pageIndex: number;
    public pageSize: number;
    public totalCount: number;
    //#endregion
    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public subject?: Subject<any>;
    public topic?: string;
    private companyRepo: ICompanyRepo;

    public constructor(companyRepo: ICompanyRepo) {
        this.companies = [];
        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalCount = 0;
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.companyRepo = companyRepo;
    }

    public onDataChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

    public getData = async () => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const model = {} as PaginationQuery;
            model.PageNum = this.pageIndex;
            model.PageSize = this.pageSize;
            const result = await this.companyRepo.getCompanies(model);
            if (result.isSuccess) {
                const res = result.value as PageResult<CompanyModel[]>
                this.companies = res?.pageData as [] ?? [];
                this.totalCount = res?.totalCount ?? 0;
            }
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            this.errorMessages.push(result.error);
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }
    };

    private notifyViewAboutChanges = (): void => {
        const data = {
            companies: this.companies,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            totalCount: this.totalCount,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    private updateCurrentPageData = (pageNumber: number, pageSize: number) => {

        const pageIndex = pageNumber - 1;
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.notifyViewAboutChanges();
        this.getData();

    }
}
