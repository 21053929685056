import React, { Fragment, useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import InternetSearchSvgIcon from "../svg-icons/InternetSearchSvgIcon";
import ResultCount from "./ResultCount";
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";
import MasonryView from "../MasonryView";


type Props = {
    searches: InternetResultModel[];
}

const InternetSearches: React.FC<Props> = (props: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    return (
        <Fragment>
            <Row><ResultCount count={props.searches?.length ?? 0} text={isPrintView ? "Internet Searches" : null}/></Row>
            <div className="searches p-0">
                <MasonryView columnCount={3}>
                    {props.searches.map(search =>
                        <div key={Math.random()}>
                            <Col className="search-card box-shadow">
                                <Row>
                                    <Col md="auto" className="img-box ml-3 m-0"><InternetSearchSvgIcon /></Col>
                                    <Col md="auto" className="val url"><a href={search.url}>{search.url}</a></Col>
                                </Row>
                                <hr />
                                <Row className="data-row">
                                    <Col md="auto" className="title">Title:</Col>
                                    <Col md="auto" className="val">{search.searchTitle}</Col>
                                </Row>
                                <Row className="data-row">
                                    <Col md="auto" className="title">Brief:</Col>
                                    <Col md="auto" className="val">{search.searchContent ?? <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Col>
                        </div >
                    )}
                </MasonryView>
            </div>
        </Fragment>
    );
};

export default InternetSearches;
