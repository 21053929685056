import React from "react";

const Agreement: React.FC = () => {

  return (
    <>
      <div className="agreement-content">
        <div className="agreement-title">Terms and Conditions and Privacy Policy</div>
        <div className="agreement-text">
          By clicking&nbsp;&apos;Accept&apos;&nbsp;I agree to the current terms and conditions of this site
          and acknowledge that my use of the data obtained herein is in compliance
          with the permissible uses of the DPPA & GLBA. I further represent that any
          information obtained will not be used in whole or in part, as a factor in
          establishing an individual&apos;s creditworthiness or employment, or any other
          prohibited uses under the FCRA. I acknowledge that if any data obtained
          through this system is found to have been misused, my access will be
          immediately terminated, and I may be subject to prosecution under the
          applicable laws.
        </div>
      </div>
    </>
  );
};

export default Agreement;
