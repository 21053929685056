import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import SearchOutputRepository from "../../../domain/repositories/searchoutput/ISearchOutputRepository";
import NameModel from "../../../domain/entities/generic/NameModel";
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";
import DateOfBirthModel from "../../../domain/entities/searchoutput/DateOfBirthModel";
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import ImageModel from "../../../domain/entities/searchoutput/ImageModel";
import PhoneModel from "../../../domain/entities/searchoutput/PhoneModel";
import SocialPlatformProfileModel from "../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import TLOTokenModel from "../../../domain/entities/searchoutput/TLOTokenModel";
import UsernameModel from "../../../domain/entities/searchoutput/UsernameModel";
import Result from "../../../domain/common/Result";
import SearchOutputModel from "../../../domain/entities/searchoutput/SearchOutputModel";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import { HeaderInfo } from "../../components/search-result/header/Header";
import SearchRepository from "../../../domain/repositories/search/ISearchRepository";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";

export default class HomeViewModel implements IBaseViewModel {
  //#region props
  public pictureUrl: string;
  public name: NameModel;
  public age: string;
  public caseName: string;
  public caseId: number;
  public headerModel: HeaderInfo;
  public addresses: AddressModel[];
  public relatives: RelativeModel[];
  public dateOfBirths: DateOfBirthModel[];
  public educations: EducationModel[];
  public experiences: ExperienceModel[];
  public emails: EmailModel[];
  public images: ImageModel[];
  public names: NameModel[];
  public phones: PhoneModel[];
  public socialResults: SocialPlatformProfileModel[];
  public tLOTokens: TLOTokenModel[];
  public usernames: UsernameModel[];
  public internetResults: InternetResultModel[];
  //#endregion

  public isLoading: boolean;
  public isDownloading: boolean;
  public isShowError: boolean;
  public isShowPDF: boolean;

  public errorMessages: string[];
  public isSuccess: boolean;
  public isPrintView: boolean;


  private topic?: string;
  private subject?: Subject<any>;
  private searchoutputRepository: SearchOutputRepository;
  private searchRepository: SearchRepository;

  public showAddModal: boolean;
  public selectedItemId: number | null;
  public clickEmail: boolean;
  public searchRequestId: number;
  public isExpiredSearchResult: boolean;

    public constructor(userSettingRepository: SearchOutputRepository, searchRepository: SearchRepository) {
    this.pictureUrl = "";
    this.name = {} as NameModel;
    this.age = "";
    this.caseName = "";
    this.caseId = 0;
    this.headerModel = {} as HeaderInfo;
    this.addresses = [];
    this.relatives = [];
    this.dateOfBirths = [];
    this.educations = [];
    this.experiences = [];
    this.emails = [];
    this.images = [];
    this.names = [];
    this.phones = [];
    this.socialResults = [];
    this.tLOTokens = [];
    this.usernames = [];
    this.internetResults = [];

    this.isLoading = false;
    this.isDownloading = false;
    this.isShowError = false;
    this.isShowPDF = false;
    this.errorMessages = [];
    this.isSuccess = false;
    this.isPrintView = false;

    this.searchoutputRepository = userSettingRepository;
    this.searchRepository = searchRepository;
    this.showAddModal = false;
    this.selectedItemId = null;
    this.clickEmail = false;
    this.searchRequestId = 0;
    this.isExpiredSearchResult = false;
  }

  public openAddModal = (id: string | undefined) => {
    this.showAddModal = true;
    this.selectedItemId = id === undefined ? 0 : +id;
    this.notifyViewAboutChanges();
  };

  public closeAddModal = () => {
    this.showAddModal = false;
    this.notifyViewAboutChanges();
  };

  public loadSearchResult = async (
    id: number
  ): Promise<Result<SearchOutputModel | string>> => {
    try {
      this.searchRequestId = id;
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.searchoutputRepository.get(id);
      result.error;
      this.isLoading = false;
      this.isShowError = !result.isSuccess;

      if (!result.isSuccess && result.statusCode == 423)
          this.isExpiredSearchResult = true;

      if (result.isSuccess && result.value) {
        const details = await this.searchRepository.getSearchDetail(id);
        this.isShowError = !details.isSuccess;
        this.headerModel = {
              id: id, caseId: result.value.caseId, caseName: result.value.caseName, name: result.value.name, age: result.value.age, imageUrl: result.value.pictureUrl, address: result.value.addresses[0]?.addressLine, email: result.value.emails[0]?.email, phone: result.value.phones[0]?.phoneNumber, searchDetails: details.isSuccess ? details.value as SearchDetailsModel : {} as SearchDetailsModel
          };
        this.pictureUrl = result.value.pictureUrl;
        this.name = result.value.name;
        this.age = result.value.age;
        this.caseId = result.value.caseId;
        this.caseName = result.value.caseName;
        this.addresses = result.value.addresses;
        this.dateOfBirths = result.value.dateOfBirths;
        this.educations = result.value.educations;
        this.experiences = result.value.experiences;
        this.relatives = result.value.relatives;
        this.emails = result.value.emails;
        this.images = result.value.images;
        this.names = result.value.names;
        this.phones = result.value.phones;
        this.socialResults = this.flattenThenOrderByData(this.groupByScore(result.value.socialResults));
        this.setUserNameOfResultData(this.socialResults);
        this.tLOTokens = result.value.tLOTokens;
        this.usernames = result.value.usernames;
        this.internetResults = result.value.internetSearches;
        }
      this.notifyViewAboutChanges();
      return result;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
      return Result.Fail(e.message, 500);
    }
  };

    public groupByScore = (socialMediaList: SocialPlatformProfileModel[]): { score: number | undefined, media: SocialPlatformProfileModel[] }[]  => {

        const groupedMedia: { score: number | undefined, media: SocialPlatformProfileModel[] }[] = [];

        socialMediaList.forEach(medium => {
            const sameScoreMediaIndex = groupedMedia.length > 0 ? groupedMedia.findIndex(m => m.score == medium.score) : -1;
            sameScoreMediaIndex >= 0 ? groupedMedia[sameScoreMediaIndex].media.push(medium) : groupedMedia.push({ score: medium.score, media: [medium] });
        })
        return groupedMedia;
    };


    public flattenThenOrderByData = (groupedMedia: { score: number | undefined, media: SocialPlatformProfileModel[] }[]): SocialPlatformProfileModel[] => {

        let orderedMedia: SocialPlatformProfileModel[] = [];

        groupedMedia.forEach(group => {
            const withData = group.media.filter(m => m.resultsWithData.length > 0).map(m => {return {resultsWithData: m.resultsWithData, resultsWithNoData: [], socialPlatform: m.socialPlatform, score: m.score} as SocialPlatformProfileModel} );
            const withNoData = group.media.filter(m => m.resultsWithNoData.length > 0).map(m => {return {resultsWithNoData: m.resultsWithNoData, resultsWithData:[], socialPlatform: m.socialPlatform, score: m.score} as SocialPlatformProfileModel} );
            orderedMedia = orderedMedia.concat(withData).concat(withNoData);
        });
        return orderedMedia;
    };

    public getSearchDetails = async (searchRequestId: number): Promise<SearchDetailsModel | null> => {
        const result = await this.searchRepository.getSearchDetail(searchRequestId);
        if (result.isSuccess)
            return result.value as SearchDetailsModel;

        this.isShowError = true;
        this.notifyViewAboutChanges();
        return null;
    }

    private setUserNameOfResultData = (media: SocialPlatformProfileModel[]) => {
        media.forEach(m => {
            m.resultsWithData.forEach(d => {
                if (!d.username && d.resultData) {
                    const parsedResultData = JSON.parse(d.resultData);
                    let username = parsedResultData['username'];
                    username = username?.includes('@') ? username?.split('@')?.at(1) : username;
                    d.username = username;
                }
            })
        })
    }

  public downloadExcelReport = async (
    id: number
  ): Promise<Result<Blob | string>> => {
    try {
      this.isDownloading = true;
      this.notifyViewAboutChanges();
      const result = await this.searchoutputRepository.downloadExcelReport(id);
      result.error;
      this.isDownloading = false;
      this.isShowError = !result.isSuccess;
      if (!result.isSuccess) {
        this.errorMessages.push(result.error);
      }
      if (result.isSuccess && result.value) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result.value]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Social E-Profiler Result.xlsx");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) link.parentNode.removeChild(link);
      }
      this.notifyViewAboutChanges();
      return result;
    } catch (e: any) {
      this.isDownloading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
      return Result.Fail(e.message, 500);
    }
  };

  public togglePrintView = () => {
    this.isPrintView = !this.isPrintView;
    this.notifyViewAboutChanges();
  }

  public setShowPDF =  (isShowPDF: boolean) => {
    this.isShowPDF = isShowPDF;
    this.isDownloading = isShowPDF;
    this.notifyViewAboutChanges();
  };

  private notifyViewAboutChanges = (): void => {
    const data = {
      name: this.name,
      pictureUrl: this.pictureUrl,
      age: this.age,
      caseId: this.caseId,
      caseName: this.caseName,
      headerModel: this.headerModel,
      addresses: this.addresses,
      relatives:this.relatives,
      dateOfBirths: this.dateOfBirths,
      educations: this.educations,
      emails: this.emails,
      experiences: this.experiences,
      images: this.images,
      names: this.names,
      phones: this.phones,
      socialResults: this.socialResults,
      tLOTokens: this.tLOTokens,
      usernames: this.usernames,
      internetResults: this.internetResults,
      isDownloading: this.isDownloading,
      isPrintView: this.isPrintView,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      showAddModal: this.showAddModal,
      selectedItemId: this.selectedItemId,
      clickEmail: this.clickEmail,
      isShowPDF: this.isShowPDF,
      isExpiredSearchResult: this.isExpiredSearchResult
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
    };

    private getUserName = (url: string): string => {

        const url_at = ['archive.org/details', 'flipboard.com', 'clubhouse.com', 'tiktok.com', 'tinder.com', 'unsplash.com', 'threads.net'];
        const url_endsWithSlash = ['mixcloud.com', 'periscope.tv', 'pinterest.com'];

        if (url_at.some(u => url.includes(u)))
            return url.split('/@')?.at(-1) || '';

        else if (url_endsWithSlash.some(u => url.includes(u)))
            return url.split('/')?.at(-2) || '';

        else if (url.includes('.blogspot.com'))
            return url.split('.blogspot.com')?.at(0) || '';

        else if (url.includes('.tumblr.com'))
            return url.split('.tumblr.com')?.at(-2) || '';

        else if (url.includes('.yelp.com'))
            return url.split('.yelp.com')?.at(-2) || '';

        else if (url.includes('androidforums.com/members'))
            return url.split('username=')?.at(-1) || '';

        else if (url.includes('play.google.com/store/apps/developer'))
            return url.split('?id=')?.at(-1) || '';

        else if (url.includes('yelp.com/user_details?userid'))
            return url.split('?userid=')?.at(-1) || '';

        else if (url.includes('blogger.com/profile/'))
            return url.split('profile/')?.at(-1) || ''

        else if (url.includes('tripit.com/people'))
            return url.split('#/profile/basic-info')?.at(-2)?.split('/')?.at(-1) || ''

        else if (!url.includes('facebook.com/search/people?q='))
            return url.split('/')?.at(-1) || '';

        else
            return (null as any);
    }

    public removeEmail = async (id: number) => {

        this.isLoading = true;
        this.notifyViewAboutChanges();

        const result = await this.searchoutputRepository.softDeleteEmail(id);
        this.isShowError = !result.isSuccess;

        this.clickEmail = true;
        await this.loadSearchResult(this.searchRequestId);
    }

}
