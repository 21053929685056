import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import { toast } from "react-toastify";
import PendingAccountRequestDetailsModel from "../../../domain/entities/admin/models/PendingAccountRequestDetailsModel";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";
import IAccountRequestRepo from "../../../domain/repositories/admin/IAccountRequestRepo";
import ICompanyRepo from "../../../domain/repositories/admin/ICompanyRepo";


export default class AccountRequestViewModel implements IBaseViewModel {

    public subject?: Subject<any>;
    public topic?: string;
    private pendingAccountRequestRepo: IAccountRequestRepo;
    private companyRepo: ICompanyRepo;
    public accountRequest: PendingAccountRequestDetailsModel;
    public companiesNames: CompanyNameModel[];
    public autoDetectedCompanyId: number;
    public selectedCompanyId: number | undefined;
    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public showAccountRequestApprovalModal: boolean;
    public showAccountRequestDenialModal: boolean;
    public denialReason: string;
    public denialNote: string;
    public actionSuccedded: boolean;
    public disabledBtns: boolean;
    public validation: any;

    public constructor(pendingAccountRequestRepo: IAccountRequestRepo, companyRepo: ICompanyRepo) {

        this.pendingAccountRequestRepo = pendingAccountRequestRepo;
        this.companyRepo = companyRepo;
        this.accountRequest = {} as PendingAccountRequestDetailsModel;
        this.companiesNames = [];
        this.autoDetectedCompanyId = 0;
        this.selectedCompanyId = 0;
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.showAccountRequestApprovalModal = false;
        this.showAccountRequestDenialModal = false;
        this.denialReason = '';
        this.denialNote = '';
        this.actionSuccedded = false;
        this.disabledBtns = true;
        this.validation = {};
    }

    public onDenyBtnClick = () => {
        this.showAccountRequestDenialModal = true;
        this.notifyViewAboutChanges();
    }

    public onApproveBtnClick = () => {
        this.showAccountRequestApprovalModal = true;
        this.notifyViewAboutChanges();
    }

    public hideAccountRequestDenialModal = () => {
        this.showAccountRequestDenialModal = false;
        this.denialNote = '';
        this.denialReason = '';
        this.notifyViewAboutChanges();
    }

    public hideAccountRequestApprovalModal = () => {
        this.showAccountRequestApprovalModal = false;
        this.notifyViewAboutChanges();
    }

    public onAccountRequestDeny = () => {
        this.showAccountRequestDenialModal = false;
        this.isLoading = true;
        this.notifyViewAboutChanges();
        this.pendingAccountRequestRepo.DenyAccountRequest({ accountRequestId: this.accountRequest.id, note: this.denialNote, reason: this.denialReason }).then(r => {
            this.isShowError = !r.isSuccess;

            if (!r.isSuccess)
                this.errorMessages.push(r.error);
            else {
                this.actionSuccedded = true;
                this.notify('Denied');
            }
                   
            this.denialNote = '';
            this.denialReason = '';
            this.isLoading = false;
            this.notifyViewAboutChanges();
        });
    }

    public onAccountRequestApprove = () => {
        this.showAccountRequestApprovalModal = false;
        this.isLoading = true;
        this.notifyViewAboutChanges();

        this.selectedCompanyId = this.selectedCompanyId ? this.selectedCompanyId : this.autoDetectedCompanyId;

        this.pendingAccountRequestRepo.ApproveAccountRequest(this.accountRequest.id, this.selectedCompanyId).then(r => {
            this.isShowError = !r.isSuccess;

            if (!r.isSuccess)
                this.errorMessages.push(r.error);
            else {
                this.actionSuccedded = true;
                this.notify('Approved');
            }
                
            this.isLoading = false;
            this.notifyViewAboutChanges();
        });
    }

    public onDataChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

    public onCompanyChanged = (id: number | undefined): void => {
        this.selectedCompanyId = id;

        if (!this.selectedCompanyId)
            this.disabledBtns = true;
        else
            this.disabledBtns = false;

        this.notifyViewAboutChanges();
    };

    public notify = (action: string) => {
        toast.success(`${action} Successfully`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    public getData = async (accountRequestId: number) => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            await this.getAccountRequest(accountRequestId);
            const result = await this.companyRepo.getCompaniesNames();
            if(result.isSuccess){
                this.companiesNames = result.value as CompanyNameModel[];
            }

            this.autoDetectedCompanyId = this.accountRequest.suggestedCompanyId
            this.disabledBtns = this.autoDetectedCompanyId ? false : true;

            this.isLoading = false;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }
    };

    public getAccountRequest = async (accountRequestId: number) => {
        try {
            
            const result = await this.pendingAccountRequestRepo.getAccountRequest(accountRequestId);

            if (result.isSuccess)
                this.accountRequest = result.value as PendingAccountRequestDetailsModel ??  {} as PendingAccountRequestDetailsModel;

            this.isShowError = !result.isSuccess;
            this.errorMessages.push(result.error);

        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }
    };

    private notifyViewAboutChanges = (): void => {
        const data = {
            accountRequest: this.accountRequest,
            companiesNames: this.companiesNames,
            autoDetectedCompanyId: this.autoDetectedCompanyId,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            showAccountRequestApprovalModal: this.showAccountRequestApprovalModal,
            showAccountRequestDenialModal: this.showAccountRequestDenialModal,
            denialReason: this.denialReason,
            denialNote: this.denialNote,
            actionSuccedded: this.actionSuccedded,
            disabledBtns: this.disabledBtns,
            validation: this.validation,
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }

}
