import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import PageResult from '../../domain/common/PageResult';
import HelperMethods from '../../presentation/utils/HelperMethods';
import PaginationQuery from '../../domain/common/PaginationQuery';
import CompanyDetailsModel from '../../domain/entities/admin/models/CompanyDetailsModel';
import CompanyNameModel from '../../domain/entities/admin/models/CompanyNameModel';
import CompanyModel from '../../domain/entities/admin/models/CompanyModel';
import ICompanyRepo from '../../domain/repositories/admin/ICompanyRepo';


export default class CompanyApi implements ICompanyRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getCompanies = (model: PaginationQuery): Promise<Result<PageResult<CompanyModel[]> | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/Company` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PageResult<CompanyModel[]>>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    getCompaniesNames = (): Promise<Result<CompanyNameModel[] | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/GenericData/CompaniesNames`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<CompanyNameModel[]>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    getCompany = (id: number): Promise<Result<CompanyDetailsModel | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/Company/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<CompanyDetailsModel>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    addCompany = (company: CompanyDetailsModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(company)
        };

        return this.httpClient.httpFetch(`/api/Admin/Company`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    updateCompany = (company: CompanyDetailsModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(company)
        };

        return this.httpClient.httpFetch(`/api/Admin/Company`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}
