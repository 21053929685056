import React, { Fragment } from "react";
import { Col, Row } from 'react-bootstrap';
import NameModel from '../../../../domain/entities/generic/NameModel';
import Actions from "./Actions";
import BreadCrumb from "./BreadCrumb";
import PersonalInfo from './PersonalInfo';
import RequestDetails from './RequestDetails';
import SearchDetailsModel from "../../../../domain/entities/search/SearchDetailsModel";

export type Props = {
    headerInfo: HeaderInfo;
}

export type HeaderInfo = {
    id?: number | undefined;
    caseId?: number | undefined;
    caseName?: string | undefined;
    imageUrl?: string | undefined;
    name?: NameModel | undefined;
    age?: string | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    searchDetails?: SearchDetailsModel | null;
    onRerunSearch?: (id: number) => void;
};

const Header: React.FC<Props> = ({ headerInfo }: Props) => {

    return (
        <Fragment>
            <Row className="p-0">
                <Col className="p-0"><BreadCrumb caseId={headerInfo.caseId} caseName={headerInfo.caseName} name={headerInfo.name} /></Col>
                <Col className="p-0 order-social-canvas-wrapper">
                    <a href="https://forms.intertelinc.com/" rel="noreferrer" target="_blank" className="submit-btn btn order-social-canvas">Order Social Canvass</a>
                </Col>
            </Row>
            <Row className="header bg-white box-shadow">
                <Col xs="auto">
                    <PersonalInfo imageUrl={headerInfo.imageUrl} name={headerInfo.name} age={headerInfo.age} />
                </Col>
                <Col>
                    <RequestDetails id={headerInfo.id} address={headerInfo.address} phone={headerInfo.phone} email={headerInfo.email} searchDetails={headerInfo.searchDetails} onRerunSearch={headerInfo.onRerunSearch} />
                </Col>
                <Col xs="auto">
                    <Actions/>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Header;
