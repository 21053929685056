import React from "react";
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";

type EducationProps = {
  education: EducationModel;
};

const Education: React.FC<EducationProps> = (props: EducationProps) => {
  return (
      <div className="education-box-body">
          <div className="address-inner-content">
              <img
                  src="/images/education.svg"
                  className="output-tab-content-icon"
                  alt="img"
              />
              <p className="address-text">{props.education.schoolName} </p>
          </div>
          {(props.education.startYear ||
              props.education.endYear ||
              props.education.location) && (
              <div className="education-inner-card-body">
                  {props.education.startYear && (
                      <div className="  education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">From: </p>
                          <p className="education-inner-text-card-body">
                              {props.education.startYear}
                          </p>
                      </div>
                  )}
                  {props.education.endYear && (
                      <div className="  education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">To: </p>
                          <p className="education-inner-text-card-body">
                              {props.education.endYear}
                          </p>
                      </div>
                  )}
                  {props.education.location && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">
                              Location:{" "}
                          </p>
                          <p className="education-inner-text-card-body">
                              {props.education.location}
                          </p>
                      </div>
                  )}
              </div>
              )}
      </div>
  );
};
export default Education;
