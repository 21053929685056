import ResetPasswordModel from "../../../domain/entities/auth/structures/ResetPasswordModel";
import AuthRepository from "../../../domain/repositories/auth/IAuthRepository";
import FormValidator from "../../utils/FormValidator";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";

export default class ResetPasswordViewModel implements IBaseViewModel {
  //#region props
  public token: string;
  public userId: string;
  public password: string;
  public confirmPassword: string;
  //#endregion

  public validation: any;
  public isLoading: boolean;
  public isShowError: boolean;
  public isSuccess: boolean;
  public errorMessages: string[];
  public startPasswordType: boolean;

  private topic?: string;
  private subject?: Subject<unknown>;
  private authRepository: AuthRepository;

  public constructor(authRepository: AuthRepository) {
    this.token = "";
    this.userId = "";
    this.password = "";
    this.confirmPassword = "";

    this.validation = {};
    this.isLoading = false;
    this.isShowError = false;
    this.isSuccess = false;
    this.errorMessages = [];
    this.startPasswordType = false;

    this.authRepository = authRepository;
  }
  onTokenQueryChanged(token: string): void {
    this.token = token;
  }
  onUserIdQueryChanged(userId: string): void {
    this.userId = userId;
  }

  public onQueryChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
    (this as any)[e.currentTarget.id] = input.currentTarget.value;
    if (
      input.currentTarget.name === "password" ||
      input.currentTarget.name === "confirmPassword"
    )
      this.startPasswordType = true;
    this.notifyViewAboutChanges();
  };

  public onClick = async (): Promise<void> => {
    if (!this.validateResetPasswordForm()) {
      this.notifyViewAboutChanges();
      return;
    }

    try {
      const model = {} as ResetPasswordModel;
      model.Password = this.password;
      model.UserId = this.userId;
      model.Token = this.token;

      this.isLoading = true;
      this.notifyViewAboutChanges();

      const result = await this.authRepository.resetPassword(model);
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.isSuccess = result.isSuccess;
      this.errorMessages.push(result.error);
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
    }

    this.notifyViewAboutChanges();
  };

  private validateResetPasswordForm = (): boolean => {
    this.isShowError = false;
    this.errorMessages = [];
    this.validation = {};

    if (!this.password) {
      this.isShowError = true;
      this.validation.password = "password cannot be empty";
    }
    if (!FormValidator.isValidPassword(this.password)) {
      this.isShowError = true;
      this.validation.password = "weak password";
    }
    if (!this.confirmPassword) {
      this.isShowError = true;
      this.validation.confirmPassword = "confirm password cannot be empty";
    }
    if (this.password != this.confirmPassword) {
      this.isShowError = true;
      this.validation.confirmPassword = "password does not match";
    }

    return !this.isShowError;
  };

  private notifyViewAboutChanges = (): void => {
    const data = {
      token: this.token,
      userId: this.userId,
      password: this.password,
      confirmPassword: this.confirmPassword,

      validation: this.validation,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      startPasswordType: this.startPasswordType,
      errorMessages: this.errorMessages,
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
  };
}
