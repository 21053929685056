import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import DenyModel from '../../domain/entities/auth/structures/DenyModel';
import PageResult from '../../domain/common/PageResult';
import HelperMethods from '../../presentation/utils/HelperMethods';
import PaginationQuery from '../../domain/common/PaginationQuery';
import PendingAccountRequestDetailsModel from '../../domain/entities/admin/models/PendingAccountRequestDetailsModel';
import PendingAccountRequestModel from '../../domain/entities/admin/models/PendingAccountRequestModel';
import IAccountRequestRepo from '../../domain/repositories/admin/IAccountRequestRepo';


export default class AccountRequestApi implements IAccountRequestRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getAccountRequests = (model: PaginationQuery): Promise<Result<PageResult<PendingAccountRequestModel[]> | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/AccountRequest` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PageResult<PendingAccountRequestModel[]>>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    getAccountRequest = (accountRequestId: number): Promise<Result<PendingAccountRequestDetailsModel | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/AccountRequest/${accountRequestId}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PendingAccountRequestDetailsModel>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    ApproveAccountRequest = (accountRequestId: number, selectedCompanyId: number): Promise<Result> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ selectedCompanyId: selectedCompanyId })
        };

        return this.httpClient.httpFetch(`/api/Admin/AccountRequest/${accountRequestId}/Approve`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    DenyAccountRequest = (denyModel: DenyModel): Promise<Result> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Reason: denyModel.reason, Note: denyModel.note })
        };

        return this.httpClient.httpFetch(`/api/Admin/AccountRequest/${denyModel.accountRequestId}/Deny`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}
