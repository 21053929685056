import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import Result from "../../../domain/common/Result";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel";
import React from "react";

export default class UserProfileViewModel implements IBaseViewModel {
    public isLoading: boolean;
    public isShowError: boolean;
    private userSettingRepository: UserSettingRepository;
    private topic?: string;
    private subject?: Subject<any>;

    public constructor(userSettingRepository: UserSettingRepository) {
        this.isLoading = false;
        this.isShowError = false;
        this.userSettingRepository = userSettingRepository;
    }

   
    public getUserAccount = async (): Promise<Result<UserAccountModel | string>> => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const result = await this.userSettingRepository.getUserAccount();
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            this.notifyViewAboutChanges();
            if (result.isSuccess)
                return Result.OkWithValue<UserAccountModel>(result.value || {} as UserAccountModel);
            
            return Result.Fail("");
        } catch (e: any) {
            this.isLoading = false;
            this.isShowError = true;
            this.notifyViewAboutChanges();
            return Result.Fail(e.message, 500);
        }
    };

    
    private notifyViewAboutChanges = (): void => {

        const data = {
            isLoading: this.isLoading,
            isShowError: this.isShowError
        };
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject;
    };
}
