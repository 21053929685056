import React, { useEffect, useState } from "react";
import GLBAOption from "../../../../domain/entities/usersettings/GLBAOption";
import DPPAOption from "../../../../domain/entities/usersettings/DPPAOption";
import { Col, Row, Table } from "react-bootstrap";

type Props = {
    details: any;
    GLBAOptions: GLBAOption[];
    DPPAOptions: DPPAOption[];
}

const GLBADPPAChange: React.FC<Props> = ({ details, GLBAOptions, DPPAOptions }: Props) => {

    const oldGLBA = GLBAOptions.find(o => o.id === details["OldGLBAId"])?.text;
    const oldDPPA = DPPAOptions.find(o => o.id === details["OldDPPAId"])?.text;
    const newGLBA = GLBAOptions.find(o => o.id === details["NewGLBAId"])?.text;
    const newDPPA = DPPAOptions.find(o => o.id === details["NewDPPAId"])?.text;
    const isApprove = details["IsApprove"];

    return (
        <div>
            <div className={`admin-response ${ isApprove != null && isApprove ? "approve" : "deny"}`} >{ isApprove != null? isApprove ? "Approved" : "Denied" : "Pending"}</div>
            <Table borderless>
                <thead>
                    <tr>
                        <th></th>
                        <th><b>Old</b></th>
                        <th><b>New</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><b>GLBA</b></td>
                        <td>{oldGLBA}</td>
                        <td>{newGLBA}</td>
                    </tr>
                    <tr>
                        <td><b>DPPA</b></td>
                        <td>{oldDPPA}</td>
                        <td>{newDPPA}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default GLBADPPAChange;
