import React from "react";
import SocialPlatformModel from "../../../domain/entities/searchoutput/SocialPlatformModel";
import SocialResultModel from "../../../domain/entities/searchoutput/SocialResultModel";

type SocialProfilesProps = {
  otherSocialResults: SocialResultModel[];
  socialPlatform: SocialPlatformModel | null;
};

const SocialProfiles: React.FC<SocialProfilesProps> = (
  props: SocialProfilesProps
) => {
    return <div className="social-card-div">
        <div className="social-output-icon-div">
                    {props.socialPlatform && props.socialPlatform.iconUrl && (
                        <img
                            className="social-output-icon rounded-circle"
                            src={props.socialPlatform?.iconUrl}
                            alt="img"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/icons/default-social-icon.svg";
                            }}
                        />
                    )}
                    {(!props.socialPlatform || !props.socialPlatform.iconUrl) && (
                        <img
                            className="social-output-icon rounded-circle"
                            src="/icons/default-social-icon.svg"
                            alt="img"
                        />
                    )}
               {/* </a>*/}
                <span> {props?.socialPlatform?.name}</span>
        </div>
        <div className="social-inner-content">
            {props?.otherSocialResults[0]?.rulesBasedValidation &&
                <small className="identifiers"> Identifiers: {props?.otherSocialResults[0]?.rulesBasedValidation}</small>
            }
            {props.otherSocialResults.filter(p => !p.url.includes('https://www.facebook.com/search/people?')).map(profile => {
                return (
                    <a className="address-text" key={Math.random()} href={"https://" + profile.url} target={"_blank"} rel="noreferrer">
                        {profile.url}{" "}
                    </a>
                );
            })}
            {props.otherSocialResults.filter(p => p.url.includes('https://www.facebook.com/search/people?'))?.map(profile => {
                return (
                    <button key={Math.random()} className="btn generic-search-button">
                        <a className="address-text" key={Math.random()} href={profile.url} target={"_blank"} rel="noreferrer">
                            Search Facebook For Subject
                        </a>
                    </button>
                );
            })}
        </div>
    </div>
};
export default SocialProfiles;
