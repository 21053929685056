import React, { useState } from "react";

type props = {
    collapsed?: boolean
}


const PrivacyPolicy: React.FC<props> = ({ collapsed = false, ...props }) => {

    const [state, setState] = useState({ collapsed: collapsed, collapseBtnText: 'Read more..' });

    const onCollapseBtnClick = () => {
        if (state.collapsed)
            setState({ collapsed: false, collapseBtnText: 'Show less' })
        else
            setState({ collapsed: true, collapseBtnText: 'Read more..' })
    }

    return (
        <div className="privacy-policy--terms-and-conditions">
            <p className="privacy-title">
                <b>Privacy Policy</b>
            </p>
            <p>
                <p>
                    This Privacy Policy describes how we, INTERTEL, Inc., (together with our affiliate companies - &quot;we,&quot;
                    &quot;our,&quot; or &quot;us&quot;) collect, store, use and disclose personal data regarding individuals (&quot;you&quot;) who: (i) visit or
                    otherwise interact with our websites (&quot;Visitors&quot;), <a href="https://intertelinc.com/">https://intertelinc.com/</a>, <a href="https://www.itotalaccess.net">https://www.itotalaccess.net</a>,
                    and any other website, webpage, e-mail, or text message under our control (collectively - &quot;Sites&quot;); (ii)
                    use our portal (the &quot;Users&quot;), via the Sites or other applications linking to it (collectively, the &quot;Service&quot;).
                </p>
                <p>
                    Your privacy is important to us, and we are strongly committed to making our practices regarding your
                    personal data more transparent and fairer. Please read this Privacy Policy carefully and make sure that
                    you fully understand and agree to it.
                </p>
                <p>
                    You are not legally required to provide us with any Personal Data (defined below), and may do so (or
                    avoid doing so) at your own free will.
                </p>
                <p>
                    If you do not wish to provide us with such Personal Data,{collapsed && state.collapsed && <><span>...</span>&nbsp;&nbsp;&nbsp;<span onClick={onCollapseBtnClick} className="collapseBtn">{state.collapseBtnText}</span></>}<span className={state.collapsed ? 'd-none' : ''}>or to have it processed by us or any of our
                    Service Providers (defined below), please simply do not enter our Sites or use our Service. You may also
                    choose not to provide us with &quot;optional&quot; Personal Data, but please keep in mind that without it we may
                    not be able to provide you with the full range of our services or with the best user experience when
                    using our Service.</span>
                </p>
                <span className={state.collapsed ? 'd-none' : ''}>
                <p>
                    This Privacy Policy forms part of our <a href="https://intertelinc.com/terms-conditions/">Terms and Conditions</a>, available
                    here: <a href="https://intertelinc.com/terms-conditions/">https://intertelinc.com/terms-conditions/</a> (&quot;Terms&quot;). Any capitalized but undefined term in this
                    Privacy Policy shall have the meaning given to it in the Terms. 
                </p>
                <p className="padding-left-65"><b>1. Data Collection</b></p>
                <p>
                    We collect technical data, profile data, customer data, content, and other data received from you, your
                    organization or other third party sources. 
                </p>
                <p>
                    We collect various types of personal data regarding our Users as well as data regarding Visitors to our
                    Sites. Such data is typically collected and generated through your interaction with us or our Service,
                    through automatic means, or directly from you, from other Users, from our Customers, or from other
                    third parties (including Service Providers, as hereinafter defined). 
                </p>
                <p>
                    Specifically, we collect the following categories of data (which, to the extent it relates to an identified or
                    identifiable individual, is deemed as &quot;Personal Data&quot;):
                </p>
                <p className="padding-left-65">1. Data automatically collected or generated:</p>
                <ol className="padding-left-130">
                    <li>
                        <p>
                            When you visit, interact with, or use our Service, we may collect, record or generate
                            certain technical data about you. We do so either independently or with the help of
                            third party Service Providers (as defined in Section 4 below), including through the use
                            of &quot;cookies&quot; and other tracking technologies (as detailed in Section 5 below).</p>
                    </li>
                    <li>
                        <p>
                            Such data consists of connectivity, technical and aggregated usage data, such as IP
                            addresses and general locations, device data (like type, operating system, mobile device
                            id, browser version, locale and language settings used), date and time stamps of usage,
                            the cookies installed or utilized on such device and the recorded activity (sessions, clicks
                            and other interactions) of Visitors and Users in connection with our Service. In addition,
                            phone calls (e.g. with our customer success agents) may be automatically recorded,
                            tracked and analyzed, for purposes including analytics, service-, operations-, and
                            business quality control and improvements, and record- keeping purposes.
                        </p>
                    </li>
                </ol>
                <p className="padding-left-65">2. User Data received from you: </p>
                <ol className="padding-left-130">
                    <li>
                        <p>
                            When you contact us or sign up to the Service and create your individual profile (&quot;User
                            Profile&quot;), you may provide us with Personal Data. This includes your name, workplace
                            and position, contact details (such as e-mail, phone and address), account login details
                            (including user names and passwords), as well as any other data you choose to provide
                            when you use our Service, contact us, or interact with others via our Service
                            (collectively, &quot;User Data&quot;).
                        </p>
                    </li>
                    <li>
                        <p>
                            You may also send us a &quot;Contact Us&quot; or support requests, or provide us with feedback,
                            reviews, or answers to surveys or promotions, including by submitting an online form on
                            our Service or social media channels, by posting on any of our online public forums or
                            communities, by sending an e-mail to any of our designated addresses, or any other
                            form of communication. Such data may include details on a problem you are
                            experiencing, contact information and any other documentation, screen recording,
                            screenshots or other information.
                        </p>
                    </li>
                </ol>
                <p className="padding-left-50">
                    <ul>
                        <li>
                            Our Customers may provide us with additional User Data such as their billing details, business
                            needs and preferences. To the extent that such data concerns a non-human entity (e.g. the bank
                            account of a company or business), we will not regard it as &quot;Personal Data&quot; and this Privacy
                            Policy will not apply to it. 
                        </li>
                    </ul>
                </p>
                <ol className="padding-left-80">
                    <li>
                        <p>
                            Customer Data containing Personal Data: Users may choose to upload or submit Customer Data
                            to our Service which includes Personal Data (e.g., by uploading the Customer&apos;s clients&apos; or
                            employees&apos; Personal Data). Personal Data which is included in Customer Data will only be
                            processed on behalf of our Customer (who owns the Customer&apos;s Account), in accordance with
                            the agreement with such Customer, and this Privacy Policy. 
                        </p>
                    </li>
                    <li>
                        <p>
                            User Data received from our Customers and Users: Customers and Users may provide us with
                            the contact details of their employees, team members and colleagues, in order for us to contact,
                            invite or subscribe them as Users to our Service. Such data typically includes these individuals&apos;
                            names, phone numbers and work e-mails, however additional data may be provided at the
                            discretion of those providing it. For the purposes of this Privacy Policy, this data will also be
                            regarded as &quot;User Data.&quot;
                        </p>
                    </li>
                    <li>
                        <p>
                            Data received from other third parties: We may receive Personal Data concerning you from
                            other sources. For example, if you participate in an event, webinar or promotion that we
                            sponsor or participate in, we may receive your Personal Data from its organizers. We may also
                            receive your contact and professional details (g., your name, company, position, contact details
                            and professional experience, preferences and interests) from our business partners or service
                            providers, and through the use of tools and channels commonly used for connecting between
                            companies and individuals in order to explore potential business and employment
                            opportunities, such as LinkedIn.
                        </p>
                    </li>
                    <li>
                        <p>
                            Data concerning service integrations: If, when using the Service, you or your Admin choose to
                            integrate the Customer&apos;s Account on a third-party service (and such service is supported by our
                            Service), we will connect and integrate that third-party service to ours. The third-party provider
                            of this integration may receive certain relevant data about or from your Customer&apos;s Account
                            (including User Data), or share certain relevant data from your Customer&apos;s Account on their
                            service with our Service, depending on the nature and purpose of such integration.
                        </p>
                    </li>
                    <li>
                        <p>
                            Data obtained through Analytics Tools: We use analytics tools (e.g. Google Analytics) to collect
                            data about the use of our Sites and Service. Analytics tools collect data such as how often Users
                            and Visitors visit or use the Sites or Service, which pages they visit and when, and which
                            website, ad or e-mail message brought them there. 
                        </p>
                    </li>
                    <li>
                        <b>Data Uses</b>
                    </li>
                </ol>
                <p>
                    We use Personal Data as necessary for the performance of our Service; to comply with our legal and
                    contractual obligations; and to support our legitimate interests in maintaining and improving our
                    Service, e.g. in understanding how our Service is used and gaining insights which help us dedicate our
                    resources and efforts more efficiently; in marketing, advertising and selling our Service to you and
                    others; providing customer service and technical support; and protecting and securing our Users,
                    Customers, Visitors, ourselves, and our Service. 
                </p>
                <p>
                    We do not sell your personal information for the intents and purposes of the California Consumer
                    Privacy Act (&quot;CCPA&quot;). 
                </p>
                <p>
                    Specifically, we use Personal Data for the following purposes: 
                </p>
                <ol className="padding-left-80">
                    <li>
                        <p>
                            To facilitate, operate, and provide our Service; 
                        </p>
                    </li>
                    <li>
                        <p>
                            To authenticate the identity of our Users, and to allow them to access our Service; 
                        </p>
                    </li>
                    <li>
                        <p>
                            To provide our Visitors, Users, and Customers with assistance and support;
                        </p>
                    </li>
                    <li>
                        <p>
                            To gain a better understanding of how Visitors and Users evaluate, use and interact with our
                            Service, and how we could improve their and others&apos; user experience, and continue improving
                            our products, offerings, and the overall performance of our Service; 
                        </p>
                    </li>
                    <li>
                        <p>
                            To facilitate and optimize our marketing campaigns, ad management and sales operations, and
                            to manage and deliver advertisements for our products and services more effectively, including
                            on other websites and applications. Such activities allow us to highlight the benefits of using our
                            Service, and thereby to increase your engagement and overall satisfaction with our Service. This
                            includes contextual, behavioral and interests based advertising based on the Visitor&apos;s or User&apos;s
                            activity, preferences or other data available to us or to our business partners;
                        </p>
                    </li>
                    <li>
                        <p>
                            To contact our Visitors, Users and Customers (whether existing or prospective) with general or
                            personalized service-related messages, as well as promotional messages that may be of specific
                            interest to them (as further described in Section 6 below);
                        </p>
                    </li>
                    <li>
                        <p>
                            To facilitate, sponsor and offer certain events;
                        </p>
                    </li>
                    <li>
                        <p>
                            To publish your feedback and submissions to our public forums and blogs; 
                        </p>
                    </li>
                    <li>
                        <p>
                            To support and enhance our data security measures, including for the purposes of preventing
                            and mitigating the risks of fraud, error, or any illegal or prohibited activity;
                        </p>
                    </li>
                    <li>
                        <p>
                            To create aggregated statistical data, inferred non-personal data or anonymized or
                            pseudonymized data (rendered non-personal), which we or our business partners may use to
                            provide and improve our respective services, or for any other purpose; and
                        </p>
                    </li>
                    <li>
                        <p>
                            To comply with applicable laws and regulations.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Data Location & Retention</b>
                        </p>
                    </li>
                </ol>
                <p>
                    We store your data solely in the United States, for as long as necessary in accordance with our
                    reasonable business needs (as necessary for the performance of our Service or for supporting and
                    exercising our legitimate interests); and in accordance with our legal obligations. 
                </p>
                <p>
                    Data Location: Your Personal Data may be processed by us and our authorized Service Providers
                    (defined below) in both inside and outside of the United States, as reasonably necessary for the proper
                    performance and delivery of our Service, or as may be required by law.
                </p>
                <p>
                    While privacy laws may vary between jurisdictions, We and our Service Providers are each committed to
                    protect Personal Data in accordance with this Privacy Policy and customary industry standards,
                    regardless of any lesser legal requirements that may apply in their jurisdiction.
                </p>
                <p>
                    Data Retention: We will retain your Personal Data for as long as it is reasonably necessary in order to
                    maintain and expand our relationship and provide you with our Service and offerings; in order to comply
                    with our legal and contractual obligations; or to protect ourselves from any potential disputes (i.e. as
                    required by laws applicable to log-keeping, records and bookkeeping, and in order to have proof and
                    evidence concerning our relationship, should any legal issues arise following your discontinuance of
                    use), all in accordance with our data retention policy.
                </p>
                <p>
                    Please note that except as required by applicable law or our specific agreements with you, we will not
                    be obligated to retain your Personal Data for any particular period, and we are free to securely delete it
                    or restrict access to it for any reason and at any time, with or without notice to you. If you have any
                    questions about our data retention policy, please contact us by e-mail at <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com.</a> 
                </p>
                <p>
                    <b>4. Data Sharing</b>
                </p>
                <p>
                    Legal Compliance: In exceptional circumstances, we may disclose or allow government and law
                    enforcement officials access to your Personal Data, in response to a subpoena, search warrant or court
                    order (or similar requirement), or in compliance with applicable laws and regulations. Such disclosure or
                    access may occur if we believe in good faith that: (a) we are legally compelled to do so; (b) disclosure is
                    appropriate in connection with efforts to investigate, prevent, or take action regarding actual or
                    suspected illegal activity, fraud, or other wrongdoing; or (c) such disclosure is required to protect the
                    security or integrity of our products and services.
                </p>
                <p>
                    Service Providers: We may engage selected third party companies and individuals to perform services
                    for us. Such service providers may include Third Party Service providers, hosting and server co-location
                    services, communications and content delivery networks (CDNs), data and cyber security services, billing
                    and payment processing services, fraud detection and prevention services, web analytics, e-mail
                    distribution and monitoring services, session or activity recording services, remote access services,
                    performance measurement, data optimization and marketing services, social and advertising networks,
                    content providers, e-mail, voicemails, support and customer relation management systems, and our
                    legal and financial advisors (collectively, &quot;Service Providers&quot;). 
                </p>
                <p>
                    These Service Providers may have access to your Personal Data, depending on each of their specific roles
                    and purposes in facilitating and enhancing our Service, and may only use it for such limited purposes as
                    determined in our agreements with them. 
                </p>
                <p>
                    Third Party Websites and Services: Our Service may also include links to third party websites. Such
                    websites and Third Party Services, and any information you process, submit, transmit or otherwise use
                    with such websites and Third Party Services, are governed by such third party&apos;s terms and privacy
                    practices and policies, and not by this Privacy Policy. We encourage you to carefully read the terms and
                    privacy policies of such website and Third Party Services. 
                </p>
                <p>
                    Sharing Personal Data with our Customers and other Users: We may share your Personal Data with the
                    Customer owning the Account to which you are subscribed as a User (including data and
                    communications concerning your User Profile). This includes instances where you may contact us for
                    help in resolving an issue specific to a team of which you are a member (and which is managed by the
                    same Customer).
                </p>
                <p>
                    Subsidiaries and Affiliated Companies: We may share Personal Data internally within our group, for the
                    purposes described in this Privacy Policy. In addition, should we or any of our subsidiaries or affiliates
                    undergo any change in control, including by means of merger, acquisition or purchase of substantially all
                    of its assets, your Personal Data may be shared with the parties involved in such an event. If we believe
                    that such change in control might adversely affect your Personal Data then stored with us, we will notify
                    you of this event and the choices you may have via e-mail or prominent notice on our Service. 
                </p>
                <p>
                    For the avoidance of doubt, we may share your Personal Data in additional manners, pursuant to your
                    explicit approval, or if we are legally obligated to do so, or if we have successfully rendered such data
                    non-personal and anonymous. We may transfer, share or otherwise use non-personal data at our sole
                    discretion and without the need for further approval.
                </p>
                <p>
                    <b>5. Cookies and Tracking Technologies</b>
                </p>
                <p>
                    We and our Service Providers use cookies and other technologies for performance, tracking, analytics
                    and personalization purposes.
                </p>
                <p>
                    Our Sites and Service (including some of our Service Providers) utilize &quot;cookies,&quot; anonymous identifiers,
                    container tags and other technologies in order for us to provide our Service and ensure that it performs
                    properly, to analyze our performance and marketing activities, and to personalize your experience. Such
                    cookies and similar files or tags may also be temporarily placed on your device. Certain cookies and
                    other technologies serve to recall Personal Data, such as an IP address, previously indicated by a User.
                </p>
                <p>
                    Please note that most browsers allow you to control cookies, including whether or not to accept them
                    and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may
                    choose to block cookies with your browser. 
                </p>
                <p>
                    <b>6. Communications</b>
                </p>
                <p>
                    We engage in service and promotional communications, through e-mail, phone, SMS and notifications. 
                </p>
                <p>
                    Service Communications: We may contact you with important information regarding our Service. For
                    example, we may send you notifications (through any of the means available to us) of changes or
                    updates to our Service, billing issues, service changes, log-in attempts or password reset notices, etc.
                    You can control your communications and notifications settings from your User Profile settings or by
                    contacting your account manager. However, please note that you will not be able to opt-out of receiving
                    certain service communications which are integral to your use (like password resets or billing notices). 
                </p>
                <p>
                    Promotional Communications: We may also notify you about new features, additional offerings, events
                    and special opportunities or any other information we think our Users will find valuable. We may
                    provide such notices through any of the contact means available to us (e.g. phone, mobile or e-mail),
                    through the Service, or through our marketing campaigns on any other sites or platforms. 
                </p>
                <p>
                    If you do not wish to receive such promotional communications, you may notify us at any time by
                    sending an e-mail to <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com</a>. 
                </p>
                <p>
                    <b>7. Data Security</b>
                </p>
                <p>
                    In order to protect your Personal Data held with us, we are using industry-standard physical, procedural
                    and technical security measures, including encryption as appropriate. In the event a breach occurs that
                    directly impacts your Personal Data, we will provide you notice of such breach within 48 hours of
                    discovery.
                </p>
                <p>
                    <b>8. Data Subject Rights</b>
                </p>
                <p>
                    If you wish to exercise your rights under any applicable law, such as the right to request access to, and
                    rectification or erasure of your Personal Data held with us, or to restrict or object to such Personal
                    Data&apos;s processing, or to port such Personal Data (each to the extent available to you under the laws
                    which apply to you) - please contact us by e-mail at <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com</a>.
                </p>
                <p>
                    Please note that once you contact us by e-mail, we may instruct you on how to fulfill your request
                    independently through your User Profile settings; or may require additional information and documents,
                    including certain Personal Data, in order to authenticate and validate your identity and to process your
                    request. Such additional data will be then retained by us for legal purposes (e.g. as proof of the identity
                    of the person submitting the request), in accordance with Section 3 above. 
                </p>
                <p>
                    <b>9. Data Controller/Processor</b>
                </p>
                <p>
                    We currently operate only inside the United States. If you are located outside of the United States, there
                    might be different laws applicable to Personal Data. If you need more information, please
                    contact <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com</a>. 
                </p>
                <p>
                    <b>10. Additional Notices</b>
                </p>
                <p>
                    Updates and Amendments: We may update and amend this Privacy Policy from time to time by posting
                    an amended version on our Service. The amended version will be effective as of the date it is published.
                    When we make material changes to these Terms, we&apos;ll provide notice as appropriate under the
                    circumstances, e.g., by displaying a prominent notice within the Service or by sending an email. Your
                    continued use of the Service after the changes have been implemented will constitute your acceptance
                    of the changes.
                </p>
                <p>
                    External Links: While our Service may contain links to other websites or services, we are not responsible
                    for their privacy practices. We encourage you to pay attention when you leave our Service for the
                    website or application of such third parties, and to read the privacy policies of each and every website
                    and service you visit. This Privacy Policy applies only to our Service. 
                </p>
                <p>
                    Our Service is not designed to attract children under the age of 18: We do not allow children to be Users
                    of our Service. If we learn that a person under the age of 18 is using the Service or has provided us with
                    Personal Data, we will attempt to prohibit and block such use and will make our best efforts to promptly
                    delete such Personal Data. If you believe that we might have any such data, please contact us by e-mail
                    at <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com</a>. 
                </p>
                <p>
                    If you have any comments or questions regarding our Privacy Policy, if you have any concerns regarding
                    your privacy, or if you wish to make a complaint about how your personal data is being processed by us,
                    you can contact <a href="mailto:intertel@intertelgo.com">intertel@intertelgo.com</a>.
                </p>
                <p>
                    Effective Date: September 30, 2020&nbsp;&nbsp;&nbsp;{collapsed && !state.collapsed && <span onClick={onCollapseBtnClick} className="collapseBtn">{state.collapseBtnText}</span>}
                    </p>
                </span>
                <div className="height-50"></div>
            </p>
        </div>
    );
};

export default PrivacyPolicy;
