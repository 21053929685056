import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import SocialPlatformRepository from '../../domain/repositories/searchoutput/ISocialPlatformRepository';
import SocialPlatformModel from '../../domain/entities/searchoutput/SocialPlatformModel';

export default class SocialPlatformApi implements SocialPlatformRepository {
    httpClient: HttpClient;
    SocialPlatformAPI: string | undefined;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
        this.SocialPlatformAPI = process.env.REACT_APP_SEARCH_API;
    }

    get(): Promise<Result<SocialPlatformModel[]>> {
        const requestOptions = {
            method: 'GET'
        };

        return this.httpClient.httpFetch(`${this.SocialPlatformAPI}/api/SocialPlatform/get`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<SocialPlatformModel[]>(await res.json());
            return Result.FailWithValue<SocialPlatformModel[]>('Server Error', await res.json(), res.status);
        }).catch(e => {
            return Result.FailWithValue<SocialPlatformModel[]>(e.message, [] as SocialPlatformModel[], 500);
        });
    }
}
