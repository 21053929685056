import { toast } from 'react-toastify';
import { Subject } from 'rxjs';
import AuthRepository from '../../../domain/repositories/auth/IAuthRepository';
import FormValidator from '../../utils/FormValidator';
import BaseView from '../../views/BaseView';
import IBaseViewModel from '../IBaseViewModel';

export default class ForgetPasswordViewModel implements IBaseViewModel {

    public email: string;
    public isLoading: boolean;
    public isShowError: boolean;
    public isSuccess: boolean;
    public errorMessages: string[];
    public validation: any;
    public disable: boolean;

    private topic?: string;
    private subject?: Subject<any>;
    private authRepository: AuthRepository;

    public constructor(authRepository: AuthRepository) {
        this.email = '';
        this.validation = {};
        this.isLoading = false;
        this.isShowError = false;
        this.isSuccess = false;
        this.errorMessages = [];
        this.authRepository = authRepository;
        this.disable = true;
    }
    onEmailQueryChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.email = input.currentTarget.value;
        this.validateForgetPasswordForm();
        this.notifyViewAboutChanges();
    }

    public onConfirm = async (): Promise<void> => {
        if (this.validation.email)
            return;

        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.authRepository.forgetPassword(this.email);

            this.isLoading = false;
            this.disable = true;

            if (result.isSuccess)
                this.notifySuccess();
            else
                this.notifyError();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }

        this.notifyViewAboutChanges();
    };

    private notifySuccess = () => {
        toast.success("An email has been sent to you, please check your inbox to reset your password", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };

    private notifyError = () => {
        toast.error("We are unable to process your reset password request. Please contact your system administrator for help.", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };

    public resetIsShowError = () => {
        this.isShowError = false;
        this.notifyViewAboutChanges();
    }

    private validateForgetPasswordForm = () => {
        this.isShowError = false;
        this.errorMessages = [];
        this.validation = {};

        if (!this.email.replaceAll(' ', '')) {
            this.validation.email = 'Email cannot be empty';
            this.disable = true;
        }
        else if (!FormValidator.isValidEmail(this.email)) {
            this.validation.email = 'Email is not valid';
            this.disable = true;
        }
        else
            this.disable = false;
    }

    private notifyViewAboutChanges = (): void => {
        const data = {
            email: this.email,
            validation: this.validation,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            disable: this.disable
        }
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject
    }
}
