import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import IAppSettingsRepo from '../../domain/repositories/appSettings/IAppSettingsRepo';
import TermsAndConditionsAndPrivacyPolicyVersionModel from '../../domain/entities/appSettings/models/TermsAndConditionsAndPrivacyPolicyVersionModel';


export default class AppSettingsApi implements IAppSettingsRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getLastTermsAndConditionsAndPrivacyPolicyVersion = (): Promise<Result<TermsAndConditionsAndPrivacyPolicyVersionModel | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/Appsettings/GetLastTermsAndConditionsAndPrivacyPolicyVersion` , requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<TermsAndConditionsAndPrivacyPolicyVersionModel>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    addNewTermsAndConditionsAndPrivacyPolicyVersion = (): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/Admin/Appsettings/AddNewTermsAndConditionsAndPrivacyPolicyVersion`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}
