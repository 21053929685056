import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Helmet from 'react-helmet';
import React, { useEffect } from 'react';
import Home from './lib/presentation/views/home/Home';
import CaseList from './lib/presentation/views/CaseList';
import { init as InitLocalization } from './lib/infrastructure/redux/actions/localization';
import { useDispatch } from 'react-redux'
import Translations from './lib/infrastructure/translations/resources';
import ChangePassword from './lib/presentation/views/auth/ChangePassword';
import ConfirmEmail from './lib/presentation/views/auth/ConfirmEmail';
import ResetPassword from './lib/presentation/views/auth/ResetPassword';
import SearchList from './lib/presentation/views/SearchList';
import UserSettings from './lib/presentation/views/usersettings/UserSettings';
import SearchOutputHome from './lib/presentation/views/searchoutput';
import NotAllowedAccess from './lib/presentation/components/NotAllowedAccess';
import NotFoundAccess from './lib/presentation/components/NotFoundAccess';
import ServerError from './lib/presentation/components/ServerError';
import useUser from './lib/infrastructure/hooks/useUser';
import CreateSearchPage from './lib/presentation/views/createSearch/CreateSearchPage';
import PrivacyPolicy from './lib/presentation/views/PrivacyPolicy';
import TermsAndConditions from './lib/presentation/views/TermsAndConditions';
import ForgetPassword from './lib/presentation/views/ForgetPassword';
import SearchResult from './lib/presentation/views/SearchResult';
import Register from './lib/presentation/views/Register';
import LocalStorageService from './lib/presentation/utils/LocalStorageService';
import PendingRegistrations from './lib/presentation/views/admin/PendingRegistrations';
import AllUsers from './lib/presentation/views/admin/AllUsers';
import UserSearchRequests from './lib/presentation/components/admin/UserSearchRequests';
import SetPassword from './lib/presentation/views/SetPassword';
import Companies from './lib/presentation/views/admin/Companies';
import AccountRequest from './lib/presentation/views/admin/AccountRequest';
import AddUpdateCompany from './lib/presentation/views/admin/AddUpdateCompany';
import UserInfoEdit from './lib/presentation/views/admin/UserInfoEdit';
import TermsAndConditionsAndPrivacyPolicy from './lib/presentation/views/appSettings/TermsAndConditionsAndPrivacyPolicy';
import UserViewPermissions from './lib/presentation/views/admin/UserViewPermissions';
import PendingRequests from './lib/presentation/views/admin/PendingRequests';

function App() {

  const dispatch = useDispatch()
  dispatch(InitLocalization({ locale: 'en', translations: Translations }))
  const { user } = useUser();
  useEffect(() => { LocalStorageService.setTermsOfServiceVersion(); LocalStorageService.setPrivacyPolicyVersion() }, []);


  return (
    <div className="App">
      <Helmet>
        <title>Social E-Profiler</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>
      <Router> 
        <Routes>
          {user.isUserAuthorized() && <Route path='/' element={<Home />}></Route>}
          <Route path='/' element={<Home />}></Route>
          <Route path='/forget-password' element={<ForgetPassword />}></Route>
          {/*<Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>*/}
          {/*<Route path='/terms-and-conditions' element={<TermsAndConditions/>}></Route>*/}
          <Route path='/not-allowed' element={<NotAllowedAccess />}></Route>
          <Route path='/not-found' element={<NotFoundAccess />}></Route>
          <Route path='/server-error' element={<ServerError />}></Route>
          <Route path='/search-output/:id/results' element={<SearchOutputHome />}></Route>
          <Route path='/forgetpassword' element={<ForgetPassword />}></Route>
          <Route path='/resetpassword/:userId/:token' element={<ResetPassword />}></Route>
          <Route path='/settings' element={<UserSettings defaultactiveTab='profile' />}></Route>
          <Route path='/settings/profile' element={<UserSettings defaultactiveTab='profile' />}></Route>
          <Route path='/settings/social-connection' element={<UserSettings defaultactiveTab='social-connection' />}></Route>
          <Route path='/settings/security' element={<UserSettings defaultactiveTab='change-password' />}></Route>
          <Route path='/settings/tracking' element={<UserSettings defaultactiveTab='tracking' />}></Route>
          <Route path='/settings/glba-dppa' element={<UserSettings defaultactiveTab='glba-dppa' />}></Route>
          <Route path='/settings/communication' element={<UserSettings defaultactiveTab='communication' />}></Route>
          <Route path='/changepassword' element={<ChangePassword />}></Route>
          <Route path='/confirmemail/:userId/:token' element={<ConfirmEmail />}></Route>
          <Route path='/search/create' element={<CreateSearchPage />}></Route>
          <Route path='/search' element={<SearchList />}></Route>
          <Route path='/case' element={<CaseList />}></Route>
          <Route path='/register' element={<Register />}></Route>
          <Route path='/register/setPassword' element={<SetPassword />}></Route>
          <Route path='/setPassword' element={<SetPassword />}></Route>
          <Route path="/search-result/:id/" element={<SearchResult/> }/>
          <Route path='/admin/pendingRegistration' element={<PendingRegistrations/>}></Route>
          <Route path='/admin/pendingRequests' element={<PendingRequests/>}></Route>
          <Route path='/admin/pendingRegistration/:id/' element={<AccountRequest/>}></Route>
          <Route path='/admin/user' element={<AllUsers/>}></Route>
          <Route path='/admin/company' element={<Companies/>}></Route>
          <Route path='/admin/company/:id/' element={<AddUpdateCompany/>}></Route>
          <Route path='/admin/company/create' element={<AddUpdateCompany/>}></Route>
          <Route path='*' element={<NotFoundAccess />}></Route>
          <Route path="/search-result/:id" element={<SearchResult/> }/>
          <Route path="/admin/user/:id/search-requests" element={<UserSearchRequests/> }/>
          <Route path="/admin/user/:id/view-permissions" element={<UserViewPermissions/> }/>
          <Route path="/admin/user/:id" element={<UserInfoEdit/> }/>
          <Route path="/admin/user/:userId/search-request/:id" element={<SearchResult/> }/>
          <Route path="/admin/appsettings" element={<TermsAndConditionsAndPrivacyPolicy/> }/>
        </Routes>
       </Router>
    </div>
  );
}

export default App;
