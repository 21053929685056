import React from "react";
import { Col, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";
import PersonalInformation from "../person-info-edit/PersonalInformation";
import AlternativeContact from "../person-info-edit/AlternativeContact";
import AccountPasswordRecovery from "../person-info-edit/AccountPasswordRecovery";
import CompanyInformation from "../person-info-edit/CompanyInformation";

type Props = {
    onPrevious: () => void;
    onNext: () => void;
    introStatement: React.ReactNode;
    state: RegisterComponentState;
    viewModel: RegisterViewModel;
}


const AccountInfo: React.FC<Props> = ({ state, viewModel, introStatement, onNext, onPrevious }) => {

   
    return <>
        <div className="registration account-info main-page">
            <div className="section-wrapper px-5">
                <Row className="ml-n-25px">{introStatement}</Row>
                <Row className="current-step ml-n-25px"><img className="p-0" src="/images/account_info.svg" /></Row>
                <PersonalInformation state={state} viewModel={viewModel} />
                <AlternativeContact state={state} viewModel={viewModel} />
                <AccountPasswordRecovery state={state} viewModel={viewModel} />
                <CompanyInformation state={state} viewModel={viewModel} />
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={viewModel.onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto">
                        <a onClick={onPrevious} className="btn previous-btn"><span>&larr;</span> &nbsp;&nbsp;&nbsp; Previous</a>
                        <button onClick={onNext} disabled={!state.formIsValid} className="btn next-btn">Next &nbsp;&nbsp;&nbsp; <span>&rarr;</span></button>
                    </Col>
                </Row>
            </div >
        </div>
    </>
};

export default AccountInfo;