import React from "react";
import { Col } from 'react-bootstrap';
import { HeaderInfo } from "./Header";
const BreadCrumb: React.FC<HeaderInfo> = (info: HeaderInfo) => {
    return (
        <Col className="breadrumb p-0">
            <a href="/case" className="p-0">Cases</a>
            <span className="">&nbsp;/&nbsp;</span>
            <a href={"/search?caseid=" + info.caseId} className="p-0">{info.caseName}</a>
            <span className="">&nbsp;/&nbsp;</span>
            <span className="name">{info.name?.firstName} {info.name?.lastName}</span>
        </Col>
    );
};
export default BreadCrumb;