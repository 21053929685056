import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";
import { SET_Search_Details } from "../actions/actionTypes";

const initialState: SearchDetailsModel = {
    address: '',
    caseId: 0,
    caseName: '',
    city: '',
    dateOfLoss: new Date(),
    employer: '',
    firstName: '',
    lastName: '',
    name: '',
    searchId: 0,
    state: '',
    zipCode: '',
    ageRangeFrom: 0,
    ageRangeTo: 0,
    dateOfBirth: new Date(),
    emails: [],
    phones: [],
    userNames: []
}

const reducer = (searchDetails: SearchDetailsModel = initialState, action: ActionType) => {
    switch (action.type) {
        case SET_Search_Details:
            return action.payload;
        default:
            return searchDetails;
    }
}

export default reducer;