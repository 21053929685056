import React, { Fragment, useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import InternetSearchSvgIcon from "../svg-icons/InternetSearchSvgIcon";
import ResultCount from "./ResultCount";
import MasonryView from "../MasonryView";


type Props = {
    searches: InternetResultModel[];
}

const PDFInternetSearches: React.FC<Props> = (props: Props) => {

    return (
        <Fragment>
            <Row><ResultCount count={props.searches?.length ?? 0} text={"Internet Searches"} /></Row>
            <div className="searches p-0">
                <MasonryView columnCount={3}>
                    {props.searches.map(search =>
                        <div key={Math.random()}>
                            <Col className="search-card box-shadow">
                                <Row>
                                    <Col className="col-auto img-box px-2">
                                        <img width='30px' height='18px' src="/images/search-results/search.png" />
                                    </Col>
                                    <Col md="auto" className="val url">{search.url}</Col>
                                </Row>
                                <hr />
                                <Row className="data-row">
                                    <Col md="auto" className="title">Title:</Col>
                                    <Col md="auto" className="val">{search.searchTitle}</Col>
                                </Row>
                                <Row className="data-row">
                                    <Col md="auto" className="title">Brief:</Col>
                                    <Col md="auto" className="val">{search.searchContent ?? <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Col>
                        </div >
                    )}
                </MasonryView>
            </div>
        </Fragment>
    );
};

export default PDFInternetSearches;
