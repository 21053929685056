import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import { TableChangeState } from "react-bootstrap-table-next";
import IPendingUserRequestRepo from "../../../domain/repositories/admin/IPendingUserRequestRepo";
import UserViewPermissionModel from "../../../domain/entities/admin/models/UserViewPermissionModel";
import UserViewPermissionsPaginationQueryModel from "../../../domain/entities/admin/models/UserViewPermissionsPaginationQueryModel";
import { toast } from "react-toastify";
import IUserAccountRepo from "../../../domain/repositories/admin/IUserAccountRepo";
import UserInfoModel from "../../../domain/entities/admin/models/UserInfoModel";
import PageResult from "../../../domain/common/PageResult";

export const Approved = 'Approved';
export const Denied = 'Denied';

export default class UserViewPermissionsViewModel implements IBaseViewModel {
    //#region props
    public userId: string;
    public userName: string;
    public viewPermissions: UserViewPermissionModel[];
    public pageIndex: number;
    public pageSize: number;
    public totalCount: number;
    //#endregion
    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public subject?: Subject<any>;
    public topic?: string;
    private pendingAccountRequestRepo: IPendingUserRequestRepo;
    private userAccountRepo: IUserAccountRepo;

    public constructor(pendingAccountRequestRepo: IPendingUserRequestRepo, userAccountRepo: IUserAccountRepo) {
        this.userId = '';
        this.userName = '';
        this.viewPermissions = [];
        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalCount = 0;
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.pendingAccountRequestRepo = pendingAccountRequestRepo;
        this.userAccountRepo = userAccountRepo;
    }

    public onToggle = async (requestId: number) => {
        this.isLoading = true;
        this.notifyViewAboutChanges();

        const result = await this.pendingAccountRequestRepo.ToggleSearchRequestViewRequest(requestId);

        if (result.isSuccess) {
            const vp = this.viewPermissions.find(v => v.requestId == requestId);
            if(vp)
                vp?.requestStatus == Approved ? (vp.requestStatus = Denied) : (vp.requestStatus = Approved);
            this.notify();
        }

        this.isShowError = !result.isSuccess;
        this.isLoading = false;
        this.notifyViewAboutChanges();
    }

    public notify = () => {
        toast.success(`Updated Successfully`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    public getData = async (userId: string) => {
        try {
            this.userId = userId;
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const model = {} as UserViewPermissionsPaginationQueryModel;
            model.PageNum = this.pageIndex;
            model.PageSize = this.pageSize;
            model.UserId = userId;

            const result = await this.pendingAccountRequestRepo.getUserViewPermissions(model);
            const userInfoResult = await this.userAccountRepo.GetUserInfo(this.userId ?? "");

            if (userInfoResult.isSuccess) {
                const userModel = userInfoResult.value as UserInfoModel;
                this.userName = `${userModel.firstName} ${userModel.lastName}`;
            }

            if (result.isSuccess) {
                const res = result.value as PageResult<UserViewPermissionModel>;
                this.viewPermissions = res?.pageData as [] ?? [];
                this.totalCount = res?.totalCount ?? 0;
            }

            this.isLoading = false;
            this.isShowError = !result.isSuccess || !userInfoResult.isSuccess;
            this.errorMessages.push(result.error);
            this.errorMessages.push(userInfoResult.error);
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }
    };

    private notifyViewAboutChanges = (): void => {
        const data = {
            userId: this.userId,
            userName: this.userName,
            viewPermissions: this.viewPermissions,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            totalCount: this.totalCount,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    private updateCurrentPageData = (pageNumber: number, pageSize: number) => {
        const pageIndex = pageNumber - 1;
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.notifyViewAboutChanges();
        this.getData(this.userId);

    }
}
