import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import HelperMethods from "../../../utils/HelperMethods";
import { UserName } from "./Filters";


type platformFilterProps = {
    userNames: UserName[];
    previousSelectedUserNames: string;
    onFilterChange: (selectedUserNames: string) => void;
}

const UserNameFilter: React.FC<platformFilterProps> = (props: platformFilterProps) => {

    const [state, setState] = useState({
        display: false,
        searchedUserNames: HelperMethods.getCopyOf(props.userNames),
        allUserNames: HelperMethods.getCopyOf(props.userNames),
        appliedFilterUserNames: HelperMethods.getCopyOf(props.userNames),
        selectedCount: 0
    });

    const menuRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

        const UserNamesNames = props.userNames

        if (props.previousSelectedUserNames) {
            UserNamesNames.forEach(p => {
                props.previousSelectedUserNames?.includes(`${p?.username},`) ? p.selected = true : null;
            });
        }

        const selectedCount = UserNamesNames.filter(s => !s.selected)?.length ?? 0;

        setState({
            display: false,
            searchedUserNames: HelperMethods.getCopyOf(UserNamesNames),
            allUserNames: HelperMethods.getCopyOf(UserNamesNames),
            appliedFilterUserNames: HelperMethods.getCopyOf(UserNamesNames),
            selectedCount: selectedCount
        });

    }, [props.userNames]);


    useEffect(() => {

        const clickHandler = (e: MouseEvent) => {
            if (state.display && menuRef?.current && !menuRef.current.contains(e.target as HTMLElement))
                closeMenu();
        }

        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    const changeSelectionIn = (UserNames: UserName[], platformName: string) => {

        const changedPlatform = UserNames.find(p => p.username == platformName);

        if (changedPlatform)
            changedPlatform.selected = !changedPlatform.selected;

        return UserNames;
    }

    const changeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {

        const platformName = event.target.name;

        const searchedUserNames = changeSelectionIn(HelperMethods.getCopyOf(state.searchedUserNames), platformName);
        const allUserNames = changeSelectionIn(HelperMethods.getCopyOf(state.allUserNames), platformName);
        const selectedCount = allUserNames.filter(a => !a.selected).length;

        setState({ ...state, selectedCount: selectedCount, searchedUserNames: searchedUserNames, allUserNames: allUserNames });
    }

    const toggleMenu = (e: React.FormEvent) => {
        e.stopPropagation();
        if (state.display)
            closeMenu();
        else
            setState({ ...state, selectedCount: state.appliedFilterUserNames.filter(a => !a.selected).length, display: true });
    }

    const search = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searched = state.allUserNames.filter(p => p.username.toLowerCase().startsWith(event.target.value.toLowerCase()));
        setState({ ...state, searchedUserNames: searched });
    }

    const clearAll = () => {
        state.allUserNames.forEach(p => p.selected = false);
        const searchBox = document.getElementById('social-media-search-box');
        searchBox ? (searchBox as HTMLInputElement).value = '' : null;
        setState({ ...state, selectedCount: state.allUserNames.length, searchedUserNames: HelperMethods.getCopyOf(state.allUserNames) });
    }

    const selectAll = () => {
        state.allUserNames.forEach(p => p.selected = true);
        setState({ ...state, selectedCount: 0, searchedUserNames: HelperMethods.getCopyOf(state.allUserNames) });
    }

    const closeMenu = () => setState({
        ...state,
        display: false,
        selectedCount: state.appliedFilterUserNames.filter(s => !s.selected).length,
        searchedUserNames: HelperMethods.getCopyOf(state.appliedFilterUserNames),
        allUserNames: HelperMethods.getCopyOf(state.appliedFilterUserNames)
    });

    const applyFilters = () => {
        let selectedUserNames = '';
        let selectedCount = state.allUserNames.length;

        state.allUserNames.forEach(p => {
            if (p.selected) {
                selectedUserNames += `${p.username}, `;
                selectedCount--;
            }
        });

        props.onFilterChange(selectedUserNames);
        setState({ ...state, display: false, selectedCount: selectedCount, appliedFilterUserNames: HelperMethods.getCopyOf(state.allUserNames) });
    }
    
    return (
        <div className="platform-filter">
            <div className="filter-btn" onClick={toggleMenu}>
                <button className="filter-btn">
                    <img src="/images/filter-icon.svg" className="filter-icon" />
                    <span>Filter</span>
                    {state.selectedCount > 0 && < span className="filter-count">{state.selectedCount}</span>}
                </button>
            </div>
            {state.display && 
                <div className="menu box-shadow" ref={menuRef}>
                    <div className="filter-header">
                        <div onClick={selectAll}>Select All</div>
                        <div onClick={clearAll}>Clear All</div>
                    </div>
                    <div className="search">
                        <input autoComplete="new-password" type="text" placeholder="search for username" onChange={search} id='social-media-search-box'/>
                    </div>
                    <div className="menu-items">
                        {state.searchedUserNames.map(p => {
                            return <div key={p.username} className="menu-item">
                                <input id={p.username} type="checkbox" checked={p.selected} onChange={changeSelection} name={p.username} />
                                <label htmlFor={p.username}>{p.username}</label>
                             </div>
                        })}
                    </div>
                    <Row className="justify-content-end pt-3">
                        <Col md="auto" className="p-0"><button onClick={closeMenu} className="cancel-btn">Cancel</button></Col>
                        <Col md="auto"><button onClick={applyFilters} className="apply-btn">Apply Filters</button></Col>
                    </Row>
                </div>  
             }
        </div>
    );
};

export default UserNameFilter;
