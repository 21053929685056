import React from "react";
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";
import Education from "./Education";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type EducationsProps = {
  educations: EducationModel[];
};

const Educations: React.FC<EducationsProps> = (props: EducationsProps) => {
  return (
      <div id="__education">

          <SocialResultSectionTitle title="Education" iconUrl="/images/education.svg" />
          
          {props.educations && props.educations.length > 0 && (
              <>
                  {props.educations.map((education, index) => {
                      return <Education education={education} key={index} />;
                  })}
              </>
          )}
          {!props.educations ||
              (props.educations.length == 0 && (
                  <h2 className="title text-center">No Records found</h2>
              ))}
      </div>
  );
};
export default Educations;
