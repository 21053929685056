import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchDetailsModel from "../../domain/entities/search/SearchDetailsModel";

type SearchDetailsProps = {
    data: SearchDetailsModel | null;
    onRerunSearch: (id: number) => void;
    onCloseModal: () => void;
};

const SearchDetails: React.FC<SearchDetailsProps> = (props: SearchDetailsProps) => {

    return (
      <>
            <Row>
                <Col className="model-title">{props.data?.name ?? ""}</Col>
                <Col className="close-modal-icon col-md-auto" onClick={props.onCloseModal}><span>x</span></Col>
            </Row>
            <div className="case-details">
                <>
                    <div className="row">
                        <p className="search-detail-title">Case Name</p>
                        <p className="title-info">
                            {props.data?.caseName ?? "N/A"}
                        </p>
                    </div>
                    <Row className="search-details-title">
                        <Col md="auto">Personal Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">First Name</p>
                                <p className="title-info">
                                    {props.data?.firstName ?? "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Last Name</p>
                                <p className="title-info">
                                    {props.data?.lastName ?? "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Phone</p>
                                {props.data?.phones && props.data?.phones.length < 1 ? (
                                    <p className="title-info">N/A</p>
                                ) : (
                                        props.data?.phones?.map((p) => {
                                        return (
                                            <p className="title-info" key={p}>
                                                {p}
                                            </p>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Email Address</p>
                                {props.data?.emails &&
                                    props.data?.emails.length < 1 ? (
                                    <p className="title-info">N/A</p>
                                ) : (
                                        props.data?.emails?.map((e) => {
                                        return (
                                            <p className="title-info" key={e}>
                                                {e}
                                            </p>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">UserName</p>
                                {props.data?.userNames &&
                                    props.data?.userNames.length < 1 ? (
                                    <p className="title-info">N/A</p>
                                ) : (
                                        props.data?.userNames?.map((e) => {
                                        return (
                                            <p className="title-info" key={e}>
                                                {e}
                                            </p>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Date Of Birth</p>
                                <p className="title-info">
                                    {(props.data?.dateOfBirth && new Date(props.data?.dateOfBirth).toLocaleDateString()) || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Age Range</p>
                                <p className="title-info">
                                    {props.data?.ageRangeFrom && props.data?.ageRangeFrom ? `${props.data?.ageRangeFrom} - ${props.data?.ageRangeTo}` : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <Row className="search-details-title">
                        <Col md="auto">Company Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Street Address</p>
                                <p className="title-info">
                                    {props.data?.address || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">City</p>
                                <p className="title-info">
                                    {props.data?.city || "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">State </p>
                                <p className="title-info">
                                    {props.data?.state || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Zip Code</p>
                                <p className="title-info">
                                    {props.data?.zipCode || "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row item">
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Date Of Loss</p>
                                <p className="title-info">
                                    {(props.data?.dateOfLoss && new Date(props.data?.dateOfLoss).toLocaleDateString()) || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <p className="search-detail-title">Employer</p>
                                <p className="title-info">
                                    {props.data?.employer || "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            </div>
            <Row>
                <Col></Col>
                <Col md="auto" className="p-0"><button type="button" className="btn previous-btn m-0" onClick={props.onCloseModal}>Close</button></Col>
                <Col md="auto"><button type="button" className="btn next-btn" onClick={() => props.onRerunSearch(props.data?.searchId || 0)}>Re-Run Search</button></Col>
            </Row>
      </>
  );
};

export default SearchDetails;
