import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducer';

const useTranslation = () => {
  const {locale, translations} = useSelector((state: RootState) => state.localization)

  const t = (key: string) => {
    if (!translations[key] && process.env.ENVIRONMENT === "development") {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);      
      return key;      
    }
    return translations[key] || key
  }

  return {
    t,
    locale
  }
}

export default useTranslation;