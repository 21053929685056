import React from "react";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import Email from "./Email";
import EmailList from "./EmailList";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type EmailsProps = {
  emails: EmailModel[];
};

const Emails: React.FC<EmailsProps> = ({ emails }) => {
  return (
      <div id="__email">

          <SocialResultSectionTitle title="Email" iconUrl="/images/email.svg" />

          {emails && emails.length > 0 && (
              <>
                  {emails
                      .filter((v) => v.gHuntOutput)
                      .map((email) => (
                          <Email email={email} key={Math.random()} />
                      ))}
                  {
                      <EmailList
                          emails={emails
                              .filter((emailItem) => !emailItem.gHuntOutput)}
                      />
                  }
              </>
          )}
          {!emails ||
              (emails.length == 0 && (
                  <h2 className="title text-center">No Records found</h2>
              ))}
      </div>
  );
};
export default Emails;
