import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import IAppSettingsRepo from "../../../domain/repositories/appSettings/IAppSettingsRepo";
import TermsAndConditionsAndPrivacyPolicyVersionModel from "../../../domain/entities/appSettings/models/TermsAndConditionsAndPrivacyPolicyVersionModel";
import { toast } from "react-toastify";

export default class TermsAndConditionsAndPrivacyPolicyViewModel implements IBaseViewModel {
    public lastVersionDate: any;
    public isShowNewVersionModal: boolean;
    public isLoading: boolean;
    public isShowError: boolean;
    private topic?: string;
    private subject?: Subject<any>;
    private appSettingsRepo: IAppSettingsRepo;

    public constructor(appSettingsRepo: IAppSettingsRepo) {
        this.lastVersionDate = null;
        this.isShowNewVersionModal = false;
        this.isLoading = false;
        this.isShowError = false;
        this.appSettingsRepo = appSettingsRepo;
    }

    public getData = async () => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.appSettingsRepo.getLastTermsAndConditionsAndPrivacyPolicyVersion();
            if (result.isSuccess)
                this.lastVersionDate = ((result.value || {}) as TermsAndConditionsAndPrivacyPolicyVersionModel).creationDate;

            this.isShowError = !result.isSuccess;
            this.isLoading = false;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.isShowError = true;
        }
    };

    public hideNewVersionModal = () => {
        this.isShowNewVersionModal = false;
        this.notifyViewAboutChanges();
    }

    public showNewVersionModal = () => {
        this.isShowNewVersionModal = true;
        this.notifyViewAboutChanges();
    }

    public addNewVersion = async () => {
        this.isShowNewVersionModal = false;
        this.isLoading = true;
        this.notifyViewAboutChanges();

        const result = await this.appSettingsRepo.addNewTermsAndConditionsAndPrivacyPolicyVersion();
        if (result.isSuccess)
            this.lastVersionDate = new Date();
        this.isLoading = false;
        this.isShowError = !result.isSuccess;
        this.notify();
        this.notifyViewAboutChanges();
    }

    public notify = () => {
        toast.success(`Added Successfully`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    private notifyViewAboutChanges = (): void => {
        const data = {
            lastVersionDate: this.lastVersionDate,
            isShowNewVersionModal: this.isShowNewVersionModal,
            isLoading: this.isLoading,
            isShowError: this.isShowError
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }
}
