import React from "react";
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";

type AddressProps = {
  address: AddressModel;
};

const Address: React.FC<AddressProps> = (props: AddressProps) => {
    return (
      <div className="row">
            <div className="col mb-1 search-result-address">
            <img src="/images/location.svg" className="output-tab-content-icon" alt="img" />
              <div className="address-text">
                  {props.address.line}
                  {
                      props.address.city && props.address.state && <div>{props.address.city}, {props.address.state}, {props.address.zipCode}</div>
                  }
              </div>
            </div>
      </div>
  );
};
export default Address;
