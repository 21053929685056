import React from "react";
import { Col, Row } from "react-bootstrap";
import AddUpdateCompanyViewModel from "../../view-model/admin/AddUpdateCompanyViewModel";
import { CompanyState } from "../../views/admin/AddUpdateCompany";


type Props = {
    state: CompanyState,
    viewModel: AddUpdateCompanyViewModel
}

const AccountPointOfContact: React.FC<Props> = ({ state, viewModel }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Account Point of Contact</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="First Name"
                    name="accountPointOfContactName"
                    id="accountPointOfContactName"
                    onChange={viewModel.onAccountPointOfContactNameChange}
                    maxLength={50}
                    value={state.company.accountPointOfContact?.firstName}
                    className={`form-control ${state.validation.accountPointOfContactName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Name<span className="mandatory-symbol">*</span></label>
                {state.validation.accountPointOfContactName && (
                    <div className="invalid-feedback">
                        {state.validation.accountPointOfContactName}
                    </div>
                )}
            </Col>

            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Name"
                    name="accountPointOfContactLastName"
                    id="accountPointOfContactLastName"
                    onChange={viewModel.onAccountPointOfContactLastNameChange}
                    value={state.company.accountPointOfContact?.lastName}
                    maxLength={50}
                    className={`form-control ${state.validation.accountPointOfContactLastName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Last Name<span className="mandatory-symbol">*</span></label>
                {state.validation.accountPointOfContactLastName && (
                    <div className="invalid-feedback">
                        {state.validation.accountPointOfContactLastName}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input" md="6">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Phone"
                    name="accountPointOfContactPhone"
                    id="accountPointOfContactPhone"
                    onChange={viewModel.onAccountPointOfContactPhoneChange}
                    value={state.company.accountPointOfContact?.phone}
                    className={`form-control ${state.validation.accountPointOfContactPhone ? "is-invalid" : ""}`}
                />
                <label className="form-label">Phone<span className="mandatory-symbol">*</span></label>
                {state.validation.accountPointOfContactPhone && (
                    <div className="invalid-feedback">
                        {state.validation.accountPointOfContactPhone}
                    </div>
                )}
            </Col>

            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Email"
                    name="accountPointOfContactEmail"
                    id="accountPointOfContactEmail"
                    onChange={viewModel.onAccountPointOfContactEmailChange}
                    value={state.company.accountPointOfContact?.email}
                    className={`form-control ${state.validation.accountPointOfContactEmail ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email<span className="mandatory-symbol">*</span></label>
                {state.validation.accountPointOfContactEmail && (
                    <div className="invalid-feedback">
                        {state.validation.accountPointOfContactEmail}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default AccountPointOfContact;
