import React  from "react";
import { Col, Row } from "react-bootstrap";

type Props = {
    children: Array<any>,
    columnCount: number
}

const MasonryView: React.FC<Props> = (props:Props) => {
    const columns:Array<Array<any>> = Array<Array<any>>(props.columnCount);

    props.children.forEach((child,index) => {
        if(columns[index % props.columnCount] === undefined)
            columns[index % props.columnCount] = Array<any>();
        columns[index % props.columnCount].push(child); 
    });

    return <Row>
        {
            columns.map(col => (<Col key={Math.random()}>
                {col}
            </Col>))
        }
    </Row>
    }

export default MasonryView