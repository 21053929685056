import React, { useContext } from "react";
import { Row, Col } from 'react-bootstrap';
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";
import PhoneSvgIcon from "../svg-icons/PhoneSvgIcon";
import ResultCount from "./ResultCount";

export type Props = {
    phones: string[];
}


const Phones: React.FC<Props> = ({phones}: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;


    return (
        <>
        <Row className="d-flex">
            <ResultCount count={phones.length ?? 0} text={isPrintView ? "Phones" : null}/>
        </Row>
        <Row className="phones p-0">
            {phones.map(p => 
                <Col className="col-4" key={Math.random()}>
                    <Row className="phone-card box-shadow bg-white">
                        <Col className="col-auto img-box mt-2">
                            <PhoneSvgIcon />
                        </Col>
                        <Col className="p-0 col-auto">{p}</Col>
                        <Col className="p-0 google-search">
                            <img src="/images/google.svg" className="m-0" />
                            <span className="title">Google Search:&nbsp;</span>
                            <a className="view p-0" target="_blank" rel="noreferrer" href={'https://www.google.com/search?q="' + p.replaceAll(/[()]/g, '').replace(' ', '-') + '"'}>View</a>
                        </Col>
                </Row>
            </Col>)} 
        </Row>
        </>
    );
};

export default Phones;
