import React from "react";
import { Col, Row } from 'react-bootstrap';
import { HeaderInfo } from './PDFHeader';


const PDFPersonalInfo: React.FC<HeaderInfo> = (props: HeaderInfo) => {

    const capitalizeFirstLetter = (value: string | undefined) => { return value ? value[0].toUpperCase() + value.substring(1) : '' };
    const imageUrl = props.imageUrl ?? "/images/person.png"
    const name = capitalizeFirstLetter(props.name?.firstName) + ' ' + capitalizeFirstLetter(props.name?.lastName);

    return (
        <Row className="personal-info">
            <Col md="auto" className="p-0"><img src={imageUrl} alt="img" width="86px" height="84px" className="person-img" /></Col>
            <Col className="p-0">
                <div className="name">{name}</div>
                <div className="age">{props.age} years old</div>
            </Col>
        </Row>
    );
};

export default PDFPersonalInfo;
