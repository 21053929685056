import React from "react";

type AgreementProps = {
    closeModal?: () => void;
};

const TosPpChangesAgreement: React.FC<AgreementProps> = (props: AgreementProps) => {

    return (
        <>
            <div className="agreement-content">
                <div className="agreement-title">Update</div>
                <div className="agreement-text">
                    Our Terms and Service and Privacy Policy have been changed. Click the new
                    &nbsp;<a target="_blank" rel="noreferrer" className="username-link" href="https://intertelinc.com/privacy-policy/">Privacy Policy</a>
                    &nbsp;and&nbsp;<a target="_blank" rel="noreferrer" className="username-link" href="https://intertelinc.com/terms-conditions/">Terms and Conditions</a>&nbsp;to read.
                </div>
                <div className="agreement-text">
                    By clicking&nbsp;&apos;Accept&apos;&nbsp;I agree to the current terms and conditions of this site.
                </div>
            </div>
        </>
    );
};

export default TosPpChangesAgreement;
