import React, { useEffect, useState } from "react";
import ConfirmEmailViewModel from "../../view-model/auth/ConfirmEmailViewModel";
import { TailSpin } from "react-loader-spinner";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import AuthApi from "../../../data/auth/AuthApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useParams } from "react-router";
import ErrorAlert from "../../components/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert";
type ConfirmEmailComponentState = {
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: string[];
  isSuccess: boolean;
};

const ConfirmEmail: React.FC = () => {
  const { viewModel, subscription } = useViewModel(ConfirmEmailViewModel, [
    new AuthApi(new HttpClient()),
  ]);
  const [state, setState] = useState<ConfirmEmailComponentState>({
    isSuccess: false,
    isLoading: false,
    isShowError: false,
    errorMessages: [],
  });
  const { token, userId } = useParams();

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );

    viewModel.onTokenQueryChanged(token ? encodeURIComponent(token) : "");
    viewModel.onUserIdQueryChanged(userId ?? "");
    viewModel.submitConfirmation();
    return () => {
      subscriber.unsubscribe();
    };
  }, []);
  return (
    <div className="container">
      {state.isShowError && (
        <div className="mt-5">
          <ErrorAlert
            headerMessage="ConfirmEmailErrorHeaderMessage"
            bodyMessage="ConfirmEmailErrorBodyMessage"
          />
        </div>
      )}
      {!state.isShowError && state.isSuccess && (
        <div className="mt-5">
          <SuccessAlert
            headerMessage="ConfirmEmailHeaderMessage"
            bodyMessage="ConfirmEmailBodyMessage"
          />
        </div>
      )}
      {!state.isShowError && !state.isSuccess && (
        <div className="mt-5">
          <h2 className="title">Confirming Email</h2>
          {state.isLoading && (
            <TailSpin
              wrapperClass="cover-spin"
              visible={state.isLoading}
              height={50}
              width="50"
              color="#FF5733"
              ariaLabel="loading"
            />
          )}
          <br />
        </div>
      )}
    </div>
  );
};
export default ConfirmEmail;
