import React from "react";

const WorkSvgIcon: React.FC = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 3.625H11.375V1.375C11.375 1.07663 11.2565 0.790483 11.0455 0.579505C10.8345 0.368526 10.5484 0.25 10.25 0.25H5.75C5.45163 0.25 5.16548 0.368526 4.9545 0.579505C4.74353 0.790483 4.625 1.07663 4.625 1.375V3.625H1.25C0.951631 3.625 0.665483 3.74353 0.454505 3.9545C0.243526 4.16548 0.125 4.45163 0.125 4.75V12.625C0.125 12.9234 0.243526 13.2095 0.454505 13.4205C0.665483 13.6315 0.951631 13.75 1.25 13.75H14.75C15.0484 13.75 15.3345 13.6315 15.5455 13.4205C15.7565 13.2095 15.875 12.9234 15.875 12.625V4.75C15.875 4.45163 15.7565 4.16548 15.5455 3.9545C15.3345 3.74353 15.0484 3.625 14.75 3.625ZM5.75 1.375H10.25V3.625H5.75V1.375ZM1.25 12.625V4.75H14.75V12.625H1.25Z" fill="#76787B" />
        </svg>
    );
};

export default WorkSvgIcon;