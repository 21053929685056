import { useRef } from "react";
import { filter, Observable, Subject } from "rxjs";
import IBaseViewModel from "../../presentation/view-model/IBaseViewModel";

type SubjectType<TViewModel> = {
    topic: string,
    data: TViewModel
}

const createSubscription = (subject: Subject<any>, topicName: string): Observable<any> => {
    return subject.pipe(filter(f => f.topic === topicName))
}

// eslint-disable-next-line no-empty-pattern
function useViewModel<TViewModel extends IBaseViewModel>(ViewModelType: { new(...[]): TViewModel }, dependencies: Array<any>): 
        {viewModel: TViewModel, subscription: Observable<SubjectType<TViewModel>>} {
    const viewModelRef = useRef<TViewModel | null>(null);
    const subjectRef = useRef(new Subject());

    const topicName = 'ALERT_TOPIC'
    if (viewModelRef.current === null)
        viewModelRef.current = new ViewModelType(...dependencies);
        viewModelRef.current.attachSubject(subjectRef.current, topicName)

    return {
        viewModel: viewModelRef.current,
        subscription: createSubscription(subjectRef.current, topicName)
    }
}

export default useViewModel