import React from "react";
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";
import Experience from "./Experience";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type ExperiencesProps = {
  experiences: ExperienceModel[];
};

const Experiences: React.FC<ExperiencesProps> = (props: ExperiencesProps) => {
  return (
      <div id="__work">

          <SocialResultSectionTitle title="Work" iconUrl="/images/work.svg" />

          {props.experiences && props.experiences.length > 0 && (
              <>
                  {props.experiences.map((experience, index) => {
                      return <Experience experience={experience} key={index} />;
                  })}
              </>
          )}
          {!props.experiences ||
              (props.experiences.length == 0 && (
                  <h5 className="title text-center">No Records found</h5>
              ))}
      </div>
  );
};
export default Experiences;
