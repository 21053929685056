import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import CompanyApi from "../../../data/admin/CompanyApi";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import CompanyDetailsModel from "../../../domain/entities/admin/models/CompanyDetailsModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import AccountPointOfContact from "../../components/add-update-company/AccountPointOfContact";
import BillingInfo from "../../components/add-update-company/BillingInfo";
import BillingPointOfContact from "../../components/add-update-company/BillingPointOfContact";
import MainInfo from "../../components/add-update-company/MainInfo";
import SalesRepresentative from "../../components/add-update-company/SalesRepresentative";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import AddUpdateCompanyViewModel from "../../view-model/admin/AddUpdateCompanyViewModel";


export type CompanyState = {
    company: CompanyDetailsModel;
    statusList: AccountStatusModel[];
    domainFormValues: string[];
    validation: any;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    formIsValid: boolean;
    billingPocSameAsAccountPoc: boolean;
};


const AddUpdateCompany: React.FC = () => {

    const { id } = useParams();

    const { viewModel, subscription } = useViewModel(AddUpdateCompanyViewModel, [
        new CompanyApi(new HttpClient()),
        new UserAccountApi(new HttpClient())
    ]);
    
    const [state, setState] = useState<CompanyState>({
        company: viewModel.company,
        statusList: viewModel.statusList,
        domainFormValues: [""],
        validation: viewModel.validation,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        formIsValid: viewModel.formIsValid,
        billingPocSameAsAccountPoc: viewModel.billingPocSameAsAccountPoc
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) =>
            setState({...state, ...d.data,})
        );

        if (id)
            viewModel.getData(+id);

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    
    return (
        <div className="container">
            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                className="avoid-nav-bar"
                bodyClassName="toast-message"
            />
            <div className="add-update-company-container">
                <Row><Col className="p-0" md="auto"><a href="/admin/company"> <BackArrowSvgIcon /></a></Col>
                    {id && <Col><div className="admin-page-title">Update Company</div></Col>}
                    {!id && <Col><div className="admin-page-title">Create Company</div></Col>}
                </Row>
                <Row className="add-update-company-form">
                    <MainInfo state={state} viewModel={viewModel} />
                    <AccountPointOfContact state={state} viewModel={viewModel} />
                    <BillingPointOfContact state={state} viewModel={viewModel} />
                    <BillingInfo state={state} viewModel={viewModel} />
                    <SalesRepresentative state={state} viewModel={viewModel} />
                </Row>
                <Row className="save-btn-row"><button disabled={!state.formIsValid} onClick={viewModel.saveChanges} className="btn next-btn">Save</button></Row>
            </div>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddUpdateCompany;