import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import GenericDataApi from "../../data/GenericDataApi";
import RequestsCountModel from "../../domain/entities/admin/models/RequestsCountModel";
import GenericDataRepo from "../../domain/repositories/IGenericDataRepo";
import useUser from "../../infrastructure/hooks/useUser";
import { RootState } from "../../infrastructure/redux/reducer";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import { SetPendingRequestsCount } from "../../infrastructure/redux/actions/SetPendingRequestsCount";

const AdminBoardMenuItemText = 'Admin Board';
const HomeMenuItemText = 'Home';
const AppSettings = 'App Settings';

const Header: React.FC = () => {
  const [navbar, setNavbar] = useState(false);
  const { user } = useUser();

  const toggleMenu = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", toggleMenu);
  const [opened, setOpened] = useState(false);
  const navButtonHandle = () => {
    setOpened(!opened);
  };

  const [userButton, setUserButton] = useState(false);
  const userHandleView = () => {
    setUserButton(!userButton);
    };

    const [countState, setCountState] = useState({ pendingRegistrationsCount: 0, pendingRequestsCount: 0 });
    const [dataStatusState, setDataStatusState] = useState({ isLoading: false, isShowError: false });
    const dispatch = useDispatch();
    useSelector((state: RootState) => {
        if (state.pendingRequestsCount != countState.pendingRequestsCount)
            setCountState({ ...countState, pendingRequestsCount: state.pendingRequestsCount });
    });

    useEffect(() => {
        const repo: GenericDataRepo = new GenericDataApi(new HttpClient());
        setDataStatusState({ isShowError: false, isLoading: true });
        repo.GetRequestsCount().then((res) => {
            if (res.isSuccess) {
                setDataStatusState({ isShowError: false, isLoading: false });
                const val = res?.value as RequestsCountModel;
                setCountState({ pendingRegistrationsCount: val?.pendingRegistrationsCount, pendingRequestsCount: val?.pendingRequestsCount });
                dispatch(SetPendingRequestsCount(val.pendingRequestsCount));
            }
            else
                setDataStatusState({ isShowError: true, isLoading: false });
        }).catch(() => setDataStatusState({ isShowError: true, isLoading: false }));
    }, []);
    
  return (
    <header
      className={
        navbar
          ? "theme-main-menu sticky-menu theme-menu-four fixed"
          : "theme-main-menu sticky-menu theme-menu-four"
      }
    >
      <div className="inner-content">
        <div className="d-flex align-items-center">
          <div className="logo order-lg-0">
            <a href="/" className="d-block">
              <img src="/images/INTERTEL-logo-no-tag.png" alt="" width={90} />
            </a>
          </div>
          {user.isUserAuthorized() && (
            <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
              <nav className="navbar navbar-expand-lg order-lg-2">
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item active dropdown">
                      <a
                        onClick={userHandleView}
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                      >
                        Welcome &nbsp; {user.firstName + " " + user.lastName}
                      </a>
                      <ul
                        className={
                          userButton
                            ? "dropdown-menu userView"
                            : "dropdown-menu"
                        }
                      >
                        <li>
                          <a href="/settings" 
                            className="dropdown-item"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "16px 10px",
                              gap: "16px",
                              width: "232px",
                              height: "50px",
                              background: "#FFFFFF",
                              flex: "none",
                              order: "0",
                              alignSelf: "stretch",
                              flexGrow: "0",
                            }}>
                            <span
                              style={{
                                color: "#000000",
                                height: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "18px",
                                width: "100%"
                              }}>
                              My Account Settings
                            </span>
                          </a>
                        </li>
                        <hr></hr>
                        {user.isAdmin && 
                          <>
                            <li>
                               <a href={ window.location.pathname.includes('admin') ? '/' : '/admin/pendingRegistration'  }
                               className="dropdown-item"
                               style={{
                               display: "flex",
                               flexDirection: "row",
                               alignItems: "center",
                               padding: "16px 10px",
                               gap: "16px",
                               width: "232px",
                               height: "50px",
                               background: "#FFFFFF",
                               flex: "none",
                               order: "0",
                               alignSelf: "stretch",
                               flexGrow: "0",
                               color: "#000000",
                               fontStyle: "normal",
                               fontWeight: "400",
                               fontSize: "14px",
                               lineHeight: "18px",
                               }}>
                                 { window.location.pathname.includes('admin') ? HomeMenuItemText : AdminBoardMenuItemText }
                               </a>
                            </li>
                            <hr></hr>
                          </>}
                        <li>
                          <a
                            href={"/bff/logout?sid=" + user.sid}
                            className="dropdown-item"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "16px 10px",
                              gap: "16px",
                              width: "232px",
                              height: "50px",
                              background: "#FFFFFF",
                              flex: "none",
                              order: "0",
                              alignSelf: "stretch",
                              flexGrow: "0",
                            }}
                          >
                            <img
                              src="/images/logout.svg"
                              alt=""
                              style={{
                                width: "16px",
                                height: "16px",
                                color: "#DA1E28",
                              }}
                            />
                            <span
                              style={{
                                color: "#DA1E28",
                                width: "43px",
                                height: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "18px",
                              }}
                            >
                              Logout
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          )}
          <nav className="navbar navbar-expand-lg order-lg-2">
            <div
              className={
                opened
                  ? "collapse navbar-collapse navTarget"
                  : "collapse navbar-collapse"
              }
              id="navbarNav"
            >
              <ul className="navbar-nav mLeft">
                <li className="d-block d-lg-none">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="/images/INTERTEL-logo-no-tag.png"
                        alt=""
                        width={130}
                      />
                    </a>
                  </div>
                </li>
                <li>
                  {" "}
                  {opened && user.isUserAuthorized() && (
                    <div className="right-widget align-items-center ms-auto order-lg-3 userViewExpand">
                      <nav className="navbar navbar-expand-lg order-lg-2">
                        <div className="" id="navbarNav">
                          <ul className="navbar-nav">
                            <li className="nav-item active dropdown">
                              <a
                                onClick={userHandleView}
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                              >
                                Welcome &nbsp;{" "}
                                {user.firstName + " " + user.lastName}
                              </a>
                              <ul
                                className={
                                  userButton
                                    ? "dropdown-menu userView"
                                    : "dropdown-menu"
                                }
                              >
                                <li>
                                  <a
                                    href="/settings"
                                    className="dropdown-item"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      padding: "16px 10px",
                                      gap: "16px",
                                      width: "232px",
                                      height: "50px",
                                      background: "#FFFFFF",
                                      flex: "none",
                                      order: "0",
                                      alignSelf: "stretch",
                                      flexGrow: "0",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#000000",
                                        width: "50px",
                                        height: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                      }}
                                    >
                                      My Account Settings
                                    </span>
                                  </a>
                                </li>
                                <hr></hr>
                                <li>
                                  <a
                                    href={"/bff/logout?sid=" + user.sid}
                                    className="dropdown-item"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      padding: "16px 10px",
                                      gap: "16px",
                                      width: "232px",
                                      height: "50px",
                                      background: "#FFFFFF",
                                      flex: "none",
                                      order: "0",
                                      alignSelf: "stretch",
                                      flexGrow: "0",
                                    }}
                                  >
                                    <img
                                      src="/images/logout.svg"
                                      alt=""
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        color: "#DA1E28",
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: "#DA1E28",
                                        width: "43px",
                                        height: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                      }}
                                    >
                                      Logout
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  )}
                </li>
                {user.isUserAuthorized() ? (
                  (!window.location.pathname.includes('admin') && 
                  <>
                    <li className={window.location.pathname == "/" ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/" role="button">Request{" "}</a>
                    </li>
                    <li className={window.location.pathname.includes("/search") ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/search" role="button"> Search History{" "}</a>
                    </li>
                    <li className={window.location.pathname.includes("/case") ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/case" role="button">Case History{" "}</a>
                    </li>
                  </>) ||
                  (user.isAdmin && window.location.pathname.includes('admin') &&
                  <>
                    <li className={window.location.pathname.includes('/admin/pendingRegistration') ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/admin/pendingRegistration" role="button">{`Pending Registrations (${countState.pendingRegistrationsCount})`}</a>
                    </li>
                    <li className={window.location.pathname.includes("pendingRequests") ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/admin/pendingRequests" role="button">{`Pending Requests (${countState.pendingRequestsCount})`}</a>
                    </li>
                    <li className={window.location.pathname == "/admin/user" ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/admin/user" role="button">All Users{" "}</a>
                    </li>
                    <li className={window.location.pathname == ("/admin/company") ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/admin/company" role="button">Company Information{" "}</a>
                    </li>
                    <li className={window.location.pathname == ("/admin/appSettings") ? "nav-item active" : "nav-item"}>
                      <a className="nav-link" href="/admin/appSettings" role="button">App Settings{" "}</a>
                    </li>
                  </>)
                  ) : (<li className={window.location.pathname.includes("/register") ? "nav-item active" : "nav-item"}></li>)}
              </ul>
            </div>
          </nav>
          <span className="burger" onClick={navButtonHandle}>
            {!opened ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            )}
          </span>
        </div>
          </div>
        <TailSpin
          wrapperClass="cover-spin"
          visible={dataStatusState.isLoading}
          height={50}
          width="50"
          color="#FF5733"
          ariaLabel="loading"
        />
      <Modal show={dataStatusState.isShowError}>
        <Modal.Body className="modal-card warning-modal-card error-modal">
          <div>
            <Row className="warning-modal-header">
              <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
              <Col ><span className="cancel-modal-title">Server Error</span></Col>
              <Col md="auto" className="close-modal-icon"><span onClick={() => setDataStatusState({ ...dataStatusState, isShowError: false })}>x</span></Col>
            </Row>
            <Row>
              <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
            </Row>
            <Row className="warning-modal-btn-row">
              <Col className="p-0"><a onClick={() => setDataStatusState({ ...dataStatusState, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
