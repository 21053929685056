import React from "react";
import { Accordion, Col, Row } from 'react-bootstrap';
import EmailBreachModel from "../../../../domain/entities/searchoutput/EmailBreachModel";


const PDFEmailBreaches: React.FC<{ breaches: EmailBreachModel[] }> = (props: { breaches: EmailBreachModel[] }) => {

    return (
        <>
            <Row>
                <Col className="col-auto img-box px-2">
                    <img width='30px' height='18px' src="/images/search-results/email.png" />
                </Col>
                <Col className="breaches-title">Email Breaches:</Col>
            </Row>
            <table>
                <tr><td className="table-header">Website</td><td className="table-header">Breach Date</td></tr>
                {props.breaches?.map(breach => <tr key={Math.random()}><td>{breach.website}</td><td>{breach.breachDate.toString().split('T')[0]}</td></tr>)}
            </table>
            {props.breaches?.length == 0 && <div className="not-available breaches-not-available">Not Available</div>}
        </>
    );
};

export default PDFEmailBreaches;
