import React from "react";
import { Col } from "react-bootstrap";

type Props = {
    isAdmin: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AdminCheckBox: React.FC<Props> = (props: Props) => {

    return (<Col className="input-group-meta form-group mb-30 form-floating first-col-input">
        <div className="authorized-manager">
            <input
                type="checkbox"
                id="isAdmin"
                checked={props.isAdmin}
                onChange={props.onChange}
            />
            <label className="form-check-label cursor-pointer" htmlFor="isAdmin">Admin</label>
        </div>
    </Col>);
}

export default AdminCheckBox;