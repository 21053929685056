import type UserSettingRepository from "../../domain/repositories/usersettings/IUserSettingRepository";
import Result from "../../domain/common/Result";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import UserAccountModel from "../../domain/entities/usersettings/UserProfileModel";
import SocialConnectionModel from "../../domain/entities/usersettings/SocialConnectionModel";
import PaginationRequestModel from "../../domain/entities/generic/PaginationRequestModel";
import ViewPasswordModel from "../../domain/entities/usersettings/ViewPasswordModel";
import UserSearchRequestsViewPermissionRequestModel from "../../domain/entities/usersettings/UserSearchRequestsViewPermissionRequestModel";
import GlbaDppaChangeRequestModel from "../../domain/entities/usersettings/GlbaDppaChangeRequestModel";
import UserClaimOfficeStatusModel from "../../domain/entities/usersettings/UserClaimOfficeStatusModel";
import UserSocialConnectionModel from "../../domain/entities/usersettings/UserSocialConnectionModel";
import PaginationModel from "../../domain/entities/generic/PaginationModel";
import SocialConnectionTypeModel from "../../domain/entities/usersettings/SocialConnectionTypeModel";
import SocialConnectionPasswordModel from "../../domain/entities/usersettings/SocialConnectionPasswordModel";


export default class UserSettingApi implements UserSettingRepository {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  addSocialConnection(object: SocialConnectionModel): Promise<Result> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/UserSocialConnection/Add`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue(await res.json());
        return Result.Fail("Validation Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  updateSocialConnection(
    object: SocialConnectionModel
  ): Promise<Result> {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Update`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue(await res.json());
        return Result.Fail("Validation Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  getSocialConnectionDetails(id: string): Promise<Result<UserSocialConnectionModel>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Details?id=${id}`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<UserSocialConnectionModel>(await res.json());
        return Result.FailWithValue<UserSocialConnectionModel>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail<UserSocialConnectionModel>(e.message, 500);
      });
  }

  removeSocialConnection(id: string): Promise<Result> {
    const requestOptions = {
      method: "DELETE",
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Delete?id=${id}`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue(await res.json());
        return Result.Fail("Validation Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  listSocialConnection(object: PaginationRequestModel): Promise<Result<PaginationModel<UserSocialConnectionModel>>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/List`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue<PaginationModel<UserSocialConnectionModel>>(await res.json());
        return Result.Fail<PaginationModel<UserSocialConnectionModel>>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<PaginationModel<UserSocialConnectionModel>>(e.message, 500);
      });
  }

  listSocialTypes(): Promise<Result<SocialConnectionTypeModel[]>> {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient
      .httpFetch(
        `/api/SocialConnectionType/List`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue<SocialConnectionTypeModel[]>(await res.json());
        return Result.Fail<SocialConnectionTypeModel[]>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<SocialConnectionTypeModel[]>(e.message, 500);
      });
  }

  viewPassword(object: ViewPasswordModel): Promise<Result<SocialConnectionPasswordModel>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/ViewPassword`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue<SocialConnectionPasswordModel>(await res.json());
        if (res.status === 403) return Result.Fail<SocialConnectionPasswordModel>("Wrong password", 200);
        return Result.Fail<SocialConnectionPasswordModel>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<SocialConnectionPasswordModel>(e.message, 500);
      });
  }

    getUserAccount(): Promise<Result<UserAccountModel>> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(object)
    };

    return this.httpClient
      .httpFetch(`/api/User/GetUserAccount`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<UserAccountModel>(await res.json());
        return Result.Fail<UserAccountModel>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<UserAccountModel>(e.message, 500);
      });
  }

    updateUserAccount(object: UserAccountModel): Promise<Result> {
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/User/UpdateUserAccount`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue(await res.json());
        return Result.FailWithValue("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
    }

    GetSearchRequestsViewPermissionRequest = (): Promise<Result<UserSearchRequestsViewPermissionRequestModel[]>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        return this.httpClient.httpFetch(`/api/ViewPermissions/GetSearchRequestsViewPermissionRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<UserSearchRequestsViewPermissionRequestModel[]>(await res.json());
            else if (res.status == 401)
                return Result.Fail<UserSearchRequestsViewPermissionRequestModel[]>('Unauthorized', 401);
            return Result.Fail<UserSearchRequestsViewPermissionRequestModel[]>('Server Error', res.status);
        }).catch(e => {
            return Result.Fail<UserSearchRequestsViewPermissionRequestModel[]>(e.message, 500);
        });
    }

    AddSearchRequestsViewPermissionRequest = (requestedUsersEmails: string): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestedUsersEmails)
        };
        return this.httpClient.httpFetch(`/api/ViewPermissions/AddSearchRequestsViewPermissionRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    addGlbaDppaChangeRequest(object: GlbaDppaChangeRequestModel): Promise<Result> {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };

        return this.httpClient
            .httpFetch(`/api/GlbaDppa/AddGlbaDppaChangeRequest`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
               else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
               return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    getGlbaDppaChangeRequest(): Promise<Result<GlbaDppaChangeRequestModel>> {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/GlbaDppa/GetGlbaDppaChangeRequest`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaChangeRequestModel>(await res.json());
                else if (res.status == 401)
                    return Result.Fail<GlbaDppaChangeRequestModel>('Unauthorized', 401);
                return Result.Fail<GlbaDppaChangeRequestModel>('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    getAccountGlbaDppa(): Promise<Result<GlbaDppaChangeRequestModel>> {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/User/GetAccountGlbaDppa`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaChangeRequestModel>(await res.json());
                else if (res.status == 401)
                    return Result.Fail<GlbaDppaChangeRequestModel>('Unauthorized', 401);
                return Result.Fail<GlbaDppaChangeRequestModel>('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    updateAccountCommunication(sendSearchRequestsCompletionEmail: boolean): Promise<Result<string>> {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(sendSearchRequestsCompletionEmail)
        };

        return this.httpClient
            .httpFetch(`/api/User/UpdateAccountCommunication`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    acceptTermsAndConditionAndPrivacyPolicyLastVersion(): Promise<Result<string>>{
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/User/AcceptLastTermsAndConditionsAndPrivacyPolicyVersion`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    DeleteAllUserSearchRequestPermissions(id: string): Promise<Result<string>> {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/ViewPermissions/DeleteAllUserSearchRequestPermissions/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    getUserClaimOfficeStatus(): Promise<Result<UserClaimOfficeStatusModel>>{
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return this.httpClient.httpFetch(`/api/User/ClaimOfficeStatus`, requestOptions).then(async res => {
        if (res.status === 200)
            return Result.OkWithValue<UserClaimOfficeStatusModel>(await res.json());
        return Result.Fail<UserClaimOfficeStatusModel>('Server Error', res.status);
    }).catch(e => {
        return Result.Fail<UserClaimOfficeStatusModel>(e.message, 500);
    });
    }
}
