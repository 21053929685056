import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { TailSpin } from "react-loader-spinner";
import CaseApi from "../../data/cases/CaseApi";
import useViewModel from "../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import FloatingLabelControl from "../components/FloatingLabelControl";
import CaseViewModel from "../view-model/cases/CaseViewModel";

type CreateCaseProps = {
  id: string | null;
  closeModal?: () => void;
};

type CaseState = {
  id: string;
  name: string;

  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: Array<string>;
};

const CreateCase: React.FC<CreateCaseProps> = (props: CreateCaseProps) => {
  const { viewModel, subscription } = useViewModel(CaseViewModel, [
    new CaseApi(new HttpClient()),
  ]);
  const [state, setState] = useState<CaseState>({
    id: "",
    name: "",
    validation: {},
    isLoading: false,
    isShowError: false,
    errorMessages: [],
  });

  const id = props.id;

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );
    if (id) {
      viewModel.onIdQueryChanged(id);
    }
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="case-modal-header">
        <h2 className="new-case-title">New Case</h2>
        <img
          className="modal-close-icon"
          src="/images/close.svg"
          alt=""
          onClick={props.closeModal}
        />
      </div>
      <div className="wid">
        <div className="case-form col-md-12">
          <div className="input-group-meta form-group mb-30 form-floating wid">
            <input autoComplete="new-password"
              type="text"
              placeholder="Enter Case Name"
              name="name"
              id="name"
              onChange={viewModel.onNameQueryChanged}
              value={state.name}
              className={`form-control wid ${state.validation.name
                  ? "case-form-input is-invalid"
                  : "case-form-input"
                }`}
            />
            <label className="case-form-label">Case name</label>

            {state.validation.name && (
              <div className="invalid-feedback">{state.validation.name}</div>
            )}
          </div>
        </div>

        <TailSpin
          wrapperClass="cover-spin"
          visible={state.isLoading}
          height={50}
          width="50"
          color="#FF5733"
          ariaLabel="loading"
        />

        {state.isShowError &&
          state.errorMessages.map((error, i) => {
            return (
              <div
                key={i}
                className="row my-3 text-danger justify-content-center"
              >
                {error}
              </div>
            );
          })}

        <div className="case-modal-footer">
          <button className="case-close-modal-btn" onClick={props.closeModal}>
            <p className="close-modal-btn-content">Cancel</p>
          </button>
          <button
            type="button"
            className="confirm-modal-btn"
            onClick={(): Promise<void> => viewModel.onClick()}
          >
            {id && <p className="confirm-modal-btn-content">Edit Case</p>}
            {!id && <p className="confirm-modal-btn-content">Create Case</p>}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateCase;
