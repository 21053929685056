import { useEffect, useState } from 'react'
import useSWR from "swr";
import AuthApi from '../../data/auth/AuthApi';
import AuthHolder from '../../domain/entities/auth/models/AuthHolder';
import UserSession from '../../domain/entities/auth/structures/UserSessionModel';
import { HttpClient } from '../utils/fetchInterceptor';

const useUser = ({
  redirectTo = '',
  redirectIfFound = false,
} = {}) => {
  const httpClient = new HttpClient()
  const { data, mutate: mutateUser } = useSWR<UserSession>('user_session', new AuthApi(httpClient).loadSession)
  const [user, setUser] = useState(new AuthHolder());

  useEffect(() => {
      if (data) setUser(new AuthHolder().onSessionLoad(data));
      if (!redirectTo || !data) return;

      if (
        (redirectTo && !redirectIfFound && !user?.isUserAuthorized()) ||
        (redirectIfFound && user?.isUserAuthorized())
      ) {
        window.location.assign(redirectTo)
      }
  }, [data, redirectIfFound, redirectTo]);

  return { user, mutateUser };
}

export default useUser;