import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import UserViewPermissions from "./UserViewPermissions";
import ListPermittedUsers from "../../components/user-settings/ListPermittedUsers";

type PermissionsState = {
    showRequestManagerAccessModal: boolean,
    isLoading: boolean,
    isShowError: boolean
}

const Permissions: React.FC = () => {

    const [state, setState] = useState<PermissionsState>({ isLoading: false, isShowError: false, showRequestManagerAccessModal: false });

    return (<div className="tracking-wrapper">
        <Row>
            <Col></Col>
            <Col md="auto">
                <button className="btn next-btn request-manager-access" onClick={() => setState({ ...state, showRequestManagerAccessModal: true })}>Request User Access</button>
            </Col>
        </Row>
        <ListPermittedUsers/>
        <Modal show={state.showRequestManagerAccessModal} className="request-access-modal">
            <Modal.Body className="permission-modal-card">
                <div>
                    <Row className="warning-modal-header">
                        <Col ><span className="cancel-modal-title">Request User Access</span></Col>
                        <Col md="auto" className="close-modal-icon" onClick={() => setState({ ...state, showRequestManagerAccessModal: false })}><span>x</span></Col>
                    </Row>
                    <UserViewPermissions/>
                </div>
            </Modal.Body>
        </Modal>
        <TailSpin
            wrapperClass="cover-spin"
            visible={state.isLoading}
            height={50}
            width="50"
            color="#FF5733"
            ariaLabel="loading"
        />
        <Modal show={state.isShowError}>
            <Modal.Body className="modal-card warning-modal-card error-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Server Error</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    </div>);
};

export default Permissions;
