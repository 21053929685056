import React, { Fragment } from 'react';
import ScrollLinksDisplay from '../../../domain/entities/searchoutput/scrollLinksDisplay'

type ScrollLinksDisplayProps = {
    display: ScrollLinksDisplay;
}

const SearchResultLeftMenuLinks: React.FC<ScrollLinksDisplayProps> = (props: ScrollLinksDisplayProps) => {
    return (
        <Fragment>

            {props.display.address && (
                <div className="search-result-left-menu-item">
                    <a href="#__address-history" className="search-result-scroll-link">
                        <img src="/images/location.svg" />
                        <label>Address History</label>
                    </a>
                </div>)}

            {props.display.education && (
                <div className="search-result-left-menu-item">
                    <a href="#__education" className="search-result-scroll-link">
                        <img src="/images/education.svg" />
                        <label>Education</label>
                    </a>
                </div>)}

            {props.display.email && (
                <div className="search-result-left-menu-item">
                    <a href="#__email" className="search-result-scroll-link">
                        <img src="/images/email.svg" />
                        <label>Email</label>
                    </a>
                </div>)}

            {props.display.phone && (
                <div className="search-result-left-menu-item">
                    <a href="#__phone" className="search-result-scroll-link">
                        <img src="/images/phone.svg" />
                        <label>Phone</label>
                    </a>
                </div>)}

            {props.display.socilaMedia && (
                <div className="search-result-left-menu-item">
                    <a href="#__social-media" className="search-result-scroll-link">
                        <img src="/images/social-media.svg" />
                        <label>Social Media</label>
                    </a>
                </div>)}

            {props.display.relatives && (
                <div className="search-result-left-menu-item">
                    <a href="#__relatives" className="search-result-scroll-link">
                        <img src="/images/relatives.svg" />
                        <label>Relatives</label>
                    </a>
                </div>)}

            {props.display.work && (
                <div className="search-result-left-menu-item">
                    <a href="#__work" className="search-result-scroll-link">
                        <img src="/images/work.svg" />
                        <label>Work</label>
                    </a>
                </div>)}

            {props.display.internetResults && (
                <div className="search-result-left-menu-item">
                    <a href="#__internet-search" className="search-result-scroll-link">
                        <img src="/images/internet-search.svg" />
                        <label>Internet Searches</label>
                    </a>
                </div>)}

        </Fragment>
    );
};

export default SearchResultLeftMenuLinks;
