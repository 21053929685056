import React, { } from "react";
import NameModel from "../../../domain/entities/generic/NameModel";

type MainInfoProps = {
    pictureUrl: string;
    name: NameModel;
    age: string;
}

const MainInfo: React.FC<MainInfoProps> = (props: MainInfoProps) => {
    if (props.name.firstName) {
        props.name.firstName = props.name.firstName[0].toUpperCase() + props.name.firstName.substring(1)
    }
    if (props.name.firstName) {
        props.name.lastName = props.name.lastName[0].toUpperCase() + props.name.lastName.substring(1)
    }
    return (
        <div className="output-main-info-div">
            {!props.pictureUrl && (
                <img src="/images/person.png" className="output-picture" alt="img" />
            )}
            {props.pictureUrl && (
                <img src={props.pictureUrl} className="output-picture" alt="img" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/person.png";
                }} />
            )}
            <div className="output-name-div">
                <p className="output-name">{props.name.firstName} {props.name.lastName}</p>
                {props.age &&
                    <p className="output-age">{props.age} years old</p>
                }
            </div>
        </div>
    );
}
export default MainInfo;
