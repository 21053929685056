import React from "react";

const SocialMediaSvgIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.125C6.44248 0.125 4.91992 0.586861 3.62489 1.45218C2.32985 2.31749 1.32049 3.5474 0.724452 4.98637C0.128412 6.42534 -0.0275391 8.00874 0.276319 9.53634C0.580178 11.0639 1.3302 12.4671 2.43154 13.5685C3.53288 14.6698 4.93607 15.4198 6.46367 15.7237C7.99127 16.0275 9.57467 15.8716 11.0136 15.2756C12.4526 14.6795 13.6825 13.6702 14.5478 12.3751C15.4131 11.0801 15.875 9.55753 15.875 8C15.875 5.91142 15.0453 3.90838 13.5685 2.43153C12.0916 0.954685 10.0886 0.125 8 0.125ZM14.75 7.4375H11.375C11.3093 5.36586 10.7726 3.3364 9.80563 1.50312C11.1379 1.86757 12.3264 2.63197 13.2106 3.69317C14.0947 4.75437 14.632 6.06128 14.75 7.4375ZM8 14.75C7.87452 14.7584 7.74861 14.7584 7.62313 14.75C6.45779 12.8916 5.81104 10.7552 5.75 8.5625H10.25C10.194 10.7536 9.55316 12.8899 8.39375 14.75C8.26267 14.7592 8.13109 14.7592 8 14.75ZM5.75 7.4375C5.80596 5.24638 6.44685 3.11009 7.60625 1.25C7.85672 1.22186 8.10954 1.22186 8.36 1.25C9.53128 3.1067 10.1839 5.24322 10.25 7.4375H5.75ZM6.1775 1.50312C5.21636 3.3378 4.68545 5.36719 4.625 7.4375H1.25C1.36796 6.06128 1.90528 4.75437 2.78945 3.69317C3.67361 2.63197 4.86206 1.86757 6.19438 1.50312H6.1775ZM1.27813 8.5625H4.65313C4.71183 10.6324 5.24081 12.6617 6.2 14.4969C4.87186 14.1285 3.68832 13.3624 2.80844 12.3015C1.92856 11.2407 1.39453 9.93585 1.27813 8.5625ZM9.80563 14.4969C10.7726 12.6636 11.3093 10.6341 11.375 8.5625H14.75C14.632 9.93872 14.0947 11.2456 13.2106 12.3068C12.3264 13.368 11.1379 14.1324 9.80563 14.4969Z" fill="#76787B" />
        </svg>
    );
};

export default SocialMediaSvgIcon;