import React from "react";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import EmailBreaches from "./EmailBreaches"
import GoogleSearch from "./GoogleSearch";

type Props = {
    emails: Array<EmailModel>;
}

const EmailList: React.FC<Props> = ({ emails }) => {
    if (emails.length === 0) {
        return <></>
    }

    return (
        <>
            {
                emails.map(email => {
                    return (
                        <>
                            <div className="education-box-body" key={Math.random()}>
                                <div className="address-inner-content">
                                    <img
                                        src={"/images/email.svg"}
                                        className="output-tab-content-icon"
                                        alt="img"
                                    />
                                    <p className="address-text">{email.email} </p>
                                </div>
                                {email.connectedSites &&
                                    <div className="education-card-body">
                                        <div className="education-inner-card-body">
                                            <div className=" education-inner-text-div-card-body">
                                                <p className="education-inner-title-card-body">Connected Sites: </p>
                                                <p className="education-inner-text-card-body">
                                                    {email.connectedSites}
                                                </p>
                                            </div>
                                        </div>
                                    </div>}
                                {email?.emailBreaches?.length > 0 && <EmailBreaches breaches={email.emailBreaches} />}
                                <GoogleSearch email={email.email} />
                            </div>
                        </>
                    );
                })
            }
        </>
    );
}
export default EmailList;
