import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

type Props = {
    isShow: boolean;
    onClose: () => void;
    onAddingRequest: () => void;
}

const AuthorizedManagerRequestModal: React.FC<Props> = (props: Props) => {

    return (<Modal show={props.isShow}>
        <Modal.Body className="modal-card warning-modal-card authorized-manager-warning-modal">
            <div>
                <Row className="warning-modal-header">
                    <Col className="p-0"><span className="cancel-modal-title">Request Authorized Manager</span></Col>
                    <Col md="auto" className="close-modal-icon"><span onClick={props.onClose}>x</span></Col>
                </Row>
                <Row>
                    <p className="p-0 warning-modal-body">You are requesting to be an authorized manager for your organization. Your request will be confirmed with your company representative and then approved. Do you wish to continue?</p>
                </Row>
                <Row className="authorized-manager-request-btn-row">
                    <Col md="auto" className="p-0"><a onClick={props.onClose} className="btn previous-btn">No, I want to cancel</a></Col>
                    <Col className="p-0"><a onClick={props.onAddingRequest} className="btn next-btn">Yes, I want to continue</a></Col>
                </Row>
            </div>
        </Modal.Body>
    </Modal>);
}

export default AuthorizedManagerRequestModal;