import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import useUser from "../../infrastructure/hooks/useUser";
import { RootState } from "../../infrastructure/redux/reducer";
import LoginAgreement from "../utils/LoginAgreement";
import GenericServerError from "./GenericServerError";
import Header from "./Header";
import { Col, Modal, Row } from "react-bootstrap";
import Agreement from "../views/Agreement";
import TosPpChangesAgreement from "../views/TosPpChangesAgreement";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import UserSettingApi from "../../data/usersettings/UserSettingApi";

function Layout(props: any) {

    const { errorCode } = useSelector((state: RootState) => state.error)
    const { user } = useUser();
    const allowAnonymousPaths = ['register', 'terms-and-conditions', 'privacy-policy', 'forgetpassword', 'setPassword'];

    const [justLoggedIn, setJustLoggedIn] = useState(LoginAgreement.getAgreeStatus());
    const [acceptModals, setAcceptModals] = useState({ showEveryLoginModal: false, showTermsModal: false });
    const [isShowError, setIsShowError] = useState(false);

    useEffect(() => {
        if (justLoggedIn == 'true' && user.termsAndConditionsAndPrivacyPolicyUpdated)
            setAcceptModals({ showEveryLoginModal: false, showTermsModal: true });
        else if (justLoggedIn == 'true')
            setAcceptModals({ showEveryLoginModal: true, showTermsModal: false });
    }, [justLoggedIn, user.termsAndConditionsAndPrivacyPolicyUpdated]);

    if (!user.isLoading && !user.isUserAuthorized() && !allowAnonymousPaths.some(p => window.location.pathname.includes(p))) {
        LoginAgreement.onBeforeLogin();
        return <></>;
    }

    const acceptEveryLoginModal = () => {
        if (!user.termsAndConditionsAndPrivacyPolicyUpdated) {
            LoginAgreement.onAgree();
            setJustLoggedIn('false');
        }
        setAcceptModals({ showEveryLoginModal: false, showTermsModal: false });
    };

    const acceptTermsModal = () => {
        const api = new UserSettingApi(new HttpClient());
        api.acceptTermsAndConditionAndPrivacyPolicyLastVersion().then(c => {
            if (c.isSuccess) {
                user.termsAndConditionsAndPrivacyPolicyUpdated = false;
                setAcceptModals({ showTermsModal: false, showEveryLoginModal: true });
            }
            else {
                setAcceptModals({ showEveryLoginModal: false, showTermsModal: false });
                setIsShowError(true);
            }
        }).catch(e => {
            setAcceptModals({ showEveryLoginModal: false, showTermsModal: false });
            setIsShowError(true);
        });
    }

        return (
            <>
                {!user.isLoading && !allowAnonymousPaths.some(p => window.location.pathname.includes(p)) &&
                    <>
                    <Modal className="agreement" backdrop="static" size="lg" show={acceptModals.showTermsModal}>
                        <Modal.Body >
                            <TosPpChangesAgreement />
                            <div className="accept-btn-wrapper">
                                <button className="btn next-btn" onClick={acceptTermsModal}>Accept</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal className="agreement" backdrop="static" size="lg" show={acceptModals.showEveryLoginModal}>
                        <Modal.Body >
                            <Agreement />
                            <div className="accept-btn-wrapper">
                                <button className="btn next-btn" onClick={acceptEveryLoginModal}>Accept</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    </>      
                }
                {(!user.isLoading && justLoggedIn == 'false' || allowAnonymousPaths.some(p => window.location.pathname.includes(p))) &&
                    <div className="main-page-wrapper">
                        {!allowAnonymousPaths.some(p => window.location.pathname.includes(p)) && <Header />}
                        <div className="theme-inner-banner">
                            {errorCode && <GenericServerError errorCode={errorCode} />}
                            {!errorCode && <main> {props.children} </main>}
                            {!allowAnonymousPaths.some(p => window.location.pathname.includes(p)) &&
                                <div className="footer">
                                    <div>
                                        <a href="https://intertelinc.com/privacy-policy/">Privacy Policy</a>
                                        &nbsp;&nbsp;&nbsp;
                                        <a href="https://intertelinc.com/terms-conditions/">Terms and Conditions</a>
                                    </div>
                                </div>}
                        </div>
                    </div >
                }
                {user.isLoading &&
                    <div className="main-page-wrapper">
                        <div className="theme-inner-banner">
                            <div className="row mt-5">
                                <TailSpin wrapperClass="table-spinner" visible={user.isLoading} height={50} width="50" color="#FF5733" ariaLabel="loading"/>
                            </div>
                        </div>
                    </div>
                }
                {<Modal show={isShowError}>
                    <Modal.Body className="modal-card warning-modal-card error-modal registration-error-modal">
                        <div>
                             <Row className="warning-modal-header">
                                 <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                 <Col ><span className="cancel-modal-title">Server Error</span></Col>
                             </Row>
                                <Row> Error while trying to accept terms and conditions and privacy policy new version </Row>
                        </div>
                    </Modal.Body>
                </Modal>}
            </>
        );
    }
export default Layout;
