import React, { useState } from "react";
import { Col, Row, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchDetails from "../../../views/SearchDetails";
import EmailSvgIcon from "../../svg-icons/EmailSvgIcon";
import HomeSvgIcon from "../../svg-icons/HomeSvgIcon";
import PhoneSvgIcon from "../../svg-icons/PhoneSvgIcon";
import { HeaderInfo } from "./Header";
import RequestDetail from "./RequestDetail";


const RequestDetails: React.FC<HeaderInfo> = (props: HeaderInfo) => {

    const [isShowModal, showModal] = useState(false);  
    const renderTooltip = (p: any) => ( <Tooltip id="button-tooltip" {...p}>View</Tooltip>);

    return (
        <Row className="request-details-info">
            <Row className="title">
                <Col md={"auto"} className="request-details p-0">Request Details</Col>
                <Col md={"auto"} onClick={() => showModal(true)} className="view-details p-0">
                    <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderTooltip}>
                        <div><span className="view-details-title" >View request details</span><img className="details-img" src="/images/details.svg" /></div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="details p-0">
                <RequestDetail title="Address" value={props.address}><HomeSvgIcon /></RequestDetail>
                <RequestDetail title="Phone" value={props.phone}><PhoneSvgIcon /></RequestDetail>
                <RequestDetail title="Email" value={props.email}><EmailSvgIcon /></RequestDetail>
            </Row>
            <Modal show={isShowModal} onHide={() => showModal(false)} size="lg">
                <div className="searc-details-modal">
                    <Modal.Body>
                        <SearchDetails data={props.searchDetails || null} onCloseModal={() => showModal(false)} onRerunSearch={props.onRerunSearch || {} as (() => null)} />
                    </Modal.Body>
                </div>
            </Modal>
        </Row>
    );
};

export default RequestDetails;