import React from "react";
import { Col, Row } from "react-bootstrap";

type RequestDetailProps = {
    title: string | undefined;
    value: string | undefined;
};

const RequestDetail: React.FC<RequestDetailProps> = ({ children, title, value }) => {

    return (
        <Col md="auto" className="detail">
            <Row>
                <Col md="auto" className="header-img-box" >{children}</Col>
                <Col md="auto" className="info">
                    <Row className="title">{title}</Row>
                    <Row className="value">{value}</Row>
                </Col>
            </Row>
        </Col>
    );
};

export default RequestDetail;
