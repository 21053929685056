import React from "react";
import { Col, Row } from "react-bootstrap";


type Props = {
    DateOfAcceptance: Date | null;
}

const TermsAndConditionsAndPrivacyPolicy: React.FC<Props> = (props: Props) => {

    return (
        <div className="tos-pp-version">
            <Row className="header-section p-0">
                <Col md="auto" className="section-title">Terms and Condition and Privacy Policy Accepted Version</Col>
                <Col><hr /></Col>
            </Row>

            <Row>
                <div className="col-md-6">
                    <b className="date-title p-0">Date of Last Accepted Version: {props.DateOfAcceptance ? new Date(props.DateOfAcceptance).toLocaleDateString() : '-'}</b>
                </div>
            </Row>
        </div>
    );
}

export default TermsAndConditionsAndPrivacyPolicy;