import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TailSpin } from "react-loader-spinner";
import CompanyApi from "../../../data/admin/CompanyApi";
import CompanyModel from "../../../domain/entities/admin/models/CompanyModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import CompaniesViewModel from "../../view-model/admin/CompainesViewModel";
import { Helmet } from "react-helmet";


type CompanyListState = {
    companies: CompanyModel[];
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];

    pageIndex: number;
    pageSize: number;
    totalCount: number;
};


const Companies: React.FC = () => {

    const { viewModel, subscription } = useViewModel(CompaniesViewModel, [
        new CompanyApi(new HttpClient()),
    ]);

    const [state, setState] = useState<CompanyListState>({
        companies: viewModel.companies,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });

        viewModel.getData();

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    const columns: ColumnDescription[] = [
        {
            dataField: "name",
            text: "Name"
        },
        {
            dataField: "address",
            text: "Address",
            formatter: (cell, row) => <span className="company-address">{row.address}</span>
        },
        {
            dataField: "domains",
            text: "Domains"
        },
        {
            dataField: "pricePerSearch",
            text: "Price/Search",
            formatter: (cell, row) => <span>{row.pricePerSearch} $</span>
        },
        {
            dataField: "creationDate",
            text: "Creation Date",
            formatter: (cell, row) => new Date(row.creationDate).toLocaleDateString()
        },
        {
            dataField: "",
            text: "Actions",
            formatter: (cell, row) => <a href={`/admin/company/${row.id}`} className="btn next-btn">View/Edit</a>
        }
    ];


    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - Company Information</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div>
                <Row>
                    <Col><div className="admin-page-title">Company Information</div></Col>
                    <Col md="auto"><a href="/admin/company/create" className="btn next-btn">Add Company</a></Col>
                </Row>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="searchId"
                        columns={columns}
                        data={state.companies}
                        bordered={false}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: state.pageIndex + 1,
                            sizePerPage: state.pageSize,
                            totalSize: state.totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: viewModel.onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10},
                                { text: "25", value: 25},
                                { text: "50", value: 50},
                                { text: "100", value: 100},
                              ]
                        })}
                        onTableChange={viewModel.onTableChange}
                    />
                </div>
            </div>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Companies;