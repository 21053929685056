import React from "react";
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";

type InternetSearchProps = {
    internetSearch: InternetResultModel;
};

const InternetSearch: React.FC<InternetSearchProps> = (props: InternetSearchProps) => {
    return (
        <div className="education-box-body">
            <div className="address-inner-content">
                <img
                    src="/images/internet-search.svg"
                    className="output-tab-content-icon"
                    alt="img"
                />
                <a href={props.internetSearch.url} className="address-text">
                    {props.internetSearch.url.replace('http://', '').replace('https://', '')}
                </a>
            </div>
            <div className="education-inner-card-body">
                {
                    props.internetSearch.searchTitle &&
                    <div className="  education-inner-text-div-card-body">
                        <p className="education-inner-title-card-body">Title: </p>
                        <p className="education-inner-text-card-body">
                            {props.internetSearch.searchTitle}
                        </p>
                    </div>
                }
                {
                    props.internetSearch.searchContent &&
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">
                                Breif:{" "}
                            </p>
                            <p className="education-inner-text-card-body">
                                {props.internetSearch.searchContent}
                            </p>
                        </div>
                }
            </div>
        </div>
    );
};
export default InternetSearch;
