import React from "react";
import PhoneModel from "../../../domain/entities/searchoutput/PhoneModel";

type PhoneProps = {
  phone: PhoneModel;
};

const Phone: React.FC<PhoneProps> = ({ phone }) => {
  const { name, carrier, country, timeZone, connectedSocialMedia } =
    phone.moriartyOutput;

  return (
      <div className="education-box-body">
          <div className="address-inner-content">
              <img
                  src="/images/phone.svg"
                  className="output-tab-content-icon"
                  alt="img"
              />
              <p className="address-text">{phone.phoneNumber} </p>
          </div>
          <div className="education-card-body">
              <div className="education-inner-card-body">
                  {country && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">
                              City/State:{" "}
                          </p>
                          <p className="education-inner-text-card-body">{country}</p>
                      </div>
                  )}
                  {timeZone && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">Timezone: </p>
                          <p className="education-inner-text-card-body">{timeZone}</p>
                      </div>
                  )}
                  {carrier && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">Carrier: </p>
                          <p className="education-inner-text-card-body">{carrier}</p>
                      </div>
                  )}

                  {connectedSocialMedia && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">
                              Social Media Reports:{" "}
                          </p>
                          <p className="education-inner-text-card-body">
                              {connectedSocialMedia}
                          </p>
                      </div>
                  )}
              </div>
          </div>
      </div>
  );
};
export default Phone;
