import React from "react";
import CardText from "../../components/CardText";

type Props = {
    phones: Array<string>;
}

const PhoneList: React.FC<Props> = ({ phones }) => {

    if (phones.length === 0) {
        return <></>
    }

    return (
        <>
            {
                phones.map(phoneItem => {
                    return (
                        <div key={Math.random()}>
                            <div className="address-content">
                                <div className="address-inner-content">
                                    <img src={"/images/phone.svg"} className="output-tab-content-icon" alt="img" />
                                    <p className="address-text">{phoneItem} </p>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
}
export default PhoneList;
