import React, { useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";
import AddressSvgIcon from "../svg-icons/AddressSvgIcon";
import ResultCount from "./ResultCount";

export type Props = {
    addresses: AddressModel[];
}


const Addresses: React.FC<Props> = ({addresses}: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    return (
        <>
            <ResultCount count={addresses?.length ?? 0} text={isPrintView ? "Addresses" : null}/>
            <Row className="addresses">
                <Col className="address-list">
                    {addresses.map(a => 
                        <Row className="address-card box-shadow" key={Math.random()}>
                            <Col className="col-auto img-box">
                                <AddressSvgIcon />
                            </Col>
                            <Col className="p-0 text">
                                {a.addressLine}
                            </Col>
                    </Row>)}
                </Col>
                <Col xs={8}>
                    <img className="col-12 my-1" src={`https://maps.googleapis.com/maps/api/staticmap?
                zoom=15&size=600x300&maptype=roadmap&${addresses
                    .map((a) => "markers=color:orange|size:small|" + encodeURI(a.addressLine))
                    .join("&").replaceAll("#","")}&key=AIzaSyDQRfEFtUUxMHTbo2oOj6GIN6mh1_syEK8`}
                        />
                </Col>
                

            </Row>
        </>
    );
};

export default Addresses;
