import * as actionTypes from "./actionTypes";

// Action creator
export function setReRunSearch(initPayload: ReRunSearchState): ActionType {
  const action: ActionType = {
    type: actionTypes.SET_ReRun_Search,
    payload: { ...initPayload },
  };
  return action;
}
