import React from "react";

const EducationSvgIcon: React.FC = () => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.25 1.25V0.125H13.75V1.25H10.9375V5.1875C10.9375 6.23179 10.5227 7.23331 9.78423 7.97173C9.04581 8.71016 8.04429 9.125 7 9.125C5.95571 9.125 4.95419 8.71016 4.21577 7.97173C3.47734 7.23331 3.0625 6.23179 3.0625 5.1875V1.25H0.25ZM9.8125 1.25H4.1875V2.9375H9.8125V1.25ZM5.01126 7.17624C5.53871 7.70368 6.25408 8 7 8C7.74592 8 8.46129 7.70368 8.98874 7.17624C9.51618 6.64879 9.8125 5.93342 9.8125 5.1875V4.0625H4.1875V5.1875C4.1875 5.93342 4.48382 6.64879 5.01126 7.17624ZM0.8125 2.375C0.663316 2.375 0.520242 2.43426 0.414753 2.53975C0.309263 2.64524 0.25 2.78832 0.25 2.9375V8H1.375V2.9375C1.375 2.78832 1.31574 2.64524 1.21025 2.53975C1.10476 2.43426 0.961684 2.375 0.8125 2.375ZM12.625 15.875H11.5V14.1875C11.4991 13.4418 11.2025 12.727 10.6753 12.1997C10.148 11.6725 9.43315 11.3759 8.6875 11.375H5.3125C4.56685 11.3759 3.85199 11.6725 3.32473 12.1997C2.79748 12.727 2.50088 13.4418 2.5 14.1875V15.875H1.375V14.1875C1.37622 13.1436 1.79146 12.1428 2.52962 11.4046C3.26778 10.6665 4.26858 10.2512 5.3125 10.25H8.6875C9.73141 10.2512 10.7322 10.6665 11.4704 11.4046C12.2085 12.1428 12.6238 13.1436 12.625 14.1875V15.875Z" fill="#76787B" />
        </svg>
    );
};

export default EducationSvgIcon;