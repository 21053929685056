import React from "react";
import { Col, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";
import { UserInfoComponentState } from "../../views/admin/UserInfoEdit";
import UserInfoViewModel from "../../view-model/admin/UserInfoEditViewModel";


type Props = {
    state: RegisterComponentState | UserInfoComponentState,
    viewModel: RegisterViewModel | UserInfoViewModel
}

const AccountPasswordRecovery: React.FC<Props> = ({ state, viewModel }) => {
    

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Account/Password Recovery</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <select
                    name="Question"
                    id="questionId"
                    className={`form-control ${state.validation.question ? "is-invalid" : ""}`}
                    onChange={viewModel.onSecurityQuestionChange}
                    value={state.questionId}>
                    <option value={0}></option>
                    {state.Questions.map(q => <option key={Math.random()} value={q.id}>{q.text}</option>)}
                </select>
                <label className="form-label">Security Question<span className="mandatory-symbol">*</span></label>
                {state.validation.question && (
                    <div className="invalid-feedback">
                        {state.validation.question}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Answer"
                    name="answer"
                    id="answer"
                    onChange={viewModel.onSecurityQuestionAnswerChange}
                    value={state.answer}
                    className={`form-control ${state.validation.answer ? "is-invalid" : ""}`}
                    disabled={!+state.questionId}
                />
                <label className="form-label">Answer<span className="mandatory-symbol">*</span></label>
                {state.validation.answer && (
                    <div className="invalid-feedback">
                        {state.validation.answer}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col md="6">
                <div className="input-group-meta form-group mb-30 form-floating first-col-input">
                    <input autoComplete="new-password"
                        type="text"
                        placeholder="Secondary Email"
                        name="secondaryEmail"
                        id="secondaryEmail"
                        onChange={viewModel.onEmailChange}
                        value={state.secondaryEmail ?? ''}
                        className={`form-control ${state.validation.secondaryEmail ? "is-invalid" : ""}`}
                    />
                    <label className="form-label">Secondary Email</label>
                    {state.validation.secondaryEmail && (
                        <div className="invalid-feedback">
                            {state.validation.secondaryEmail}
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    </>
};

export default AccountPasswordRecovery;
