import React from "react";
import { Col, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";
import { UserInfoComponentState } from "../../views/admin/UserInfoEdit";
import UserInfoViewModel from "../../view-model/admin/UserInfoEditViewModel";
import AuthorizedManagerCheckBox from "./AuthorizedManagerCheckBox";
import AdminCheckBox from "./AdminCheckBox";


type Props = {
    state: RegisterComponentState | UserInfoComponentState,
    viewModel: RegisterViewModel | UserInfoViewModel,
    editableEmail?: boolean,
    isAuthorizedManagerEnabled?: boolean,
    isAdminEnabled?: boolean
}

const PersonalInformation: React.FC<Props> = ({ state, viewModel, editableEmail = true, isAuthorizedManagerEnabled = false, isAdminEnabled = false }) => {

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Personal Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                    onChange={viewModel.onFirstNameChange}
                    value={state.firstName}
                    className={`form-control ${state.validation.firstName ? "is-invalid" : ""}`}
                />
                <label className="form-label">First Name<span className="mandatory-symbol">*</span></label>
                {state.validation.firstName && (
                    <div className="invalid-feedback">
                        {state.validation.firstName}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    id="lastName"
                    onChange={viewModel.onLastNameChange}
                    value={state.lastName}
                    className={`form-control ${state.validation.lastName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Last Name<span className="mandatory-symbol">*</span></label>
                {state.validation.lastName && (
                    <div className="invalid-feedback">
                        {state.validation.lastName}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    id="phone"
                    onChange={viewModel.onPhoneChange}
                    value={state.phone}
                    className={`form-control ${state.validation.phone ? "is-invalid" : ""}`}
                />
                <label className="form-label">Phone<span className="mandatory-symbol">*</span></label>
                {state.validation.phone && (
                    <div className="invalid-feedback">
                        {state.validation.phone}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    disabled={!editableEmail}
                    type="text"
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={(viewModel as RegisterViewModel).onEmailChange}
                    onBlur={(viewModel as RegisterViewModel).onEmailBlur}
                    value={state.email}
                    className={`form-control ${state.validation.email ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email<span className="mandatory-symbol">*</span></label>
                {state.validation.email && (
                    <div className="invalid-feedback">
                        {state.validation.email}
                    </div>
                )}
            </Col>
        </Row>
        <Row>
            {isAuthorizedManagerEnabled &&
                <AuthorizedManagerCheckBox isAuthorizedManager={(state as UserInfoComponentState).isAuthorizedManager} onChange={(viewModel as UserInfoViewModel).onIsAuthorizedManagerChanged} />
            }
            {isAdminEnabled &&
                <AdminCheckBox isAdmin={(state as UserInfoComponentState).isAdmin} onChange={(viewModel as UserInfoViewModel).onIsAdminChanged} />
            }
        </Row>
        
    </>
};

export default PersonalInformation;
