export default class LocalStorageService {

    static setTermsOfServiceVersion = () => localStorage.setItem("TermsOfServiceVersion", "1");

    static setPrivacyPolicyVersion = () => localStorage.setItem("PrivacyPolicyVersion", "1");

    static getTermsOfServiceVersion = (): string => localStorage.getItem("TermsOfServiceVersion") || '';

    static getPrivacyPolicyVersion = (): string => localStorage.getItem("PrivacyPolicyVersion") || '';

    static setUserRole = (role: string) => localStorage.setItem('role', role);

    static removeUserRole = () => localStorage.removeItem('role');

}