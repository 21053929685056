import { Subject } from "rxjs";
import IBaseViewModel from "../IBaseViewModel";
import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import UserAccountModel from "../../../domain/entities/admin/models/UserAccountModel";
import PageResult from "../../../domain/common/PageResult";
import AdminUsersPaginationQuery from "../../../domain/entities/admin/models/AdminUsersPaginationQuery";
import IUserAccountRepo from "../../../domain/repositories/admin/IUserAccountRepo";
import { toast } from "react-toastify";
import UsersPaginationQuery from "../../../domain/entities/usersettings/UsersPaginationQuery";

export default class ListPermittedUsersViewModel implements IBaseViewModel {
    public statusList: AccountStatusModel[];
    public currentPageData: UserAccountModel[]

    public searchByUsername: string;
    public searchByName: string;
    public searchByCompanyName: string;
    public searchByStatus: string;

    public pageIndex: number;
    public pageSize: number;
    public totalCount: number;
    public isShowError: boolean;
    public isLoading: boolean;
    public isShowDeleteUserPermissionModal: boolean;
    public selectedUserId: string;

    public sortDirection: string | null;
    public sortField: string | null;

    private userAccountRepo: IUserAccountRepo;
    private subject?: Subject<any>;
    private topic?: string;
    private paginationModel: UsersPaginationQuery;

    public constructor(userAccountRepo: IUserAccountRepo) {
        this.statusList = [];
        this.currentPageData = [];
        this.searchByUsername = '';
        this.searchByName = '';
        this.searchByCompanyName = '';
        this.searchByStatus = '';

        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalCount = 0;

        this.isShowError = false;
        this.isLoading = false;
        this.isShowDeleteUserPermissionModal = false;
        this.selectedUserId = '';

        this.userAccountRepo = userAccountRepo;
        this.paginationModel = {} as UsersPaginationQuery;
        this.sortDirection = null;
        this.sortField = null;
    }

    public init = async () => {
        this.isLoading = true;
        this.notifyViewAboutChanges();
        const result = await this.userAccountRepo.ListAccountStatus();
        if (result.isSuccess) {
            this.statusList = result.value ?? []
        } else {
            this.statusList = []
        }
        this.notifyViewAboutChanges();
        this.paginationModel.PageNum = this.pageIndex;
        this.paginationModel.PageSize = this.pageSize;
        await this.updateCurrentPageData();
    }

    public showDeleteUserPermissionModal = (selectedUserId: string) => {
        this.selectedUserId = selectedUserId;
        this.isShowDeleteUserPermissionModal = true;
        this.notifyViewAboutChanges();
    }

    public hidDeleteUserPermissionModal = () => {
        this.isShowDeleteUserPermissionModal = false;
        this.notifyViewAboutChanges();
    }

    public onDeleteUserPermission = async () => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userAccountRepo.DeleteUserPermission(this.selectedUserId);

            this.isLoading = false;
            this.isShowDeleteUserPermissionModal = false;
            this.notifyViewAboutChanges();

            if (result.isSuccess) {
                this.currentPageData = this.currentPageData.filter(r => !(r.userId == this.selectedUserId));
                this.notify();
                this.notifyViewAboutChanges();
            }
            else {
                this.isShowError = true;
                this.notifyViewAboutChanges();
            }
        }
        catch {
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    }

    public notify = () => {
        toast.success(
            "Deleted Successfully.",
            {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
                theme: "dark",
                progress: undefined,
                bodyClassName: "toast-message",
            }
        );
    };

    public updateSorting = (sortField: string | null, sortDirection: string | null) => {
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.paginationModel.SortDirection = sortDirection ?? '';
        this.paginationModel.SortField = sortField ?? '';
        this.notifyViewAboutChanges();
    }

    public updatePagination = (pageIndex: number, pageSize: number) => {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.paginationModel.PageNum = pageIndex;
        this.paginationModel.PageSize = pageSize;
        this.notifyViewAboutChanges();
    }

    public updateCurrentPageData = async () => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userAccountRepo.ListPermittedUsersAccounts(this.paginationModel);
            
            if (result.isSuccess) {
                const page = result.value as PageResult<UserAccountModel> ?? new PageResult<UserAccountModel>();
                this.currentPageData = page.pageData;
                this.totalCount = page.totalCount;
            } else {
                this.currentPageData = []
                this.totalCount = 0;
            }
            this.isLoading = false;
            this.notifyViewAboutChanges();
        } catch (e) {
            this.isLoading = false;
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    }

    private notifyViewAboutChanges = (): void => {
        const data = {
            statusList: this.statusList,
            currentPageData: this.currentPageData,
            searchByUsername: this.searchByUsername,
            searchByName: this.searchByName,
            searchByCompanyName: this.searchByCompanyName,
            searchByStatus: this.searchByStatus,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            totalCount: this.totalCount,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            isShowDeleteUserPermissionModal: this.isShowDeleteUserPermissionModal,
            sortDirection: this.sortDirection,
            sortField: this.sortField
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }


}
