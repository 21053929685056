import React from "react";
import { Helmet } from "react-helmet";
import withTranslate from "../../../infrastructure/HOC/withTranslate";
import CreateSearch from "../createSearch/CreateSearch";


const Home: React.FC = () => {

    return (
        <>
            <Helmet>
                <title>Social E-Profiler - Request</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <CreateSearch />
        </>
    );
}
export default withTranslate(Home);
