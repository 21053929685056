import React from 'react';
import { Alert } from "react-bootstrap";
import useTranslation from "../../infrastructure/hooks/useTranslate";

type SuccessAlertProps = {
    headerMessage: string,
    bodyMessage: string
}

function SuccessAlert(props: SuccessAlertProps) {
    const { t } = useTranslation();
    return (
        <Alert variant="success">
            <Alert.Heading>{t(props.headerMessage)}</Alert.Heading>
            <p>
                {t(props.bodyMessage)}
            </p>
        </Alert>
    );
}
export default SuccessAlert;