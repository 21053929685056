import React from "react";
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";

type ExperienceProps = {
  experience: ExperienceModel;
};

const Experience: React.FC<ExperienceProps> = (props: ExperienceProps) => {
  return (
      <div className="education-box-body">
          <div className="address-inner-content">
              <img
                  src="/images/work.svg"
                  className="output-tab-content-icon"
                  alt="img"
              />
              <p className="address-text">{props.experience.companyName} </p>
          </div>
          {(props.experience.startYear ||
              props.experience.endYear ||
              props.experience.location) && (
                  <div className="education-card-body">
                      <div className="education-inner-card-body">
                          {props.experience.startYear && (
                              <div className=" education-inner-text-div-card-body">
                                  <p className="education-inner-title-card-body">From: </p>
                                  <p className="education-inner-text-card-body">
                                      {props.experience.startYear}
                                  </p>
                              </div>
                          )}
                          {props.experience.endYear && (
                              <div className=" education-inner-text-div-card-body">
                                  <p className="education-inner-title-card-body">To: </p>
                                  <p className="education-inner-text-card-body">
                                      {props.experience.endYear}
                                  </p>
                              </div>
                          )}
                          {props.experience.location && (
                              <div className=" education-inner-text-div-card-body">
                                  <p className="education-inner-title-card-body">
                                      Location:{" "}
                                  </p>
                                  <p className="education-inner-text-card-body">
                                      {props.experience.location}
                                  </p>
                              </div>
                          )}
                      </div>
                  </div>
              )}
      </div>
  );
};
export default Experience;
