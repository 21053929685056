import React from 'react';
import NotAllowedAccess from './NotAllowedAccess';
import NotFoundAccess from './NotFoundAccess';
import ServerError from './ServerError';

type GenericServerErrorProps = {
    errorCode: number
}

function GenericServerError(props: GenericServerErrorProps) {
    return (
        <>
            {
                (props.errorCode == 404) && <NotFoundAccess />
            }
            {
                (props.errorCode == 403) && <NotAllowedAccess />
            }
            {
                (props.errorCode >= 500) && <ServerError />
            }
        </>
    );
}
export default GenericServerError;