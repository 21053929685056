import React from "react";
import SocialPlatformProfileModel from "../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import SocialProfile from "./SocialProfile";
import SocialResultSectionTitle from "./SearchResultSectionTitle";
import SocialResultModel from "../../../domain/entities/searchoutput/SocialResultModel";
import SocialProfileNoData from "./SocialProfilesNoData";
import SocialProfiles from "./SocialProfiles";

type SocialResultsProps = {
  socialResults: SocialPlatformProfileModel[];
  socialPlatformId: string | null;
};

const SocialResults: React.FC<SocialResultsProps> = (
  props: SocialResultsProps
) => {

  const selectedSocialResults: SocialPlatformProfileModel[] =
    props.socialResults;
    
  return (
    <div id="__social-media">

      <SocialResultSectionTitle title="Social Media" iconUrl="/images/social-media.svg" />

      {selectedSocialResults && selectedSocialResults.length > 0 && (
        <>
          {selectedSocialResults &&
            selectedSocialResults.map((platform) => {
              return (
                <>
                  {platform.resultsWithData &&
                    platform.resultsWithData.length > 0 &&
                    platform.resultsWithData.map((profile) => {
                      return (
                        <SocialProfile
                          socialPlatform={platform.socialPlatform}
                          socialResult={profile}
                          key={Math.random()}
                        />
                      );
                    })}
                  {platform.resultsWithNoData &&
                    platform.resultsWithNoData.length > 0 && (
                      <SocialProfiles
                        otherSocialResults={platform.resultsWithNoData}
                        socialPlatform={platform.socialPlatform}
                        key={Math.random()}
                      />
                    )}
                </>
              );
            })}
        </>
      )}
      {!selectedSocialResults ||
        (selectedSocialResults.length == 0 && (
          <h2 className="title text-center">No Records found</h2>
        ))}
    </div>
  );
};
export default SocialResults;
