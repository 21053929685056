import React, { Fragment } from 'react';

type SearchResultSectionTitleProps = {
    title: string;
    iconUrl: string;
}

const SearchResultSectionTitle: React.FC<SearchResultSectionTitleProps> = (props: SearchResultSectionTitleProps) => {
    return <Fragment>
        <div className="search-result-section-title">
            <img src={props.iconUrl} />
            <div>{props.title}</div>
        </div>

        <hr className="search-result-section-hr" />
    </Fragment>;
}

export default SearchResultSectionTitle;
