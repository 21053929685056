import React, { useEffect, useState } from "react";
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";
import SocialResultSectionTitle from "./SearchResultSectionTitle";
import Address from "./Address";

type AddressesProps = {
  addresses: AddressModel[];
};
type AddressesState = {
  addresses: string;
};

const Addresses: React.FC<AddressesProps> = (props: AddressesProps) => {
  const [state, setState] = useState<AddressesState>({ addresses: "" });

  useEffect(() => {
    const addresses = props.addresses
      .map((a) => "markers=color:orange|size:small|" + encodeURI(a.addressLine))
      .join("&").replaceAll("#","");
    setState({ ...state, addresses });
  }, []);

  return (
      <div id="__address-history">

          <SocialResultSectionTitle title="Address History" iconUrl="/images/location.svg" />

          {props.addresses && props.addresses.length > 0 && (
              <>
                  {props.addresses.map((address, index) => {
                      return <Address address={address} key={index} />;
                  })}
              </>
          )}
          {!props.addresses ||
              (props.addresses.length == 0 && (
                  <h2 className="title text-center">No Records found</h2>
              ))}
          {props.addresses.length > 0 && (
              <div className="row m-auto mb-3">
                  <img
                      src={`https://maps.googleapis.com/maps/api/staticmap?
          zoom=15&size=600x300&maptype=roadmap&${state.addresses}&key=AIzaSyDQRfEFtUUxMHTbo2oOj6GIN6mh1_syEK8`}
                  />
              </div>
          )}
      </div>
  );
};
export default Addresses;
