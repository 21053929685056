import React from "react";

const PrinterSvgIcon: React.FC = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">            
            <path xmlns="http://www.w3.org/2000/svg" d="M17.36 4H16V2C16.0217 1.49233 15.8413 0.99675 15.4983 0.621863C15.1553 0.246976 14.6776 0.023355 14.17 0H5.83002C5.32242 0.023355 4.84478 0.246976 4.50175 0.621863C4.15873 0.99675 3.97831 1.49233 4.00002 2V4H2.64002C1.93628 4.00529 1.26331 4.28924 0.7685 4.78968C0.273686 5.29011 -0.00264646 5.96625 1.91069e-05 6.67V13.33C-0.00264646 14.0338 0.273686 14.7099 0.7685 15.2103C1.26331 15.7108 1.93628 15.9947 2.64002 16H3.50002C3.50002 16.5304 3.71073 17.0391 4.08581 17.4142C4.46088 17.7893 4.96959 18 5.50002 18H14.5C15.0305 18 15.5392 17.7893 15.9142 17.4142C16.2893 17.0391 16.5 16.5304 16.5 16H17.36C18.0638 15.9947 18.7367 15.7108 19.2315 15.2103C19.7264 14.7099 20.0027 14.0338 20 13.33V6.67C20.0027 5.96625 19.7264 5.29011 19.2315 4.78968C18.7367 4.28924 18.0638 4.00529 17.36 4ZM6.00002 2H14V4H6.00002V2ZM5.50002 16V12H14.5V16H5.50002Z" fill="#F5831F"/>
        </svg>
    );
};

export default PrinterSvgIcon;