import React from 'react';
import { Row } from "react-bootstrap";

const SuccessfulSetPassword = () => {

    return <>
        <div className="registration successful-register main-page">
            <div className="section-wrapper">
                <Row><img src="/images/successful.svg" width="56px" height="56px" /></Row>
                <Row><span className="congrats">Congratulations</span></Row>
                <Row><span className="info">You have set password successfully</span></Row>
                <Row><span className="detailed-info">Now you can login to Social E-Profiler</span></Row>
                <Row className="d-block"><a href="/login" className="btn next-btn">Go to app &nbsp;&nbsp;&nbsp; <span>&rarr;</span></a></Row>
            </div >
        </div>
    </>;
}

export default SuccessfulSetPassword;