import React, { Fragment, useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import RelativesSvgIcon from "../svg-icons/RelativesSvgIcon";
import ResultCount from "./ResultCount";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";


type Props = {
    relatives: RelativeModel[];
}

const Relatives: React.FC<Props> = (props: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    return (
        <Fragment>
            <Row><ResultCount count={props.relatives?.length ?? 0} text={isPrintView ? "Relatives" : null}/></Row>
            <Row className="relatives p-0">
                {props.relatives.map(relative =>
                    <Col md={4} key={Math.random()}>
                        <Col className="relative-card box-shadow">
                            <Row className="m-0 align-items-center">
                                <Col md="auto" className="img-box ml-3"><RelativesSvgIcon /></Col>
                                <Col md="auto" className="relative-name">{relative.name.firstName} {relative.name.middleName} {relative.name.lastName}</Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/age.svg" /></Col>
                                    <Col md="auto" className="relative-text">Age:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.age ? relative.age : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/date of Birth.svg" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Birth:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfBirth ? relative.dateOfBirth : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/date of Death.svg" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Death:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfDeath ? relative.dateOfDeath : <span className="not-available">Not Available</span>}</Col>
                                 </Row>
                            </Row>
                        </Col>
                    </ Col >
                )}
            </Row>
        </Fragment>
    );
};

export default Relatives;
