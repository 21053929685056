import React from "react";
import { Col, Row } from "react-bootstrap";
import PendingRequestModel from "../../../domain/entities/admin/models/PendingRequestModel";

type Props = {
    glbaDppaChangeRequest: PendingRequestModel;
}

const GlbaDppaChangeRequestDetails: React.FC<Props> = (props: Props) => {

    return <div className="account-info-details">
        <Row className="header-section">
            <Col md="auto" className="p-0 section-title">Requestor Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="info-section">
            <Row className="titile-row">
                <Col><span className="title">Email</span></Col>
                <Col><span className="title">Employer Name</span></Col>
            </Row>
            <Row className="data-row">
                <Col><span className="value">{props.glbaDppaChangeRequest.email}</span></Col>
                <Col><span className="value">{props.glbaDppaChangeRequest.employerName}</span></Col>
            </Row>
            <Row className="titile-row">
                <Col><span className="title">Request Date</span></Col>
            </Row>
            <Row className="data-row">
                <Col><span className="value">{new Date(props.glbaDppaChangeRequest.requestDate).toLocaleDateString()}</span></Col>
            </Row>
        </Row>
        <Row className="header-section">
            <Col md="auto" className="p-0 section-title">GLBA/DPPA</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="info-section">
            <Row className="titile-row">
                <Col><span className="title">GLBA</span></Col>
                <Col><span className="title">DPPA</span></Col>
            </Row>
            <Row className="data-row">
                <Col><span className="value">{props.glbaDppaChangeRequest.newlySelectedGlba}</span></Col>
                <Col><span className="value">{props.glbaDppaChangeRequest.newlySelectedDppa}</span></Col>
            </Row>
        </Row>
    </div>;
}

export default GlbaDppaChangeRequestDetails;