import ConfirmEmailhHolder from '../../../domain/entities/auth/structures/ConfirmEmailModel';
import AuthRepository from '../../../domain/repositories/auth/IAuthRepository';
import IBaseViewModel from '../IBaseViewModel';
import { Subject } from 'rxjs';

export default class ConfirmEmailViewModel implements IBaseViewModel {

    //#region props
    public token: string;
    public userId: string;
    //#endregion

    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public isSuccess: boolean;


    private topic?: string;
    private subject?: Subject<any>;
    private authRepository: AuthRepository;

    public constructor(authRepository: AuthRepository) {
        this.token = '';
        this.userId = '';
        this.isLoading = true;
        this.isSuccess = false;

        this.isShowError = false;
        this.errorMessages = [];

        this.authRepository = authRepository;
    }
    onTokenQueryChanged(token: string): void {
        this.token = token;
    }
    onUserIdQueryChanged(userId: string): void {
        this.userId = userId;
    }

    onConfirmEmailChanged(): void {
        this.notifyViewAboutChanges();
    }

    public submitConfirmation = async (): Promise<void> => {

        if (!this.validateConfirmEmailForm()) {
            this.notifyViewAboutChanges();
            return;
        }
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const model = {} as ConfirmEmailhHolder;
            model.UserId = this.userId;
            model.Token = this.token;

            const result = await this.authRepository.confirmEmail(model);

            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            this.errorMessages.push(result.error);
            this.isSuccess = result.isSuccess;
            // if (result.isSuccess)
            //     window.location.href = '/';
        } catch (e: any) {
            this.isLoading = true;
            this.errorMessages.push(e.message);
            this.isShowError = true;
        }

        this.notifyViewAboutChanges();
    };

    private validateConfirmEmailForm = (): boolean => {
        this.isShowError = false;
        this.errorMessages = [];

        return !this.isShowError;
    }


    private notifyViewAboutChanges = (): void => {
        const data = {
            userId: this.userId,
            token: this.token,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages
        }
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject
    }
}
