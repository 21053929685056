import React from "react";
import { Col, Row } from "react-bootstrap";

type Props = {
    canCreateSearchRequests: boolean,
    mustFillClaimOffice: boolean
}

const CreateSearchErrorModal: React.FC<Props> = (props: Props) => {
    return <>
        {(!props.canCreateSearchRequests || props.mustFillClaimOffice) && <>
            <div className="can-not-create-search-request-modal-card"></div>
            <div className="glpa-dppa-change-request-modal-body can-not-create-search-request-modal-body">
                <Row><p className="modal-intro">You Can not Create Search Requests</p></Row>
                {props.mustFillClaimOffice && (<Row><p className="modal-info">You must fill in your Claims Office</p></Row>)}
                {!props.canCreateSearchRequests && (<Row><p className="modal-info">Your GLBA/DPPA choices prevent you from creating search requests,&nbsp;You selected &nbsp; &apos;No use permitted by the GLBA&apos; &nbsp; or &nbsp; &apos;No use permitted by the DPPA&apos; &nbsp; or both,&nbsp; select any other option to be able to create search requests from <a className="link-btn change-glba-dppa-btn" href="/settings/glba-dppa">here &rarr;</a></p></Row>)}
            </div>
        </>}
    </>
        ;
}

export default CreateSearchErrorModal;