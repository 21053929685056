import React from "react";
import EmailBreachModel from "../../../domain/entities/searchoutput/EmailBreachModel"

type EmailBreachesProps = {
    breaches: EmailBreachModel[];
};

const EmailBreaches: React.FC<EmailBreachesProps> = (props: EmailBreachesProps) => {
    return (
        <div className="breaches">
            <div className="title">Email Breaches:</div>
            <table>
                <tr className="table-header">
                    <td> <div>Website</div> </td>
                    <td> <div>Breach Date</div> </td>
                </tr>
                {props.breaches.map(breach => <tr key={Math.random()}><td>{breach.website}</td><td>{breach.breachDate.toString().split('T')[0]}</td></tr>)}
            </table>
        </div>
    );
}

export default EmailBreaches;
