import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BootstrapTable, {
  ColumnDescription
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import AccountRequestsViewModel from "../../view-model/admin/AccountRequestsViewModel";
import PendingAccountRequestModel from "../../../domain/entities/admin/models/PendingAccountRequestModel";
import AccountRequestApi from "../../../data/admin/AccountRequestApi";


type AccountRequestListState = {
    requests: PendingAccountRequestModel[];
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };


const PendingRegistrations: React.FC = () => {

    const { viewModel, subscription } = useViewModel(AccountRequestsViewModel, [
        new AccountRequestApi(new HttpClient()),
      ]);

      const [state, setState] = useState<AccountRequestListState>({
        requests: viewModel.requests,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
      });

      useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
          setState({
            ...state,
            ...d.data,
          });
        });

        viewModel.getData();
    
        return () => {
          subscriber.unsubscribe();
        };
      }, []);


    const columns: ColumnDescription[] = [
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row, i) => (
          <a href={`/admin/pendingRegistration/${row.id}`} className="username-link">{row.email}</a>
      ),
    },
    {
       dataField: "signupDate",
       text: "Registration Date",
       formatter: (cell, row) => new Date(row.signupDate).toLocaleDateString(),
    },
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "employerName",
      text: "Employer Name",
    }
  ];


  return (
    <div className="container">
      <Helmet>
        <title>Social E-Profiler - Pending Registrations</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>
      <div className="row mt-5 pending-registration-container">
        <div className="admin-page-title">Pending Registrations</div>
        <div className="table-style mt-4">
              <BootstrapTable
                keyField="searchId"
                columns={columns}
                data={state.requests}
                bordered={false}
                headerClasses="header-tr"
                remote
                pagination={paginationFactory({
                          pageStartIndex: 1,
                          page: state.pageIndex + 1,
                          sizePerPage: state.pageSize,
                          totalSize: state.totalCount,
                          firstPageText: "First",
                          prePageText: "Back",
                          nextPageText: "Next",
                          lastPageText: "Last",
                          nextPageTitle: "First page",
                          prePageTitle: "Pre page",
                          firstPageTitle: "Next page",
                          lastPageTitle: "Last page",
                          onSizePerPageChange: viewModel.onPageSizeChange,
                          sizePerPageList: [
                            { text: "10", value: 10},
                            { text: "25", value: 25},
                            { text: "50", value: 50},
                            { text: "100", value: 100},
                          ]
                })}
                onTableChange={viewModel.onTableChange}
              />
        </div>          
      </div>
    </div>
  );
};
export default PendingRegistrations;
