import React, { useContext } from "react";
import { Row, Col } from 'react-bootstrap';
import PDFAddresses from "./PDFAddresses";
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";
import PDFWork from "./PDFWork";
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";
import PDFEducation from "./PDFEducation";
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";
import PDFRelatives from "./PDFRelatives";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";
import PDFPhones from "./PDFPhones";
import PhoneModel from "../../../domain/entities/searchoutput/PhoneModel";
import PDFEmails from "./emails/PDFEmails";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import SocialPlatformProfileModel from "../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import PDFSocialResults from "./SocialResults/PDFSocialResults";
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import PDFInternetSearches from "./PDFInternetSearches";
import PDFHeader, { HeaderInfo } from "./header/PDFHeader";

export type Props = {
    headerModel: HeaderInfo;
    addresses: AddressModel[];
    experiences: ExperienceModel[];
    educations: EducationModel[];
    relatives: RelativeModel[];
    phones: PhoneModel[];
    emails: EmailModel[];
    socialResults: SocialPlatformProfileModel[];
    internetResults: InternetResultModel[];
}



const PDFSearchResults: React.FC<Props> = ({headerModel, addresses, experiences,educations,relatives, phones, emails, socialResults, internetResults}: Props) => {

    return (
        <div className="search-result pdf">
            <PDFHeader headerInfo={headerModel}/>
            <PDFAddresses addresses={addresses}/>
            <PDFWork workData={experiences}/>
            <PDFEducation educationData={educations}/>
            <PDFRelatives relatives={relatives}/>
            <PDFPhones phones={phones.map(p => p.phoneNumber)}/>
            <PDFEmails emails={emails}/>
            <PDFInternetSearches searches={internetResults}/>
            <PDFSocialResults platforms={socialResults}/>
        </div>
    );
};

export default PDFSearchResults;
