import React from "react";
import { Col, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";
import { UserInfoComponentState } from "../../views/admin/UserInfoEdit";
import UserInfoViewModel from "../../view-model/admin/UserInfoEditViewModel";


type Props = {
    state: RegisterComponentState | UserInfoComponentState,
    viewModel: RegisterViewModel | UserInfoViewModel
}


const AlternativeContact: React.FC<Props> = ({ state, viewModel }) => {

    
    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Alternative Contact</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="First Name"
                    name="alternativeFirstName"
                    id="alternativeFirstName"
                    onChange={viewModel.onQueryChanged}
                    value={state.alternativeFirstName || ''}
                    className="form-control"
                />
                <label className="form-label">First Name</label>
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Last Name"
                    name="alternativeLastName"
                    id="alternativeLastName"
                    onChange={viewModel.onQueryChanged}
                    value={state.alternativeLastName || ''}
                    className="form-control"
                />
                <label className="form-label">Last Name</label>
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Phone"
                    name="alternativePhone"
                    id="alternativePhone"
                    onChange={viewModel.onAlternativePhoneChange}
                    value={state.alternativePhone || ''}
                    className={`form-control ${state.validation.alternativePhone ? "is-invalid" : ""}`}
                />
                <label className="form-label">Phone</label>
                {state.validation.alternativePhone && (
                    <div className="invalid-feedback">
                        {state.validation.alternativePhone}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Email"
                    name="alternativeEmail"
                    id="alternativeEmail"
                    onChange={viewModel.onAlternativeEmailChange}
                    value={state.alternativeEmail || ''}
                    className={`form-control ${state.validation.alternativeEmail ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email</label>
                {state.validation.alternativeEmail && (
                    <div className="invalid-feedback">
                        {state.validation.alternativeEmail}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default AlternativeContact;
