import SearchModel from "../../../domain/entities/search/SearchModel";
import FormValidator from "../../utils/FormValidator";
import SearchRepository from "../../../domain/repositories/search/ISearchRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import AuthHolder from "../../../domain/entities/auth/models/AuthHolder";
import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import UserClaimOfficeStatusModel from "../../../domain/entities/usersettings/UserClaimOfficeStatusModel";
import ListCaseModel from "../../../domain/entities/cases/ListCaseModel";

export default class SearchViewModel implements IBaseViewModel {
  //#region props
  public caseId: number | null;
  public selectedCase: any;
  public caseName: string;
  public newCaseName: string;
  public existCaseOptionSelected: boolean;
  public firstName: string;
  public lastName: string;
  public dateOfBirth: string;
  public dateOfLoss: string;
  public ageRangeFrom: string;
  public ageRangeTo: string;
  public address: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public employer: string;
  public caseList: ListCaseModel[];
  public email: string[];
  public phone: string[];
  public emailFormValues: string[];
  public phoneFormValues: string[];
  public usernameFormValues: string[];
  public showNewCaseName: boolean;
  public username: string[];
  public stateList: any[];
  public selectedState: any;
  public isClaimOfficeRequired: boolean;
  public mustFillClaimOffice: boolean;
  
  //#endregion

  public validation: any;
  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public isSuccess: boolean;

  private topic?: string;
  private subject?: Subject<any>;
  private searchRepository: SearchRepository;
  private settingsRepository: UserSettingRepository;

  public ExistCase = 'ExistCase';
  public NewCase = 'NewCase';
  public User : AuthHolder;

  public constructor(searchRepository: SearchRepository, settingsRepository: UserSettingRepository) {
    this.caseId = null;
    this.selectedCase = null;
    this.caseName = "";
    this.newCaseName = "";
    this.existCaseOptionSelected = false;
    this.firstName = "";
    this.lastName = "";
    this.address = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    this.employer = "";
    this.dateOfLoss = "";
    this.dateOfBirth = "";
    this.ageRangeFrom = "";
    this.ageRangeTo = "";
    this.caseList = [];
    this.email = [""];
    this.phone = [""];
    this.username = [""];
    this.usernameFormValues = [""];
    this.emailFormValues = [""];
    this.phoneFormValues = [""];
    this.city = "";
    this.state = "";
    this.showNewCaseName = false;
    this.validation = {};
    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.isSuccess = false;
    this.stateList = [];
    this.selectedState = null;
    this.User = {} as AuthHolder;
    this.isClaimOfficeRequired = false;
    this.mustFillClaimOffice = false;

    this.searchRepository = searchRepository;
    this.settingsRepository = settingsRepository;
  }

  //#region prop change handlers

  public onQueryChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
    (this as any)[e.currentTarget.id] = input.currentTarget.value;
    this.notifyViewAboutChanges();
  };
  onCaseIdQueryParamChanged(caseId: any): void {
    this.selectedCase = { value: caseId };
    this.caseId = parseInt(caseId);
    this.existCaseOptionSelected = true;
    this.notifyViewAboutChanges();
  }
  onCaseIdQueryChanged(caseId: any): void {
    this.selectedCase = caseId;
    this.caseId = caseId ? caseId.value : null;
    this.notifyViewAboutChanges();
  }
  onStateIdQueryChanged(state: any): void {
    this.selectedState = state;
    this.state = state?.label;
    this.notifyViewAboutChanges();
  }
  onCaseExistSelectionChange(selection: string): void{
    this.existCaseOptionSelected = selection == this.ExistCase ? true : false;
    this.notifyViewAboutChanges();
  }
  onEmailQueryAdded(): void {
    let error = false;
    this.validation.email = [];
    if (this.emailFormValues) {
      let index = 0;
      this.emailFormValues.forEach((element) => {
        if (!element || !FormValidator.isValidEmail(element)) {
          error = true;
          this.validation.email[index] = "Email not valid";
          this.notifyViewAboutChanges();
        }

        const duplicateIndex = this.validateDuplicates(this.emailFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.email[this.emailFormValues.lastIndexOf(duplicateIndex)] = "Duplicated email";
          error = true;
          this.notifyViewAboutChanges();
        }
        index++;
      });
    }
    if (!error) {
      this.emailFormValues.push("");
      this.notifyViewAboutChanges();
    }
  }
  onPhoneQueryAdded(): void {
    let error = false;
    this.validation.phone = [];
    if (this.phoneFormValues) {
      let index = 0;
      this.phoneFormValues.forEach((element) => {
        if (!element || !FormValidator.isValidPhone(element)) {
          error = true;
          this.validation.phone[index] = "Phone not valid";
          this.notifyViewAboutChanges();
        }
        const duplicateIndex = this.validateDuplicates(this.phoneFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.phone[this.phoneFormValues.lastIndexOf(duplicateIndex)] = "Duplicated phone";
          error = true;
          this.notifyViewAboutChanges();
        }
        index++;
      });
    }
    if (!error) {
      this.phoneFormValues.push("");
      this.notifyViewAboutChanges();
    }
  }
  onEmailQueryChanged(email: string, index: number): void {
    if (!this.validation.email) this.validation.email = [];
    if (!email || !FormValidator.isValidEmail(email)) {
      this.validation.email[index] = "Email not valid";
    } else {
      this.validation.email[index] = "";
    }
    this.emailFormValues[index] = email;
    this.email[index] = email;
    this.validateEmails();
    this.notifyViewAboutChanges();
  }
  onPhoneQueryChanged(phone: string, index: number): void {
    if (!this.validation.phone) this.validation.phone = [];
    if (!phone) {
      this.validation.phone[index] = "Phone not valid";
    }
    if (phone && !FormValidator.isValidPhone(phone)) {
      this.validation.phone[index] = "Phone not valid";
    } else {
      this.validation.phone[index] = "";
    }
    this.phone[index] = phone;
    this.phoneFormValues[index] = phone;
    this.validatePhones();
    this.notifyViewAboutChanges();
  }
  onEmailQueryRemoved(index: number): void {
    this.email.splice(index, 1);
    this.emailFormValues.splice(index, 1);
    this.notifyViewAboutChanges();
  }
  onPhoneQueryRemoved(index: number): void {
    this.phone.splice(index, 1);
    this.phoneFormValues.splice(index, 1);
    this.notifyViewAboutChanges();
  }
  onUsernameQueryAdded(): void {
    let error = false;
    this.validation.username = [];
    if (this.usernameFormValues) {
      let index = 0;
      this.usernameFormValues.forEach((element) => {
        if (!element) {
          error = true;
          this.validation.username[index] = "Username not valid";
          this.notifyViewAboutChanges();
        }
        const duplicateIndex = this.validateDuplicates(this.usernameFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.username[this.usernameFormValues.lastIndexOf(duplicateIndex)] = "Duplicated username"; error = true;
          this.notifyViewAboutChanges();
        }
        index++;
      });
    }
    if (!error) {
      this.usernameFormValues.push("");
      this.notifyViewAboutChanges();
    }
  }
  onUsernameQueryChanged(username: string, index: number): void {
    if (!this.validation.username) this.validation.username = [];
    if (!username) {
      this.validation.username[index] = "Username not valid";
    } else {
      this.validation.username[index] = "";
    }
    this.username[index] = username;
    this.usernameFormValues[index] = username;
    this.validateUsernames();
    this.notifyViewAboutChanges();
  }
  onUsernameQueryRemoved(index: number): void {
    this.username.splice(index, 1);
    this.usernameFormValues.splice(index, 1);
    this.notifyViewAboutChanges();
  }

  private validateDuplicates(list: string[], element: string) {
    const checkDuplicates = list.filter(e => e == element);
    if (checkDuplicates?.length <= 1)
      return -1;
    const duplicateIndex = checkDuplicates[checkDuplicates.length - 1];
    return duplicateIndex;
  }

  //#endregion

  public addNewCase = () => {
    this.showNewCaseName = !this.showNewCaseName;
  };

  public getUserClaimOfficeData = async () => {
    try{
      const result = await this.settingsRepository.getUserClaimOfficeStatus();
      if(result.isSuccess){
        const value = result.value ?? {} as UserClaimOfficeStatusModel;
        this.isClaimOfficeRequired = value.isClaimOfficeRequired;
        this.mustFillClaimOffice = value.mustFillClaimOffice;
        this.notifyViewAboutChanges();
      }
    }
    catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
    }
  }

  public listCases = async (): Promise<void> => {
    try {
      this.isLoading = true;
      this.notifyViewAboutChanges();

      const result = await this.searchRepository.get();
      this.caseList = result.value ?? [];
      if (result.isSuccess && this.caseList && this.caseList.length > 0) {
        if (this.caseId) {
          this.caseName = this.caseList.find(
            (e) => e.caseId == this.caseId
          )?.name ?? "";

          this.selectedCase.label = this.caseName;
        }
      }
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      if (!result.isSuccess) this.errorMessages.push(result.error);
      this.notifyViewAboutChanges();
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
    }
   };

    public listStates() {
        this.stateList = this.searchRepository.getStates();
        this.state = this.selectedState?.label;
        this.notifyViewAboutChanges();
    }

  public onClickSearch = async (enhancedSearchEnabled = false): Promise<void> => {
    if (!this.validateSearchForm()) {
      this.notifyViewAboutChanges();
      return;
    }

    try {
      const model = {} as SearchModel;
      model.EnhancedSearchEnabled = enhancedSearchEnabled;
      model.CaseId = this.caseId && this.existCaseOptionSelected ? this.caseId : null;
      model.NewCaseName = this.newCaseName;
      model.FirstName = this.firstName;
      model.LastName = this.lastName;
      model.DateOfLoss = new Date(this.dateOfLoss);
      model.DateOfBirth = new Date(this.dateOfBirth);
      model.AgeRangeFrom = this.ageRangeFrom
        ? parseInt(this.ageRangeFrom)
        : null;
      model.AgeRangeTo = this.ageRangeTo ? parseInt(this.ageRangeTo) : null;
      if (this.email && this.email[0]) model.Emails = this.email;
      else model.Emails = [];
      if (this.phone && this.phone[0]) model.Phones = this.phone;
      else model.Phones = [];
      if (this.username && this.username[0]) model.Usernames = this.username;
      else model.Usernames = [];
      model.Address = this.address;
      model.City = this.city;
      model.State = this.state;
      model.ZipCode = this.zipCode;
      model.Employer = this.employer;
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.searchRepository.add(model);
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.errorMessages.push(result.error);
      this.isSuccess = result.isSuccess;
      if (result.isSuccess) window.location.href = "/search";
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
    }

    this.notifyViewAboutChanges();
  };

  private validateSearchForm = (): boolean => {
    let isShowError = false;
    this.validation = {};
    if (this.validateCase()) {
      isShowError = true;
    }
    if (this.validateFirstName()) {
      isShowError = true;
    }
    if (this.validateLastName()) {
      isShowError = true;
    }
    if (this.validateAgeRange()) {
      isShowError = true;
    }
    if (this.validateAgeRangeOrDateOfBirth()) {
      isShowError = true;
    }
    if (this.validateDateOfBirth()) {
      isShowError = true;
    }
    if (this.validateEnterOneRequiredFiled()) {
      isShowError = true;
    }
    if (this.validatePhones()) {
      isShowError = true;
    }
    if (this.validateEmails()) {
      isShowError = true;
    }
    if (this.validateDateOfLoss()) {
      isShowError = true;
    }
    if (this.validateUsernames()) {
      isShowError = true;
    }

    return !isShowError;
  };
  
  public validateUsernames() {
    this.validation.username = [];
    let isShowError = false;
    if (this.username && this.username.length > 0 && this.username[0] !== "") {
      let i = 0;
      this.validation.username = [];
      this.username.forEach((element) => {
        if (!element) {
          isShowError = true;
          this.validation.username[i] = "username cannot be empty";
        }
        const duplicateIndex = this.validateDuplicates(this.usernameFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.username[this.usernameFormValues.lastIndexOf(duplicateIndex)] = "Duplicated username";
          isShowError = true;
        }
        i++;
      });
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validatePhones() {
    this.validation.phone = [];
    let isShowError = false;
    if (this.phone) {
      let i = 0;
      this.validation.phone = [];
      this.phone.forEach((element) => {
        if (element && !FormValidator.isValidPhone(element)) {
          isShowError = true;
          this.validation.phone[i] = "Phone not valid";
        }
        const duplicateIndex = this.validateDuplicates(this.phoneFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.phone[this.phoneFormValues.lastIndexOf(duplicateIndex)] = "Duplicated phone";
          isShowError = true;
        }
        i++;
      });
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateEmails() {
    this.validation.email = [];
    let isShowError = false;
    if (this.email) {
      let i = 0;
      this.validation.email = [];
      this.email.forEach((element) => {
        if (element && !FormValidator.isValidEmail(element)) {
          isShowError = true;
          this.validation.email[i] = "Email not valid";
        }
        
        const duplicateIndex = this.validateDuplicates(this.emailFormValues, element);
        if (duplicateIndex != -1) {
          this.validation.email[this.emailFormValues.lastIndexOf(duplicateIndex)] = "Duplicated email";
          isShowError = true;
        }
        i++;
      });
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateEnterOneRequiredFiled() {
    this.validation.notEnoughData = "";
    let isShowError = false;
    if (
      (!this.email ||
        (this.email && !FormValidator.isValidEmail(this.email[0]))) &&
      (!this.phone || this.phone[0] === "") &&
      (!this.username || this.username[0] === "") &&
      (!this.address || !this.zipCode)
    ) {
      isShowError = true;
      this.validation.notEnoughData =
        "you must enter at least email, phone, Username, or street address and zip code.";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateDateOfBirth() {
    this.validation.dateOfBirth = "";
    let isShowError = false;
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 2);
    if (this.dateOfBirth && new Date(this.dateOfBirth) >= currentDate) {
      isShowError = true;
      this.validation.dateOfBirth = "birthdate cannot be future date";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateDateOfLoss() {
    this.validation.dateOfLoss = "";
    let isShowError = false;
    if (this.dateOfLoss && new Date(this.dateOfLoss) >= new Date()) {
      isShowError = true;
      this.validation.dateOfLoss = "date Of Loss cannot be future date";
    }
    if (!this.dateOfLoss && this.isClaimOfficeRequired) {
      isShowError = true;
      this.validation.dateOfLoss = "date Of Loss cannot be empty";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateAgeRangeOrDateOfBirth() {
    this.validation.noAgeOrBirthDate = "";
    let isShowError = false;
    if (!this.dateOfBirth && (!this.ageRangeFrom || !this.ageRangeTo)) {
      isShowError = true;
      this.validation.noAgeOrBirthDate =
        "you must enter date of birth or age range";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateAgeRange() {
    this.validation.ageRangeFromAndTo = "";
    let isShowError = false;
    if (
      this.ageRangeFrom &&
      this.ageRangeTo &&
      parseInt(this.ageRangeFrom) > parseInt(this.ageRangeTo)
    ) {
      isShowError = true;
      this.validation.ageRangeFromAndTo = "age range is not valid.";
    }
    this.notifyViewAboutChanges();
    if (isShowError) return isShowError;
    return this.validateAgeRangeOrDateOfBirth();
  }

  public validateLastName() {
    this.validation.lastName = "";
    let isShowError = false;
    if (!this.lastName) {
      isShowError = true;
      this.validation.lastName = "Last Name cannot be empty";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public validateFirstName() {
    this.validation.firstName = "";
    let isShowError = false;
    if (!this.firstName) {
      isShowError = true;
      this.validation.firstName = "First Name cannot be empty";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }
  
  public validateCase() {
    this.validation.caseId = "";
    let isShowError = false;
    if ((!this.caseId && this.existCaseOptionSelected) || (!this.newCaseName &&  !this.existCaseOptionSelected)) {
      isShowError = true;
      this.validation.caseId =
        "You must select an existing case, or create a new one.";
    }
    this.notifyViewAboutChanges();
    return isShowError;
  }

  public initializeSearchWithRereunSearch(reRunSearch: ReRunSearchState) {
    this.firstName = reRunSearch.firstName;
    this.lastName = reRunSearch.lastName;
    this.dateOfBirth = reRunSearch.dateOfBirth;
    this.ageRangeFrom = reRunSearch.ageRangeFrom;
    this.ageRangeTo = reRunSearch.ageRangeTo;
    this.dateOfLoss = reRunSearch.dateOfLoss;
    this.address = reRunSearch.address;
    this.city = reRunSearch.city;
    this.state = reRunSearch.state;
    this.zipCode = reRunSearch.zipCode;
    this.employer = reRunSearch.employer;
    this.caseId = reRunSearch.caseId;
    this.emailFormValues = reRunSearch.emailFormValues;
    this.phoneFormValues = reRunSearch.phoneFormValues;
    this.usernameFormValues = reRunSearch.usernameFormValues;
    this.email = reRunSearch.emailFormValues;
    this.phone = reRunSearch.phoneFormValues;
    this.username = reRunSearch.usernameFormValues;
    this.selectedState = this.searchRepository.getStates().filter(s => s.name == reRunSearch.state)?.map(s => { return { value: s.id, label: s.name } })?.at(0);
    this.notifyViewAboutChanges();
  }

  private notifyViewAboutChanges = (): void => {
    const data = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      dateOfLoss: this.dateOfLoss,
      dateOfBirth: this.dateOfBirth,
      ageRangeFrom: this.ageRangeFrom,
      ageRangeTo: this.ageRangeTo,
      caseId: this.caseId,
      selectedCase: this.selectedCase,
      newCaseName: this.newCaseName,
      existCaseOptionSelected: this.existCaseOptionSelected,
      address: this.address,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      employer: this.employer,
      phone: this.phone,
      emailFormValues: this.emailFormValues,
      phoneFormValues: this.phoneFormValues,
      usernameFormValues: this.usernameFormValues,
      showNewCaseName: this.showNewCaseName,
      username: this.username,
      validation: this.validation,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      selectedState: this.selectedState,
      isClaimOfficeRequired: this.isClaimOfficeRequired,
      mustFillClaimOffice: this.mustFillClaimOffice
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
  };
}
