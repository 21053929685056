import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import Result from "../../../domain/common/Result";
import UserInfoModel from "../../../domain/entities/admin/models/UserInfoModel";
import IUserAccountRepo from "../../../domain/repositories/admin/IUserAccountRepo";
import ISearchRepository from "../../../domain/repositories/search/ISearchRepository";
import SearchListPaginationQuery from "../../../domain/entities/search/SearchListPaginationQuery";
import SearchListFiltrationModel from "../../../domain/entities/search/SearchListFiltrationModel";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";
import PaginationModel from "../../../domain/entities/generic/PaginationModel";
import SearchListItemModel from "../../../domain/entities/search/SearchListItemModel";

export default class UserSearchListViewModel implements IBaseViewModel {
  //#region props
  public pageSize: number;
  public pageNumber: number;
  public totalCount: number;
  public searchText: string;
  public userId?: string;
  public caseDetailsName: string | null;
  public pageData: SearchListItemModel[];
  public name : string;

  public sortDirection?: string;
  public sort?: string;
  //#endregion

  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public showAddModal: boolean;
  public selectedItemId: number | null;
  public subject?: Subject<any>;
  public topic?: string;
  private userAccountRepo: IUserAccountRepo;
  private searchRepo: ISearchRepository;
  private searchQuery: SearchListFiltrationModel;
  public searchDetails: SearchDetailsModel | null;
  public constructor(userAccountRepo: IUserAccountRepo, searchRepo: ISearchRepository) {
    this.searchText = "";
    this.caseDetailsName = null;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.totalCount = 0;
    this.pageData = [];
    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.showAddModal = false;
    this.selectedItemId = null;
    this.userAccountRepo = userAccountRepo;
    this.searchRepo = searchRepo;
    this.name = "";
    this.searchQuery = new SearchListFiltrationModel();
    this.searchDetails = null;
  }
  public openAddModal = (id: string | null) => {
    this.showAddModal = true;
    this.selectedItemId = id === null ? 0 : +id;
    this.getSearchDetails(this.selectedItemId);
    this.notifyViewAboutChanges();
  };

  public closeAddModal = () => {
    this.showAddModal = false;
    this.notifyViewAboutChanges();
  };

  public init  = async() : Promise<any> =>{
    try{
        const userInfoResult = await this.userAccountRepo.GetUserFullName(this.userId?.toString() ?? "");
      if(userInfoResult.isSuccess)
      {
        this.name = userInfoResult.value ?? "";
        this.notifyViewAboutChanges();
        return userInfoResult
      }
      else{
        this.isLoading = false;
        this.errorMessages.push("error in user data fetching");
        this.isShowError = true;
        this.notifyViewAboutChanges();  
        return userInfoResult
      }
    } catch(e: any){
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
    }
  }

  onSearchChanged = (search : SearchListFiltrationModel) => {
    this.searchQuery = search;
    this.getPage(0);
  }

  public updateSorting = (sortField: string, sortDirection: string) => {
    this.sort = sortField;
    this.sortDirection = sortDirection;
    this.getPage(this.pageNumber);
  }


  public getPage = async (page: number): Promise<Result<PaginationModel<SearchListItemModel>>> => {
    try {
      this.pageNumber = page;
      const model = {} as SearchListPaginationQuery;
      model.PageNum = page;
      model.PageSize = this.pageSize;
      model.SearchText = this.searchText;
      model.UserId = this.userId;
      model.SearchText = this.searchQuery.SearchText;
      model.Status = this.searchQuery.Status;
      model.CreationDateFrom = this.searchQuery.CreationDateFrom;
      model.CreationDateTo = this.searchQuery.CreationDateTo
      model.SortField = this.sort;
      model.SortDirection = this.sortDirection;
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.searchRepo.GetUserSearchRequests(model);
      if (result.isSuccess) {
        this.pageData = result.value?.pageData ?? [];
        this.totalCount = result.value?.totalCount ?? 0;
      }
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.errorMessages.push(result.error);
      this.notifyViewAboutChanges();
      return result;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      return Result.Fail(e.message, 500);
    }
    };

    public getSearchDetails = async (id: number) => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const result = await this.searchRepo.getSearchDetail(id);
            this.isShowError = !result.isSuccess;
            this.isLoading = false;
            this.searchDetails = result.value as SearchDetailsModel;
            this.notifyViewAboutChanges();
        }
        catch (e: any) {
            this.isShowError = true;
            this.errorMessages.push(e.message);
            this.notifyViewAboutChanges();
        }
    }

  private notifyViewAboutChanges = (): void => {
    const data = {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      totalCount: this.totalCount,
      pageData: this.pageData,
      isLoading: this.isLoading,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      showAddModal: this.showAddModal,
      selectedItemId: this.selectedItemId,
      searchText: this.searchText,
      sort: this.sort,
      sortDirection: this.sortDirection,
      name: this.name,
      searchDetails: this.searchDetails
    };
    this.subject?.next({ topic: this.topic, data });
  };

  attachSubject(subject: Subject<any>, topicName: string): void {
    this.subject = subject;
    this.topic = topicName;
  }
}
