import React from "react";
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import InternetSearch from "./InternetSearch";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type InternetSearchProps = {
    internetSearches: InternetResultModel[];
};

const InternetSearches: React.FC<InternetSearchProps> = (props: InternetSearchProps) => {
    return (
        <div id="__internet-search">
            <SocialResultSectionTitle title="Internet Search" iconUrl="/images/internet-search.svg" />

            {props.internetSearches && props.internetSearches.length > 0 && (
                <>
                    {props.internetSearches.map((internetSearch, index) => {
                        return <InternetSearch internetSearch={internetSearch} key={index} />;
                    })}
                </>
            )}
            {!props.internetSearches ||
                (props.internetSearches.length == 0 && (
                    <h2 className="title text-center">No Records found</h2>
                ))}
        </div>
    );
};
export default InternetSearches;
