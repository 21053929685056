export default class FormValidator {
  static isValidEmail(email: string): boolean {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
    return emailRegex.test(email);
  }

  static isValidPhone(phone: string): boolean {
      const phoneRegex = /^[0-9]{10}$/i;
    return phoneRegex.test(phone);
  }

  static isValidRegistrationPhone(phone: string): boolean {
    const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    return phoneRegex.test(phone);
  }

    static isValidPassword(password: string): boolean {
    const passwordRegex =
      /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*[0-9]))(?=(.*[`~!@#$%^&*()_+{}:"<>?[\];',.\\/=-])).{8,}$/;
    return passwordRegex.test(password);
  }
}
