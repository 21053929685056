import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";
import { UserInfoComponentState } from "../../views/admin/UserInfoEdit";
import UserInfoViewModel from "../../view-model/admin/UserInfoEditViewModel";


type Props = {
    state: RegisterComponentState | UserInfoComponentState,
    viewModel: RegisterViewModel | UserInfoViewModel
}


const CompanyInformation: React.FC<Props> = ({ state, viewModel }) => {

    const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"].sort();
   
    return <>
        <Row className="ml-n-25px section"> 
            <Col md="auto" className="section-title">Company Information</Col>
            <Col><hr /></Col>
        </Row>
        
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Manager Name"
                    name="managerName"
                    id="managerName"
                    onChange={viewModel.onQueryChanged}
                    value={state.managerName ?? ''}
                    className={`form-control ${state.validation.managerName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Manager Name</label>
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Employer Name"
                    name="employerName"
                    id="employerName"
                    onChange={viewModel.onEmployerNameChange}
                    value={state.employerName}
                    className={`form-control ${state.validation.employerName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Employer Name<span className="mandatory-symbol">*</span></label>
                {state.validation.employerName && (
                    <div className="invalid-feedback">
                        {state.validation.employerName}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Employer Address"
                    name="employerAddress"
                    id="employerAddress"
                    onChange={viewModel.onEmployerAddressChange}
                    value={state.employerAddress}
                    className={`form-control ${state.validation.employerAddress ? "is-invalid" : ""}`}
                />
                <label className="form-label">Employer Address<span className="mandatory-symbol">*</span></label>
                {state.validation.employerAddress && (
                    <div className="invalid-feedback">
                        {state.validation.employerAddress}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Employer City"
                    name="city"
                    id="city"
                    onChange={viewModel.onEmployerCityChange}
                    value={state.city}
                    className={`form-control ${state.validation.city ? "is-invalid" : ""}`}
                />
                <label className="form-label">Employer City<span className="mandatory-symbol">*</span></label>
                {state.validation.city && (
                    <div className="invalid-feedback">
                        {state.validation.city}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <select
                    name="state"
                    id="state"
                    onChange={viewModel.onEmployerStateChange}
                    value={state.state}
                    className={`form-control ${state.validation.state ? "is-invalid" : ""}`}>
                    <option value=''></option>
                    {states.map(s => <option key={Math.random()} value={s}>{s}</option>)}
                </select>
                <label className="form-label">Employer State<span className="mandatory-symbol">*</span></label>
                {state.validation.state && (
                    <div className="invalid-feedback">
                        {state.validation.state}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Employer Address Zip Code"
                    name="zipCode"
                    id="zipCode"
                    onChange={viewModel.onEmployerZipCodeChange}
                    value={state.zipCode}
                    className={`form-control ${state.validation.zipCode ? "is-invalid" : ""}`}
                />
                <label className="form-label">Employer Address Zip Code<span className="mandatory-symbol">*</span></label>
                {state.validation.zipCode && (
                    <div className="invalid-feedback">
                        {state.validation.zipCode}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col md="6" className="input-group-meta form-group mb-30 form-floating first-col-input ">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Claim Office"
                    name="claimOffice"
                    id="claimOffice"
                    maxLength={100}
                    onChange={viewModel.onClaimOfficeChanged}
                    value={state.claimOffice}
                    className={`form-control ${state.validation.claimOffice ? "is-invalid" : ""}`}
                />
                <label className="form-label">Claim Office{ state.isClaimOfficeRequired && <span className="mandatory-symbol">*</span>}</label>
                {state.validation.claimOffice && (
                    <div className="invalid-feedback">
                        {state.validation.claimOffice}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default CompanyInformation;
