import React, { useEffect, useState } from "react";
import DPPAOption from "../../../../domain/entities/usersettings/DPPAOption";
import GLBAOption from "../../../../domain/entities/usersettings/GLBAOption";
import RegistrationResponse from "./RegistrationResponse";
import GLBADPPAChange from "./GLBADPPAChange";
import UserInfoChange from "./UserInfoChange";
import UserEventModel from "../../../../domain/entities/admin/models/UserEventModel";
import CompanyNameModel from "../../../../domain/entities/admin/models/CompanyNameModel";
import Question from "../../../../domain/entities/usersettings/Question";

type Props = {
  event?: UserEventModel;
  GLBAOptions: GLBAOption[];
  DPPAOptions: DPPAOption[];
  companies: CompanyNameModel[];
  questions: Question[];
}

const AccountHistoryDetails: React.FC<Props> = ({ event ,GLBAOptions, DPPAOptions, companies, questions}: Props) => {
    const detailsObj = JSON.parse(event?.details??"");
    let result = <></>
    if (event?.typeId == 2) { result = <RegistrationResponse details={detailsObj}/> }
    if (event?.typeId == 4) { result = <>{detailsObj["Reason"]?<><b>Reason : </b>{detailsObj["Reason"]}</>:<></>}</> }
    if (event?.typeId == 6 || event?.typeId == 7) { result = <GLBADPPAChange details={detailsObj} GLBAOptions={GLBAOptions} DPPAOptions={DPPAOptions}/>}
    if (event?.typeId == 5) { result = <UserInfoChange details={detailsObj} GLBAOptions={GLBAOptions} DPPAOptions={DPPAOptions} companies={companies} questions={questions}/>}
  return (
    <div>
        {result}
    </div>
  );
};

export default AccountHistoryDetails;
