export default class LoginAgreement {

    static onBeforeLogin = () => {
        localStorage.setItem("JustLoggedIn", "true");
        window.location.href = '/bff/login';

    };

    static getAgreeStatus = () => {
        return localStorage.getItem("JustLoggedIn");
    };

    static onAgree = () => {
        localStorage.setItem("JustLoggedIn", "false");
    };
}