import { Subject } from "rxjs";
import IBaseViewModel from "../IBaseViewModel";
import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import UserAccountModel from "../../../domain/entities/admin/models/UserAccountModel";
import PageResult from "../../../domain/common/PageResult";
import AdminUsersPaginationQuery from "../../../domain/entities/admin/models/AdminUsersPaginationQuery";
import IUserAccountRepo from "../../../domain/repositories/admin/IUserAccountRepo";

export default class AllUsersViewModel implements IBaseViewModel {
    public statusList: AccountStatusModel[];
    public currentPageData: UserAccountModel[]

    public searchByUsername: string;
    public searchByName: string;
    public searchByCompanyName: string;
    public searchByStatus: string;    

    public pageIndex: number;
    public pageSize: number;
    public totalCount: number;
    public isShowError: boolean;
    public isLoading: boolean;
    public selectedUserId: string;

    public sortDirection: string | null;
    public sortField: string | null;

    private userAccountRepo: IUserAccountRepo;
    private subject?: Subject<any>;
    private topic?: string;
    private paginationModel : AdminUsersPaginationQuery;

    public constructor(userAccountRepo: IUserAccountRepo) {
        this.statusList = [];
        this.currentPageData = [];
        this.searchByUsername = '';
        this.searchByName = '';
        this.searchByCompanyName = '';
        this.searchByStatus = '';
        
        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalCount = 0;

        this.isShowError = false;
        this.isLoading = false;
        this.selectedUserId = '';

        this.userAccountRepo = userAccountRepo;
        this.paginationModel = {} as AdminUsersPaginationQuery;
        this.sortDirection = null;
        this.sortField = null;
      }

    public init = async () => {
        this.isLoading = true;
        this.notifyViewAboutChanges();
        const result = await this.userAccountRepo.ListAccountStatus();
        if(result.isSuccess){
            this.statusList = result.value ?? []
        } else {
            this.statusList = []
        }
        this.notifyViewAboutChanges();
        this.paginationModel.PageNum = this.pageIndex;
        this.paginationModel.PageSize = this.pageSize;
        await this.updateCurrentPageData();
    }

    public onQueryChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

      public onStatusQueryChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.searchByStatus = input.currentTarget.value;
        this.notifyViewAboutChanges();
      };

    public updateSearchData = async () => {
      this.paginationModel.Email = this.searchByUsername;
      this.paginationModel.Name = this.searchByName;
      this.paginationModel.EmployerName = this.searchByCompanyName;
      this.paginationModel.Status = this.searchByStatus;
      this.paginationModel.PageNum = 0;
      this.pageIndex = 0;
      this.notifyViewAboutChanges();
      await this.updateCurrentPageData();
    }

    public updateSorting = (sortField: string | null, sortDirection: string | null) => {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.paginationModel.SortDirection = sortDirection ?? '';
      this.paginationModel.SortField = sortField ?? '';
      this.notifyViewAboutChanges();
    }

    public updatePagination = (pageIndex: number, pageSize: number) => {
      this.pageIndex = pageIndex;
      this.pageSize = pageSize;
      this.paginationModel.PageNum = pageIndex;
      this.paginationModel.PageSize = pageSize;
      this.notifyViewAboutChanges();
    }

    public updateCurrentPageData = async (getManagerData = false) => {
      try{
          this.isLoading = true;
          this.notifyViewAboutChanges();

          const result = await this.userAccountRepo.ListUsersAccounts(this.paginationModel);

        if(result.isSuccess){
            const page = result.value as PageResult<UserAccountModel> ?? new PageResult<UserAccountModel>();
            this.currentPageData = page.pageData;
            this.totalCount = page.totalCount;
        }else{
            this.currentPageData = []
            this.totalCount = 0;
        }
        this.isLoading = false;
        this.notifyViewAboutChanges();
      } catch (e){
        this.isLoading = false;
        this.isShowError = true;
        this.notifyViewAboutChanges();
      }
    }

      private notifyViewAboutChanges = (): void => {
        const data = {
            statusList: this.statusList,
            currentPageData: this.currentPageData,
            searchByUsername: this.searchByUsername,
            searchByName: this.searchByName,
            searchByCompanyName: this.searchByCompanyName,
            searchByStatus: this.searchByStatus,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            totalCount: this.totalCount,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            sortDirection: this.sortDirection,
            sortField: this.sortField
        };
        this.subject?.next({ topic: this.topic, data });
      };


      attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
        }


}
