import React from "react";

const TimeSvgIcon: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7562 11.6187L11.6 10.775L8.61875 7.775V4.00625H7.49375V8.225L10.7562 11.6187ZM8 15.5C6.975 15.5 6.00625 15.3031 5.09375 14.9094C4.18125 14.5156 3.38437 13.9781 2.70312 13.2969C2.02187 12.6156 1.48437 11.8187 1.09062 10.9062C0.696875 9.99375 0.5 9.025 0.5 8C0.5 6.975 0.696875 6.00625 1.09062 5.09375C1.48437 4.18125 2.02187 3.38437 2.70312 2.70312C3.38437 2.02187 4.18125 1.48437 5.09375 1.09062C6.00625 0.696875 6.975 0.5 8 0.5C9.025 0.5 9.99375 0.696875 10.9062 1.09062C11.8187 1.48437 12.6156 2.02187 13.2969 2.70312C13.9781 3.38437 14.5156 4.18125 14.9094 5.09375C15.3031 6.00625 15.5 6.975 15.5 8C15.5 9.025 15.3031 9.99375 14.9094 10.9062C14.5156 11.8187 13.9781 12.6156 13.2969 13.2969C12.6156 13.9781 11.8187 14.5156 10.9062 14.9094C9.99375 15.3031 9.025 15.5 8 15.5ZM8 14.375C9.75 14.375 11.25 13.75 12.5 12.5C13.75 11.25 14.375 9.75 14.375 8C14.375 6.25 13.75 4.75 12.5 3.5C11.25 2.25 9.75 1.625 8 1.625C6.25 1.625 4.75 2.25 3.5 3.5C2.25 4.75 1.625 6.25 1.625 8C1.625 9.75 2.25 11.25 3.5 12.5C4.75 13.75 6.25 14.375 8 14.375Z" fill="#F5831F"/>
        </svg>  
    );
};

export default TimeSvgIcon;
