import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import AccountRequestApi from "../../../data/admin/AccountRequestApi";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import TermsAndConditionsAndPrivacyPolicyViewModel from "../../view-model/appSettings/TermsAndConditionsAndPrivacyPolicyViewModel";
import AppSettingsApi from "../../../data/appSettings/AppSettingsApi";
import { Helmet } from "react-helmet";

type VersionState = {
    lastVersionDate: any;
    isShowNewVersionModal: boolean;
    isLoading: boolean;
    isShowError: boolean;
};

const TermsAndConditionsAndPrivacyPolicy: React.FC = () => {

    const { viewModel, subscription } = useViewModel(TermsAndConditionsAndPrivacyPolicyViewModel, [new AppSettingsApi(new HttpClient())]);

    const [state, setState] = useState<VersionState>({
        lastVersionDate: viewModel.lastVersionDate,
        isShowNewVersionModal: viewModel.isShowNewVersionModal,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });

        viewModel.getData();

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - App Settings</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                className="avoid-nav-bar"
                bodyClassName="toast-message"
            />
            <Row className="title p-2">Terms and Conditions and Privacy Policy Version</Row>
            <Row>
                <Col className="version-last-date">Last Version Date: {new Date(state.lastVersionDate).toLocaleDateString()}</Col>
            </Row>
            <Row>
                <Col></Col>
                <Col md="auto"><button className="btn next-btn" onClick={viewModel.showNewVersionModal}>New Version</button></Col>
            </Row>
            <Modal show={state.isShowNewVersionModal}>
                <Modal.Body className="new-version-modal">
                    <div className="">
                        <Row className="version-modal-header">
                            <Col><span className="cancel-modal-title">New Version</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideNewVersionModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="">Are you sure there is terms and conditions and privacy policy new version?</p>
                        </Row>
                        <Row>
                            <p className="">By clicking&nbsp;&apos;Yes&apos;&nbsp;all users have to accept the new version to be able to use the website after their next login.</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col></Col>
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideNewVersionModal} className="btn previous-btn">Cancel</a></Col>
                            <Col md="auto" className="p-0"><a onClick={viewModel.addNewVersion} className="btn next-btn">Yes</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default TermsAndConditionsAndPrivacyPolicy;