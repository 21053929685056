import React, { useEffect, useState } from "react";
import BaseView from "../BaseView";
import ResetPasswordViewModel from "../../view-model/auth/ResetPasswordViewModel";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import SuccessAlert from "../../components/SuccessAlert";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import AuthApi from "../../../data/auth/AuthApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useParams } from "react-router";
import FloatingLabelControl from "../../components/FloatingLabelControl";
import { toast, ToastContainer } from "react-toastify";
import PasswordChecklist from "react-password-checklist";

type ResetPasswordComponentState = {
  password: string;
  confirmPassword: string;

  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  isSuccess: boolean;
  startPasswordType: boolean;
  errorMessages: string[];
};

const ResetPassword: React.FC = () => {
  const { viewModel, subscription } = useViewModel(ResetPasswordViewModel, [
    new AuthApi(new HttpClient()),
  ]);
  const [state, setState] = useState<ResetPasswordComponentState>({
    confirmPassword: "",
    validation: {},
    isSuccess: false,
    password: "",
    isLoading: false,
    isShowError: false,
    errorMessages: [],
    startPasswordType: false,
  });
  const { token, userId } = useParams();

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );

    viewModel.onTokenQueryChanged(token ? encodeURIComponent(token) : "");
    viewModel.onUserIdQueryChanged(userId ?? "");
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  const notify = () => {
    toast.success(
      "Password changed successfully, you can login with the new password now.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
        theme: "dark",
        progress: undefined,
        bodyClassName: "toast-message",
      }
    );
  };
  if (viewModel.isSuccess) {
    notify();
    viewModel.isSuccess = false;
    setTimeout(() => {
      window.location.href = "/bff/login";
    }, 3000);
  }

  return (
    <div className="container">
      <Helmet>
        <title>Social E-Profiler - Reset Password</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        limit={1}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        bodyClassName="toast-message"
      />
      <div className="form-style-one">
        <div className="row gx-xxl-5 mx-1">
          <div className="col-lg-3"></div>
          <div className="form-div col-lg-6 p-5">
            <div className="row d-flex justify-content-center">
              <img
                className="form-logo mt-3"
                src="/images/INTERTEL-logo-no-tag.png"
                alt=""
              />
            </div>
            <h2 className="reset-password-title">Create New Password</h2>
            <span className="reset-password-tip">
              Your shouldn’t share your password with anyone
            </span>
            <form
              id="contact-form"
              className="main-form"
              onSubmit={(e: any) => e.preventDefault()}
            >
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="input-group-meta form-group mb-30 form-floating">
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      id="password"
                      onChange={viewModel.onQueryChanged}
                      value={state.password}
                      className={`form-control ${state.validation.password ? "is-invalid" : ""
                        }`}
                    />
                    <label className="form-label">Password</label>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="input-group-meta form-group mb-30 form-floating">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={viewModel.onQueryChanged}
                      value={state.confirmPassword}
                      className={`form-control ${state.validation.confirmPassword ? "is-invalid" : ""
                        }`}
                    />
                    <label className="form-label"> Confirm Password</label>
                  </div>
                </div>

                <TailSpin
                  wrapperClass="cover-spin"
                  visible={state.isLoading}
                  height={50}
                  width="50"
                  color="#FF5733"
                  ariaLabel="loading"
                />
                {state.startPasswordType && (
                  <div className="col-12 password-checklist md-5">
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "lowercase",
                        "capital",
                        "specialChar",
                        "number",
                        "match",
                      ]}
                      value={state.password}
                      valueAgain={state.confirmPassword}
                      minLength={8}
                    />
                  </div>
                )}

                <br />

                {state.isShowError &&
                  state.errorMessages.map((error, i) => {
                    return (
                      <div
                        key={i}
                        className="row my-3 text-danger justify-content-center"
                      >
                        {error}
                      </div>
                    );
                  })}

                <div className="btn-row mt-5">
                  <button
                    name="register"
                    className="submit-btn btn btn-primary"
                    onClick={(): Promise<void> => viewModel.onClick()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
