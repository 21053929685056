import Result from "../../domain/common/Result";
import IAuthorizedManagerRequestRepository from "../../domain/repositories/authorizedManager/IAuthorizedManagerRequestRepository";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import HelperMethods from "../../presentation/utils/HelperMethods";

export default class AuthorizedManagerRequestApi implements IAuthorizedManagerRequestRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    addRequest = (): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/AuthorizedManagerRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }

    denyRequest = (id: number): Promise<Result<string>> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/AuthorizedManagerRequest/${id}/Deny`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();

            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }

    approveRequest = (id: number): Promise<Result<string>> => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/AuthorizedManagerRequest/${id}/Approve`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();

            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }

    isPendingRequestExist = (userId: string): Promise<Result<string | boolean>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/AuthorizedManagerRequest` + HelperMethods.toQueryString({ UserId: userId, status: "Pending"}), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<boolean>(await res.json());

            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }
}