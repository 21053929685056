import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import { TableChangeState } from "react-bootstrap-table-next";
import PaginationQuery from "../../../domain/common/PaginationQuery";
import PendingAccountRequestModel from "../../../domain/entities/admin/models/PendingAccountRequestModel";
import IAccountRequestRepo from "../../../domain/repositories/admin/IAccountRequestRepo";
import PageResult from "../../../domain/common/PageResult";

export default class AccountRequestsViewModel implements IBaseViewModel {
  //#region props
  public requests: PendingAccountRequestModel[];
  public pageIndex: number;
  public pageSize: number;
  public totalCount: number;
  //#endregion
  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public subject?: Subject<any>;
  public topic?: string;
  private pendingAccountRequestRepo: IAccountRequestRepo;

    public constructor(pendingAccountRequestRepo: IAccountRequestRepo) {
    this.requests = [];
    this.pageIndex = 0;
    this.pageSize = 10;
    this.totalCount = 0;
    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.pendingAccountRequestRepo = pendingAccountRequestRepo;
  }

  public onDataChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
    (this as any)[e.currentTarget.id] = input.currentTarget.value;
    this.notifyViewAboutChanges();
  };
    
  public getData = async () => {
    try {
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const model = {} as PaginationQuery;
      model.PageNum = this.pageIndex;
      model.PageSize = this.pageSize;
      const result = await this.pendingAccountRequestRepo.getAccountRequests(model);
      if (result.isSuccess) {
          const res = result.value as PageResult<PendingAccountRequestModel[]>
          this.requests = res?.pageData as [] ?? [];
          this.totalCount = res?.totalCount ?? 0;
      }
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.errorMessages.push(result.error);
      this.notifyViewAboutChanges();
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
    }
  };

  private notifyViewAboutChanges = (): void => {
    const data = {
      requests: this.requests,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      totalCount: this.totalCount,
      isLoading: this.isLoading,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages
    };
    this.subject?.next({ topic: this.topic, data });
  };

  attachSubject(subject: Subject<any>, topicName: string): void {
    this.subject = subject;
    this.topic = topicName;
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    private updateCurrentPageData = (pageNumber: number, pageSize: number) => {

        const pageIndex = pageNumber - 1;
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.notifyViewAboutChanges();
        this.getData();

    }
}
