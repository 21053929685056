import React, { Fragment, useContext, useState } from "react";
import { Col, Modal, Row } from 'react-bootstrap';
import ResultCount from "../ResultCount";
import EmailBreaches from "./EmailBreaches";
import HomeViewModelContext from "../../../../infrastructure/Contexts/HomeViewModelContext";
import EmailModel from "../../../../domain/entities/searchoutput/EmailModel";
import MasonryView from "../../MasonryView";



type Props = {
    emails: EmailModel[];
    removeEmail: (id: number) => void;
}

const Emails: React.FC<Props> = (props: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;  

    const [isShowRemoveModal, showRemoveModal] = useState(false);
    const [selectedEmailId, setSelectedEmailId] = useState(0);

    const remove = () => {
        showRemoveModal(false);
        props.removeEmail(selectedEmailId);
    }

    const showEmailRemovalModal = (id: number) => {
        showRemoveModal(true);
        setSelectedEmailId(id);
    }

    return (
        <Fragment>
            <Row><ResultCount count={props.emails?.length ?? 0} text={isPrintView ? "Emails" : null}/></Row>
            <div className="emails container-fluid p-0">
                <MasonryView columnCount={2}>
                    {props.emails.map(emailData => {

                        const nameExist = emailData.gHuntOutput?.name.firstName || emailData.gHuntOutput?.name.lastName;
                        const name = nameExist ? `${emailData.gHuntOutput?.name.firstName ?? ''} ${emailData.gHuntOutput?.name.lastName ?? ''}` : 'Not Available';
                        
                        return (
                        <div key={Math.random()}>
                            <Col className="email-card box-shadow bg-white">
                                <Row className="m-0 p-0 align-items-center">
                                    <Col className="p-0">
                                        <img className="image p-0" src={emailData.gHuntOutput?.profilePictureUrl ?? "/images/person.png"} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "/images/email/person.png";
                                        }} />
                                        <span className="header-text">{emailData.email}</span></Col>
                                        <Col xs={"auto"} className="header-text gaia p-0"><span className="title id-title">GAIA ID:&nbsp;</span>
                                            {emailData.gHuntOutput?.gaiaId ? <span className="gaia-val">{emailData.gHuntOutput?.gaiaId}</span> : <span className="not-available">Not Available</span>}
                                            <i title="Remove" className="bi bi-trash" onClick={() => showEmailRemovalModal(emailData.id)}></i>
                                        </Col>
                                </Row>
                                <hr />
                                    <Row className="data-row container-fluid p-0 mb-0" >
                                        <Col md="auto" className="name"><img src="/images/new-person.svg" /><span className="title">Name:&nbsp;</span><span className={nameExist ? 'val' : 'not-available'}>{name}</span></Col>
                                        <Col className="google-map p-0"><img src="/images/google-maps-old.svg" className="map-icon m-1" /><span className="title map-title m-0">Map:&nbsp;</span>
                                            {emailData.gHuntOutput?.gMapReviewsUrl && (<a target="_blank" rel="noreferrer" className="view" href={ "https://" + emailData.gHuntOutput.gMapReviewsUrl}>View</a>)}
                                            {!emailData.gHuntOutput?.gMapReviewsUrl && <span className="not-available">Not Available</span>}
                                        </Col>
                                        <Col md="auto" className="p-0"><img src="/images/google.svg" className="m-0" /><span className="title">Google Search:&nbsp;</span><a className="view" target="_blank" rel="noreferrer" href={'https://www.google.com/search?q="' + emailData.email?.split('@')[0] + '"'}>View</a></Col>
                                </Row>
                                <Row className="data-row d-table">
                                    <Col md="auto" className="icon d-table-cell"><img src="/images/connected.svg" /></Col>
                                        <Col md="auto" className="title p-0 d-table-cell">Connected Sites:&nbsp;</Col>
                                        <Col md="auto" className={emailData.connectedSites ? "val p-0 connected-sites d-table-cell" : "not-available d-table-cell p-0"}>{emailData.connectedSites ? emailData.connectedSites : "Not Available"}</Col>
                                </Row>
                                <Row className="data-row m-0" >
                                    <EmailBreaches breaches={emailData.emailBreaches} />
                                </Row>
                            </Col>
                        </ div >
                    )}
                    )}
                </MasonryView>
            </div>
            <Modal show={isShowRemoveModal}>
                <Modal.Body className="modal-card remove-email-modal-card">
                    <div className="approve-account-modal">
                        <Row className="email-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Warning</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => showRemoveModal(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Clicking &nbsp; &apos; Remove &apos; &nbsp; will permanently delete all data related to this email. This action cannot be undone. Do you wish to proceed?</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={() => showRemoveModal(false)} className="btn previous-btn">No</a></Col>
                            <Col className="p-0"><a onClick={remove} className="btn next-btn">Yes</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default Emails;
