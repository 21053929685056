import React from "react";
import SocialPlatformModel from "../../../domain/entities/searchoutput/SocialPlatformModel";
import SocialResultModel from "../../../domain/entities/searchoutput/SocialResultModel";

type SocialProfileProps = {
    socialResult: SocialResultModel;
    socialPlatform: SocialPlatformModel;
};

const SocialProfile: React.FC<SocialProfileProps> = (
    props: SocialProfileProps
) => {
    let properties: { key: string; value: any }[] = [];
    let imageUrl = "";

    const formatResultProperties = (): void => {
        const parsedObject = JSON.parse(props.socialResult.resultData);
        properties = [];
        for (const key in parsedObject) {
            if (key.toLowerCase() === 'image' && parsedObject[key])
                imageUrl = parsedObject[key];
            else {
                if (Object.prototype.hasOwnProperty.call(parsedObject, key)) {
                    const value = parsedObject[key];
                    const enhancedKey = formatKeyText(key);
                    if (value && value.length > 0) {
                        properties.push({
                            key: enhancedKey,
                            value: value,
                        });
                    }
                }
            }
        }
    };

    function formatKeyText(key: string) {
        const replaceUnderscore = key.split("_");
        for (let i = 0; i < replaceUnderscore.length; i++) {
            replaceUnderscore[i] =
                replaceUnderscore[i][0].toUpperCase() + replaceUnderscore[i].substr(1);
        }

        const enhancedKey = replaceUnderscore.join(" ");
        return enhancedKey;
    }

    formatResultProperties();

    return (
        <div className="social-card-div">
            <div className="social-output-icon-div">

                <a href={"https://" + props.socialResult.url} className="address-text" target={"_blank"} rel="noreferrer">
                    {props.socialPlatform && props.socialPlatform.iconUrl && (
                        <img
                            className="social-output-icon rounded-circle"
                            src={props.socialPlatform?.iconUrl}
                            alt="img"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/icons/generic-social-icon.png";
                            }}
                        />
                    )}
                    {(!props.socialPlatform || !props.socialPlatform.iconUrl) && (
                        <img
                            className="social-output-icon rounded-circle"
                            src="/icons/default-social-icon.svg"
                            alt="img"
                        />
                    )}
                </a>
                {props.socialPlatform &&
                    <span> {props?.socialPlatform?.name}</span>
                }
                {!props.socialPlatform &&
                    <span> {props.socialResult.url.split('.com')[0]}</span>
                }
            </div>
            <div className="social-card-body">
                <div className="social-card-image">
                    {imageUrl &&
                        <img
                            className="social-img"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/images/person.png";
                            }}
                            src={imageUrl}
                            alt="img"
                        />
                    }
                    {props?.socialResult?.rulesBasedValidation &&
                        <small className="identifiers"> Identifiers: {props?.socialResult.rulesBasedValidation}</small>
                    }
                </div>
                {properties && properties.length > 0 && (
                    <div className="education-card-body">
                        <div className="education-inner-card-body">
                            <div
                                key={Math.random()}
                                className=" education-inner-text-div-card-body">
                                <p className="education-inner-title-card-body">
                                    {"Url"}:{" "}
                                </p>
                                <div className="col">
                                    <a
                                        href={"https://" + props.socialResult.url}
                                        className="education-inner-text-card-body"
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        {props.socialResult.url}
                                    </a>
                                </div>
                            </div>
                            {properties.map((property) => {
                                return (
                                    <div
                                        className=" education-inner-text-div-card-body"
                                        key={Math.random()}
                                    >
                                        <p className="education-inner-title-card-body">
                                            {property.key}:{" "}
                                        </p>
                                        {property.value.startsWith("http") && (
                                            <div className="col">
                                                <a
                                                    href={property.value}
                                                    className="education-inner-text-card-body"
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                >
                                                    {property.value}
                                                </a>
                                            </div>
                                        )}
                                        {!property.value.startsWith("http") && (
                                            <p className="education-inner-text-card-body">
                                                {property.value}
                                            </p>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default SocialProfile;
