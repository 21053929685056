import React, { useContext } from "react";
import { Row, Col } from 'react-bootstrap';
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";
import AddressSvgIcon from "../svg-icons/AddressSvgIcon";
import EducationSvgIcon from "../svg-icons/EducationSvgIcon";
import TimeSvgIcon from "../svg-icons/TimeSvgIcon";
import ResultCount from "./ResultCount";

export type Props = {
    educationData: EducationModel[];
}


const Education: React.FC<Props> = ({educationData}: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    return (
        <>
        <Row className="d-flex">
            <ResultCount count={educationData.length ?? 0} text={isPrintView ? "Education" : null}/>
        </Row>
        <Row className="p-0 education">
            {educationData.map(e => 
            <Col className="col-4" key={Math.random()}>
                <Row className="education-card box-shadow bg-white m-0">
                    <Row className="education-title">
                        <Col className="col-auto img-box mt-2">
                            <EducationSvgIcon />
                        </Col>
                        {e.schoolName}
                    </Row>
                    <hr/>
                    <Row className="education-data">
                        <Col className="col-auto img-box-clear mt-2">
                            <AddressSvgIcon />
                        </Col>
                        <Col xs="auto" className="education-data-header p-0">
                            Location: 
                            </Col>
                            <Col xs={8} className="px-1">
                            {e.location ?? <span className="not-available">Not Available</span>}
                        </Col>
                        
                    </Row>
                    <Row className="education-data">
                        <Col className="col-auto img-box-clear mt-2">
                            <TimeSvgIcon />
                        </Col>
                        <Col xs="auto" className="education-data-header p-0">From:</Col>
                        <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                        <Col xs="auto" className="education-data-header p-0">To:</Col>
                        <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                    </Row>

                </Row>
            </Col>)} 
        </Row>
        </>
    );
};

export default Education;
