enum SearchStatus {
    Inprogress = 1,
    Completed = 2,
    Failed = 3,
    NeedMoreInfo = 4,
    FinishedWithErrors = 5,
    Validated = 6,
    Created = 7
}

export default SearchStatus;