import type AuthRepository from '../../domain/repositories/auth/IAuthRepository';
import Result from '../../domain/common/Result';
import ConfirmEmailModel from '../../domain/entities/auth/structures/ConfirmEmailModel';
import RegisterModel from '../../domain/entities/auth/structures/RegisterModel';
import ResetPasswordModel from '../../domain/entities/auth/structures/ResetPasswordModel';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import ChangePasswordModel from '../../domain/entities/auth/structures/ChangePasswordModel';
import UserSession from '../../domain/entities/auth/structures/UserSessionModel';
import LoginModel from '../../domain/entities/auth/structures/LoginModel';
import SetPasswordModel from '../../domain/entities/auth/models/SetPasswordModel';
import RegistrationEmailDataModel from '../../domain/entities/auth/structures/RegistrationEmailDataModel';

export default class AuthApi implements AuthRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    resetPassword = (object: ResetPasswordModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/User/ResetPassword`, requestOptions).then(res => {
            if (res.status === 200) {
                return Result.Ok();
            }
            if (res.status === 500) {
                return Result.Fail('Server Error', 500);
            }
            return Result.Fail('Link expired', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    register(object: RegisterModel): Promise<Result> {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/Registration/RegisterAccountRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue(await res.json());

            return Result.FailWithValue('Validation Error', await res.json(), res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }
    getRegistrationEmailData(email: string): Promise<Result<RegistrationEmailDataModel>> {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Registration/EmailData/${email}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<RegistrationEmailDataModel>(await res.json());

            return Result.Fail<RegistrationEmailDataModel>('Validation Error', res.status);
        }).catch(e => {

            return Result.Fail(e.message, 500);
        });
    }
    forgetPassword = (email: string): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(email)
        };

        return this.httpClient.httpFetch(`/api/User/ForgetPassword`, requestOptions).then(res => {

            if (res.status === 404)
                throw new Error('Email not found');
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    confirmEmail = (object: ConfirmEmailModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        return this.httpClient.httpFetch(`/api/Registration/ConfirmEmail`, requestOptions).then(res => {
            if (res.status === 200)
                return Result.Ok();
            if (res.status === 500)
                return Result.Fail('Server Error', 500);
            return Result.Fail('Wrong Link', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    changePassword = (object: ChangePasswordModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/User/ChangePassword`, requestOptions).then(res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    Login = (object: LoginModel): Promise<Result> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/User/Login`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.OkWithValue(await res.text());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    setPassword = (object: SetPasswordModel): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/Registration/SetPassword`, requestOptions).then(async res => {

            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    /**
     * ToDo: Define this method in the repository and declare the user type in domain/entities/structures
     * and edit the api url according to the UMS
     * @returns a Promise with type User
     */
    loadSession = async (): Promise<UserSession> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const result = await this.httpClient.httpFetch(`/api/User/loadUserData`, requestOptions);
        if (result.status != 200)
            return {} as UserSession;
        return result.json();
    }
}
