import { Col, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useParams } from "react-router";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import { TailSpin } from "react-loader-spinner";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import { ToastContainer } from "react-toastify";
import PersonalInformation from "../../components/person-info-edit/PersonalInformation";
import AccountPasswordRecovery from "../../components/person-info-edit/AccountPasswordRecovery";
import AlternativeContact from "../../components/person-info-edit/AlternativeContact";
import CompanyInformation from "../../components/person-info-edit/CompanyInformation";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import GLBAOption from "../../../domain/entities/usersettings/GLBAOption";
import DPPAOption from "../../../domain/entities/usersettings/DPPAOption";
import Question from "../../../domain/entities/usersettings/Question";
import UserInfoEditViewModel from "../../view-model/admin/UserInfoEditViewModel";
import RegistrationDataApi from "../../../data/registrationData/RegistrationDataApi";
import GlbaDppaOptions from "../../components/GlbaDppaOptions";
import LinkedToCompany from "../../components/person-info-edit/LinkedToCompany";
import TermsAndConditionsAndPrivacyPolicy from "../../components/person-info/TermsAndConditionsAndPrivacyPolicy";
import UserEventsHistory from "./UserEventsHistory";
import UserEventModel from "../../../domain/entities/admin/models/UserEventModel";
import useUser from "../../../infrastructure/hooks/useUser";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";
import CompanyApi from "../../../data/admin/CompanyApi";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import UserRoleApi from "../../../data/admin/UserRoleApi";


export type UserInfoComponentState = {
    questionId: number;
    answer: string
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternativeFirstName: string | null;
    alternativeLastName: string | null;
    alternativeEmail: string | null;
    alternativePhone: string | null;
    secondaryEmail: string | null;
    managerName: string | null;
    employerName: string;
    employerAddress: string;
    claimOffice: string;
    city: string;
    accountStatus: string;
    zipCode: string;
    DPPAOptionId: number;
    GLBAOptionId: number;
    validation: any;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    isSuccess: boolean;
    formIsValid: boolean;
    deactivateReasonInput: string;
    showChangeAccountStatus: boolean;
    companyId: number | undefined;
    state: string;
    userEvents: UserEventModel[]

    GLBAOptions: GLBAOption[];
    DPPAOptions: DPPAOption[];
    Questions: Question[];
    Companies: CompanyNameModel[];

    lastTosAcceptedDate: Date | null;
    isAuthorizedManager: boolean;
    isAdmin: boolean;
    isShowAuthorizedManagerDeletionWarningModal: boolean;
    isClaimOfficeRequired: boolean;

};

const UserInfoEdit: React.FC = () => {

    const { viewModel, subscription } = useViewModel(UserInfoEditViewModel, [
        new UserAccountApi(new HttpClient()),
        new RegistrationDataApi(new HttpClient()),
        new CompanyApi(new HttpClient()),
        new UserSettingApi(new HttpClient()),    
        new UserRoleApi(new HttpClient())      
    ]);

    const { id } = useParams();
    const {user} = useUser();
    const [state, setState] = useState<UserInfoComponentState>({
        questionId: 0,
        answer: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        alternativeFirstName: '',
        alternativeLastName: '',
        alternativeEmail: '',
        alternativePhone: '',
        secondaryEmail: '',
        managerName: '',
        employerName: '',
        employerAddress: '',
        claimOffice: '',
        city: '',
        accountStatus: '',
        zipCode: '',
        DPPAOptionId: 0,
        GLBAOptionId: 0,
        validation: {},
        isLoading: false,
        isShowError: false,
        errorMessages: [],
        isSuccess: true,
        formIsValid: false,
        deactivateReasonInput: '',
        showChangeAccountStatus: false,
        companyId: undefined,
        state: '',
        isClaimOfficeRequired: false,

        GLBAOptions: [],
        DPPAOptions: [],
        Questions: [],
        Companies: [],
        userEvents:[],
        lastTosAcceptedDate: null,
        isAuthorizedManager: false,
        isAdmin: false,
        isShowAuthorizedManagerDeletionWarningModal: false
    }
    );
    
    let deactivateReason = "";
    const activationEvents = state.userEvents.filter(e => e.typeId == 3 || e.typeId == 4);
    if(activationEvents.length > 0){
        const lastEvent = activationEvents[activationEvents.length-1];
        const lastActivationDetails = JSON.parse(lastEvent?.details ?? "{}");
        deactivateReason = lastActivationDetails["Reason"];
    }


    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });

        viewModel.getData(id || '');
        viewModel.getIsAdmin(id || '');

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return <div className="container user-info">
        <ToastContainer
            position="top-center"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            limit={1}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            className="avoid-nav-bar"
            bodyClassName="toast-message"
        />
        <Row className="header">
            <Col><a href="/admin/user"> <BackArrowSvgIcon /></a> {state.firstName}&apos;s Profile</Col>
            <Col md="auto" className="p-0">
                <Row>
                    {deactivateReason &&  <Col className="inactive-account-reason">Reason: {deactivateReason}</Col>}
                    <Col
                        md="auto"
                        onClick={viewModel.openChangeAccountStatusModal}
                        title="Edit"
                        className={state.accountStatus == 'Active' ? "account-status active-account-status" : "account-status inactive-account-status"}>
                        {state.accountStatus == 'Active' ? 'Active' : 'Inactive'}
                    </Col>
                </Row>
            </Col>
        </Row>

        <PersonalInformation state={state} viewModel={viewModel} editableEmail={false} isAuthorizedManagerEnabled={true} isAdminEnabled={true} />
        <AlternativeContact state={state} viewModel={viewModel} />
        <AccountPasswordRecovery state={state} viewModel={viewModel} />
        <CompanyInformation state={state} viewModel={viewModel} />
        <LinkedToCompany selectedCompanyId={state.companyId} onChange={viewModel.onCompanyIdChange} companyNames={state.Companies} />
        <GlbaDppaOptions glbaSelectedOption={state.GLBAOptionId} dppaSelectedOption={state.DPPAOptionId} onChangeGlba={viewModel.onGlbaChange} onChangeDppa={viewModel.onDppaChange} />
        <UserEventsHistory userEvents={state.userEvents} GLBAOptions={state.GLBAOptions} DPPAOptions={state.DPPAOptions} companies={state.Companies} questions={state.Questions}/>
        <TermsAndConditionsAndPrivacyPolicy DateOfAcceptance={state.lastTosAcceptedDate} />
        <div className="save-btn"><button disabled={!state.formIsValid} onClick={viewModel.onSave} className="btn next-btn">Save</button></div>

        <Modal show={state.showChangeAccountStatus}>
            <Modal.Body className={(state.accountStatus == 'Active' ? "deactivate " : "activate ") + "modal-card change-account-status-modal-card"}>
                <div className="deny-account-modal">
                    <Row className="change-account-status-modal-header">
                        <Col className="p-0"><span className="cancel-modal-title">Account Status</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={() => viewModel.closeChangeAccountStatusModal()}>x</span></Col>
                    </Row>
                    <Row>
                        <Col><input disabled={state.accountStatus == 'Active' } checked type="radio" /><span className="status-name">Active</span></Col>
                        <Col><input disabled={state.accountStatus != 'Active' } checked type="radio" /><span className="status-name">Inactive</span></Col>
                    </Row>
                    {state.accountStatus != 'Active' && <Row>Are you sure you want to activate this account?</Row>}
                    {state.accountStatus == 'Active' &&
                        <Row>
                            <Row className="p-0"><span className="row-title">Reason<span className="mandatory-symbol">*</span></span></Row>
                            <Row><textarea value={state.deactivateReasonInput} className="form-control" onChange={viewModel.onDeactivateReasonChange} maxLength={30} /></Row>
                        </Row>
                    }
                    <Row className="deny-modal-btn-row">
                        <Col md="auto" className="p-0"><a onClick={() => viewModel.closeChangeAccountStatusModal()} className="btn previous-btn">Cancel</a></Col>
                        {state.accountStatus == 'Active' && <Col className="p-0"><button onClick={() => viewModel.deactivateAccount(user.firstName, user.lastName)} disabled={!viewModel.enableSavingStatusChange} className="btn next-btn">Save</button></Col>}
                        {state.accountStatus != 'Active' && <Col className="p-0"><button onClick={() => viewModel.activateAccount(user.firstName, user.lastName)} className="btn next-btn">Yes</button></Col>}
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={state.isShowAuthorizedManagerDeletionWarningModal}>
            <Modal.Body className="modal-card warning-modal-card authorized-manager-warning-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Warning</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideAuthorizedManagerDeletionWarning}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">You have unchecked&nbsp;&apos;Authorized Manager&apos;.&nbsp;If you continue,&nbsp;this user will lose all access to other users&apos;&nbsp;searches. Do you wish to continue?</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col md="auto" className="p-0"><a onClick={viewModel.hideAuthorizedManagerDeletionWarning} className="btn previous-btn">No, I will change my update</a></Col>
                        <Col className="p-0"><a onClick={viewModel.onConfirmSave} className="btn next-btn">Yes, I want to continue</a></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        <TailSpin
            wrapperClass="cover-spin"
            visible={state.isLoading}
            height={50}
            width="50"
            color="#FF5733"
            ariaLabel="loading"
        />
        <Modal show={state.isShowError}>
            <Modal.Body className="modal-card warning-modal-card error-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Server Error</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={viewModel.resetError}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col className="p-0"><a onClick={viewModel.resetError} className="btn warning-btn">Try Again Later</a></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    </div>
}

export default UserInfoEdit;