import LoginAgreement from "../../presentation/utils/LoginAgreement";

export class HttpClient {

    httpFetch = async (url: string, request: any) => {
        const _request = request;
        _request.headers = Object.assign({}, request.headers, {
            'X-CSRF': '1',
        });

        const response = await fetch(url, _request);
        if (response.status == 401 && !url.includes('/loadUserData') && !url.includes('Login'))
            LoginAgreement.onBeforeLogin();

        return response;
    };

}