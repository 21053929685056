import { combineReducers } from "redux";
import LocalizationReducer from "./localization";
import ErrorReducer from "./errorReducer";
import ReRunSearchReducer from "./reRunSearchReducer";
import SearchDetailsReducer from "./searchDetailsReducer";
import UserProfileReducer from "./userProfileReducer";
import pendingRequestsCountReducer from "./pendingRequestsCountReducer";

const rootReducer = combineReducers({
  localization: LocalizationReducer,
  error: ErrorReducer,
  search: ReRunSearchReducer,
  searchDetails: SearchDetailsReducer,
  userProfile: UserProfileReducer,
  pendingRequestsCount: pendingRequestsCountReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
