import Result from "../domain/common/Result";
import RequestsCountModel from "../domain/entities/admin/models/RequestsCountModel";
import GenericDataRepo from "../domain/repositories/IGenericDataRepo";
import { HttpClient } from "../infrastructure/utils/fetchInterceptor";


export default class GenericDataApi implements GenericDataRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    GetRequestsCount = (): Promise<Result<RequestsCountModel | string>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/GenericData/RequestsCount`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<RequestsCountModel>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    
}
