import React from "react";
import { Row, Col } from 'react-bootstrap';
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";

export type Props = {
    results: SocialResultModel[];
    platformIconUrl: string;
    platformName: string;

}


const PDFSocialResultsWithNoData: React.FC<Props> = ({ results, platformIconUrl, platformName }: Props) => {

    return <Row className="social-result-card box-shadow bg-white">
        {results[0].rulesBasedValidation && (
            <Row>
                <span className="social-identifiers-header col-auto p-0 me-1">Identifiers:</span>
                <span className="social-identifiers col-auto p-0">{results[0].rulesBasedValidation}</span>
            </Row>)}

        <Row className="my-2">
            <Col className={`platform col-auto ${platformName?.replaceAll(" ","-")}`}>
                <img
                    className="platform-icon p-0 me-1"
                    src={platformIconUrl}
                    alt="img"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/search-results/defaultsocialicon.png";
                    }}
                />
                {platformName ?? results[0].url.split('.com')[0]}
            </Col>
        </Row>
        {results.map(r =>
            <Row className="social-result-no-data my-2" key={Math.random()}>
                <a href={"https://" + r.url} target="_black">{r.url}</a>
            </Row>)}
    </Row>;
}

export default PDFSocialResultsWithNoData;
