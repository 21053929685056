import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import UserSearchRequestsViewPermissionRequestModel from "../../../domain/entities/usersettings/UserSearchRequestsViewPermissionRequestModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import ViewPermissionsViewModel from "../../view-model/usersettings/ViewPermissionsViewModel";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Modal, Row } from "react-bootstrap";
import useUser from "../../../infrastructure/hooks/useUser";

type permissionsState = {
    emailFormValues: string[];
    pageData: UserSearchRequestsViewPermissionRequestModel[];
    validation: any;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    isLoading: boolean;
    isShowError: boolean;
    isValidForm: boolean;
}

const maxNumOfEmails = 5;

const UserViewPermissions: React.FC = () => {

    const { viewModel, subscription } = useViewModel(ViewPermissionsViewModel, [
        new UserSettingApi(new HttpClient()),
    ]);

    const [state, setState] = useState<permissionsState>({ emailFormValues: [], pageData: [], validation: [], pageNumber: 0, pageSize: 10, totalCount: 0, isLoading: false, isShowError: false, isValidForm: false });
    const { user } = useUser();

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) =>
            setState({
                ...state,
                ...d.data,
            })
        );

        viewModel.getPage();
       
        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    useEffect(() => viewModel.setCurrentUserEmail(user.email), [user]);

    const columns: ColumnDescription[] = [
        {
            dataField: "requestedUsersEmails",
            text: "Requested Emails"
        },
        {
            dataField: "requestStatus",
            text: "Status",
        }
    ];

    return (
        <div className="permissions-class">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                bodyClassName="toast-message"
            />
            <p className="intro">
                If you oversee other employees, you may request access to their accounts to see their search histories. Please include the email addresses of any accounts you are requesting to access. All emails entered must match your organization. Once submitted, we will verify your request with your account administrator and then approve or deny your request.
            </p>
            <Row>
                {state.emailFormValues.map((emailItem, index) => (
                    <Col className="input-group-meta form-group col-md-6" key={index}>
                        <div className="remove-extra-input-div form-floating">
                            <input autoComplete="new-password" type="text" name="your_email" id="your_email" pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Email"
                                onChange={(e: React.FormEvent<HTMLInputElement>): void => { viewModel.onEmailQueryChanged(e.currentTarget.value, index) }}
                                value={emailItem || ""}
                                className={`form-control ${state.validation.emails && state.validation.emails[index] ? "is-invalid" : ""}`}
                            />
                            <label className="form-label">Email</label>
                            {index || state.emailFormValues.length > 1 && index > 0 ? (<span className="search-input-icon bi bi-trash" onClick={() => viewModel.onEmailQueryRemoved(index)}></span>) : null}
                            <Row>{state.validation.emails && state.validation.emails[index] && (
                                <div className="invalid-feedback mb-2">
                                    {state.validation.emails[index]}
                                </div>
                            )}</Row>
                            
                        </div>
                    </Col>
                ))}
            </Row>
            <Row>
                {state.emailFormValues.length < maxNumOfEmails &&
                    <div className="extra-input-add-div px-2">
                        <p className="extra-input-add-text d-flex" onClick={() => viewModel.onEmailQueryAdded()}>
                            <img className="extra-input-add-icon" src="/images/add-input.svg" alt="" />
                            Add Email
                        </p>
                    </div>
                }
            </Row>
            <button disabled={!state.isValidForm} onClick={viewModel.submit} className="btn next-btn">Submit</button>
            <div className="table-style mt-4">
                <BootstrapTable
                    keyField="searchId"
                    columns={columns}
                    data={state.pageData}
                    bordered={false}
                    headerClasses="header-tr"
                    remote
                    pagination={paginationFactory({
                        pageStartIndex: 1,
                        page: state.pageNumber + 1,
                        sizePerPage: state.pageSize,
                        totalSize: state.totalCount,
                        firstPageText: "First",
                        prePageText: "Back",
                        nextPageText: "Next",
                        lastPageText: "Last",
                        nextPageTitle: "First page",
                        prePageTitle: "Pre page",
                        firstPageTitle: "Next page",
                        lastPageTitle: "Last page",
                        onSizePerPageChange: viewModel.onPageSizeChange,
                        sizePerPageList: [
                            { text: "10", value: 10},
                            { text: "25", value: 25},
                            { text: "50", value: 50},
                            { text: "100", value: 100},
                          ]
                    })}
                    onTableChange={viewModel.onTableChange}
                    noDataIndication={() => (
                        <TailSpin
                            wrapperClass="table-spinner"
                            visible={false}
                            height={50}
                            width="50"
                            color="#FF5733"
                            ariaLabel="loading"
                        />
                    )}
                />
            </div>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UserViewPermissions;