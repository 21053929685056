import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import App from './App';
import rootReducer from './lib/infrastructure/redux/reducer';
import Layout from './lib/presentation/components/Layout';
import './assets/main.scss';
import "react-toastify/ReactToastify.min.css";

const store = createStore(rootReducer);
ReactDOM.render(
  <Provider store={store}>
    <Layout>
      <App /> 
    </Layout>
  </Provider>,
  document.getElementById('root')
);