import React from "react";
import { Col, Row } from "react-bootstrap";
import PendingRequestModel from "../../../domain/entities/admin/models/PendingRequestModel";


type Props = {
    searchRequestViewRequest: PendingRequestModel;
}

const ViewPermissionRequestDetails: React.FC<Props> = (props: Props) => {

    return <div className="account-info-details">
        <Row className="header-section">
            <Col md="auto" className="p-0 section-title">Requestor Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="info-section">
            <Row className="titile-row">
                <Col><span className="title">Email</span></Col>
                <Col><span className="title">Employer Name</span></Col>
            </Row>
            <Row className="data-row">
                <Col><span className="value">{props.searchRequestViewRequest.email}</span></Col>
                <Col><span className="value">{props.searchRequestViewRequest.employerName}</span></Col>
            </Row>
            <Row className="titile-row">
                <Col><span className="title">Request Date</span></Col>
            </Row>
            <Row className="data-row">
                <Col><span className="value">{new Date(props.searchRequestViewRequest.requestDate).toLocaleDateString()}</span></Col>
            </Row>
        </Row>
        <Row className="header-section">
            <Col md="auto" className="p-0 section-title">Viewing other users</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="info-section">
            <Row>
                {props.searchRequestViewRequest.requestedEmails.split(',')?.map(e => <Col key={Math.random()} md="6" className="data-row"><span className="value">{e}</span></Col>)}
            </Row>
        </Row>
    </div>;
}

export default ViewPermissionRequestDetails;