import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Form, Modal, Row } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TailSpin } from "react-loader-spinner";
import PendingUserRequestApi from "../../../data/admin/PendingUserRequestApi";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserViewPermissionModel from "../../../domain/entities/admin/models/UserViewPermissionModel";
import UserViewPermissionsViewModel, { Approved } from "../../view-model/admin/UserViewPermissionsViewModel";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import { ToastContainer } from "react-toastify";
import UserAccountApi from "../../../data/admin/UserAccountApi";

type ViewPermissionsState = {
    userName: string;
    viewPermissions: UserViewPermissionModel[];
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];

    pageIndex: number;
    pageSize: number;
    totalCount: number;
};

const UserViewPermissions: React.FC = () => {

    const { id } = useParams();

    const { viewModel, subscription } = useViewModel(UserViewPermissionsViewModel, [
        new PendingUserRequestApi(new HttpClient()),
        new UserAccountApi(new HttpClient())
    ]);

    const [state, setState] = useState<ViewPermissionsState>({
        userName: viewModel.userName,
        viewPermissions: viewModel.viewPermissions,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0
    });

    useEffect(() => {
        viewModel.getData(id ?? '');
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });
    }, []);

    const columns: ColumnDescription[] = [
            {
                dataField: "requestedUsersEmails",
                text: "Users Emails to View"
            },
            {
                dataField: "requestDate",
                text: "Request Date",
                formatter: (cell, row) => <span>{new Date(row.requestDate).toLocaleDateString()}</span>
            },
            {
                dataField: "requestStatus",
                text: "Actions",
                formatter: (cell, row) => (<>
                    <Form.Switch className="status-toggle" checked={row?.requestStatus == Approved} onChange={() => viewModel.onToggle(row.requestId)} />
                    <span>Approved</span>
                </>)
            }
        ];


        return (
            <div className="container">
                <ToastContainer
                    position="top-center"
                    hideProgressBar
                    newestOnTop={false}
                    limit={1}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    className="avoid-nav-bar"
                    bodyClassName="toast-message"
                />
                <div>
                    <Row>
                        <Col><div className="admin-page-title"><a href="/admin/user"> <BackArrowSvgIcon /> </a> {state.userName}&apos;s Search Requests View Permissions</div></Col>
                    </Row>
                    <div className="table-style mt-4">
                        <BootstrapTable
                            keyField="requestId"
                            columns={columns}
                            data={state.viewPermissions}
                            bordered={false}
                            headerClasses="header-tr"
                            remote
                            pagination={paginationFactory({
                                pageStartIndex: 1,
                                page: state.pageIndex + 1,
                                sizePerPage: state.pageSize,
                                totalSize: state.totalCount,
                                firstPageText: "First",
                                prePageText: "Back",
                                nextPageText: "Next",
                                lastPageText: "Last",
                                nextPageTitle: "First page",
                                prePageTitle: "Pre page",
                                firstPageTitle: "Next page",
                                lastPageTitle: "Last page",
                                onSizePerPageChange: viewModel.onPageSizeChange,
                                sizePerPageList: [
                                    { text: "10", value: 10 },
                                    { text: "25", value: 25 },
                                    { text: "50", value: 50 },
                                    { text: "100", value: 100 },
                                ]
                            })}
                            onTableChange={viewModel.onTableChange}
                        />
                    </div>
                </div>
                <TailSpin
                    wrapperClass="cover-spin"
                    visible={state.isLoading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                />
                <Modal show={state.isShowError}>
                    <Modal.Body className="modal-card warning-modal-card error-modal">
                        <div>
                            <Row className="warning-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                            </Row>
                            <Row>
                                <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                            </Row>
                            <Row className="warning-modal-btn-row">
                                <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );

}

export default UserViewPermissions;