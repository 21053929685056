import React from "react";
import { Col, Row } from "react-bootstrap";
import AddUpdateCompanyViewModel from "../../view-model/admin/AddUpdateCompanyViewModel";
import { CompanyState } from "../../views/admin/AddUpdateCompany";


type Props = {
    state: CompanyState,
    viewModel: AddUpdateCompanyViewModel
}

const SalesRepresentative: React.FC<Props> = ({ state, viewModel }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Ontellus Sales Representative</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Name"
                    name="salesRepresentitaveName"
                    id="salesRepresentitaveName"
                    onChange={viewModel.onSalesRepresentativeNameChange}
                    value={state.company.salesRepresentitaveName}
                    className={`form-control ${state.validation.salesRepresentitaveName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Name<span className="mandatory-symbol">*</span></label>
                {state.validation.salesRepresentitaveName && (
                    <div className="invalid-feedback">
                        {state.validation.salesRepresentitaveName}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Email"
                    name="salesRepresentitaveEmail"
                    id="salesRepresentitaveEmail"
                    onChange={viewModel.onSalesRepresentativeEmailChange}
                    value={state.company.salesRepresentitaveEmail}
                    className={`form-control ${state.validation.salesRepresentitaveEmail ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email<span className="mandatory-symbol">*</span></label>
                {state.validation.salesRepresentitaveEmail && (
                    <div className="invalid-feedback">
                        {state.validation.salesRepresentitaveEmail}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default SalesRepresentative;
