import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import UserSearchRequestsViewPermissionRequestModel from "../../../domain/entities/usersettings/UserSearchRequestsViewPermissionRequestModel";
import FormValidator from "../../utils/FormValidator";
import { toast } from "react-toastify";
import { TableChangeState } from "react-bootstrap-table-next";

export default class ViewPermissionsViewModel implements IBaseViewModel {

    public pageNumber: number;
    public pageSize: number;
    public totalCount: number;

    public emailFormValues: string[];
    public emails: string[];
    public data: UserSearchRequestsViewPermissionRequestModel[];
    public pageData: UserSearchRequestsViewPermissionRequestModel[];
    public validation: any;

    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public isSuccess: boolean;

    private topic?: string;
    private subject?: Subject<unknown>;
    private userSettingRepository: UserSettingRepository;
    private currentUserEmail: string;
    private isValidForm: boolean;

    public constructor(userSettingRepository: UserSettingRepository) {
        this.pageNumber = 0;
        this.pageSize = 10;
        this.totalCount = 0;

        this.emailFormValues = [""];
        this.emails = [""];
        this.pageData = [];
        this.data = [];
        this.validation = [];
        
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.isSuccess = false;

        this.userSettingRepository = userSettingRepository;
        this.currentUserEmail = '';
        this.isValidForm = false;
    }

    public setCurrentUserEmail = (email: string): void => { this.currentUserEmail = email; }

    public getPage = async (): Promise<void> => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userSettingRepository.GetSearchRequestsViewPermissionRequest();
            this.isShowError = !result.isSuccess;

            if (result.isSuccess && result.value && result.value.length > 0) {
                this.data = result.value;
                this.totalCount = this.data.length;
                this.updateCurrentPageData(1, 10);
            }
            else if (!result.isSuccess)
                this.errorMessages.push(result.error);

            this.isLoading = false;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    };

    public validateEmails = () => {
        this.validation.emails = Array(5).fill('');
        let error = false;

        for (let i = 0; i < this.emails.length; i++) {
            const duplicateIndex = this.getDuplicateIndex(this.emailFormValues, this.emails[i]);

            if (!this.emails[i]?.replaceAll(' ', '') || !FormValidator.isValidEmail(this.emails[i])) {
                error = true;
                this.validation.emails[i] = "Email is not valid";
            }
            else if (!this.isSameOrganizationEmail(this.emails[i])) {
                error = true;
                this.validation.emails[i] = "Access denied. This user is not part of your organization.";
            }
            else if (duplicateIndex != -1) {
                error = true;
                this.validation.emails[i] = "Duplicated email";
            }
            else
                this.validation.emails[i] = "";
        }

        if (!error) {
            this.validation.emails = [];
            this.isValidForm = true;
            return true;
        }
        else {
            this.isValidForm = false;
            return false;
        }      
   }

    private isSameOrganizationEmail = (email: string) => {
        const pattern = /@([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)$/;
        const currentUserEmailDomain = this.currentUserEmail.match(pattern);
        const emailDomain = email.match(pattern);

        return currentUserEmailDomain?.at(1) == emailDomain?.at(1);
    }

    private getDuplicateIndex(emails: string[], email: string) {
        const checkDuplicates = emails.filter(e => e == email);

        if (checkDuplicates?.length <= 1)
            return -1;

        const duplicateIndex = emails.lastIndexOf(email);
        return duplicateIndex;
    }

    public onEmailQueryAdded(): void {

        this.validateEmails();

        if (this.validation.emails?.length == 0) {
            this.emailFormValues.push("");
            this.notifyViewAboutChanges();
        }
    }

    public onEmailQueryChanged(email: string, index: number): void {
        this.emailFormValues[index] = email;
        this.emails[index] = email;
        this.validateEmails();
        this.notifyViewAboutChanges();
    }

    public onEmailQueryRemoved(index: number): void {
        this.emails.splice(index, 1);
        this.emailFormValues.splice(index, 1);
        this.validateEmails();
        this.notifyViewAboutChanges();
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    public updateCurrentPageData = (pageNumber: number, pageSize: number) => {

        const pageIndex = pageNumber - 1;
        const currentPageStartIndex = pageIndex * pageSize;
        const currentPageData = this.data.slice(currentPageStartIndex, currentPageStartIndex + pageSize);

        this.pageNumber = pageIndex;
        this.pageSize = pageSize;
        this.pageData = currentPageData;

        this.notifyViewAboutChanges();
    }

    public submit = async () => {

        let emails: string = this.emails[0];
        this.emails.slice(1).forEach(e => emails += `, ${e}`);

        this.isLoading = true;
        this.notifyViewAboutChanges();
        const r = await this.userSettingRepository.AddSearchRequestsViewPermissionRequest(emails);
        this.isLoading = false;

        this.isShowError = !r.isSuccess;
        r.error ? this.errorMessages.push(r.error) : null;

        if (r.isSuccess) {
            this.emails = [""];
            this.emailFormValues = [""];
            this.data = ([{ requestedUsersEmails: emails, requestStatus: 'Pending' }]).concat(this.data);
            this.updateCurrentPageData(1, this.pageSize);
            this.notify();
        }

        this.notifyViewAboutChanges();
    }

    private notify = () => {
        toast.success("A request has been submitted to the Admin", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };
   
    public notifyViewAboutChanges = (): void => {
        const data = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            totalCount: this.totalCount,
            pageData: this.pageData,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            emailFormValues: this.emailFormValues,
            validation: this.validation,
            isValidForm: this.isValidForm
        };
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject;
    };
}
