import React from "react";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import EmailBreaches from "./EmailBreaches"
import GoogleSearch from "./GoogleSearch";

type EmailProps = {
    email: EmailModel;
};

const Email: React.FC<EmailProps> = (props: EmailProps) => {
    return (
        <div className="education-box-body">       
            <div className="address-inner-content">
                {props.email.gHuntOutput.profilePictureUrl ? (
                    <a
                        href={props.email.gHuntOutput.profilePictureUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={props.email.gHuntOutput.profilePictureUrl}
                            className="output-tab-content-img"
                            alt="img"
                        />
                    </a>
                ) : (
                    <img
                        src="/images/email.svg"
                        className="output-tab-content-icon"
                        alt="img"
                    />
                )}

                <p
                    className={
                        props.email.gHuntOutput.profilePictureUrl
                            ? "address-text mt-3"
                            : "address-text"
                    }
                >
                    {props.email.email}{" "}
                </p>
            </div>
            <div className="education-card-body">
                <div className="education-inner-card-body">
                    {props.email.gHuntOutput.gaiaId  && (<div className=" education-inner-text-div-card-body">
                        <p className="education-inner-title-card-body">GAIA ID: </p>
                        <p className="education-inner-text-card-body">
                            {props.email.gHuntOutput.gaiaId}
                        </p>
                    </div>)}
                    {props.email.gHuntOutput.name.firstName && (
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">Name: </p>
                            {props.email.gHuntOutput.name && (
                                <p className="education-inner-text-card-body">
                                    {props.email.gHuntOutput.name.firstName}{" "}
                                    {props.email.gHuntOutput.name.lastName}
                                </p>
                            )}
                        </div>
                    )}
                    {props.email.gHuntOutput.locationName && (
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">Location: </p>
                            <p className="education-inner-text-card-body">
                                {props.email.gHuntOutput.locationName}
                            </p>
                        </div>
                    )}
                    {props.email.gHuntOutput.gMapReviewsCount && (
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">
                                Maps Review:{" "}
                            </p>
                            <p className="education-inner-text-card-body">
                                {props.email.gHuntOutput.gMapReviewsCount} reviews -{" "}
                                {props.email.gHuntOutput.gMapAvarageRating} stars
                            </p>
                        </div>
                    )}
                    {props.email.gHuntOutput.gCalendarUrl &&
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">
                                Public Calendar:{" "}
                            </p>
                            <p className="education-inner-text-card-body">
                                {props.email.gHuntOutput.gCalendarUrl && (
                                    <a
                                        href={"https://" + props.email.gHuntOutput.gCalendarUrl}
                                        target="_blank"
                                        className="education-inner-text-card-body"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/images/google-calendar.svg"
                                            className="hyperimg"
                                            alt="img"
                                        />
                                    </a>
                                )}
                                {!props.email.gHuntOutput.name && (
                                    <p className="education-inner-text-card-body">No</p>
                                )}
                            </p>
                        </div>}
                    {props.email.gHuntOutput.gMapReviewsUrl &&
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">
                                Google Map Reviews:{" "}
                            </p>
                            <p className="education-inner-text-card-body">
                                {props.email.gHuntOutput.gMapReviewsUrl && (
                                    <a
                                        href={"https://" + props.email.gHuntOutput.gMapReviewsUrl}
                                        target="_blank"
                                        className="education-inner-text-card-body"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/images/google-maps-old.svg"
                                            className="hyperimg"
                                            alt="img"
                                        />
                                    </a>
                                )}
                            </p>
                        </div>}
                    {props.email.connectedSites &&
                        <div className=" education-inner-text-div-card-body">
                            <p className="education-inner-title-card-body">Connected Sites: </p>
                            <p className="education-inner-text-card-body">
                                {props.email.connectedSites}
                            </p>
                        </div>
                    }
                </div>
            </div>
            {props?.email?.emailBreaches?.length > 0 && <EmailBreaches breaches={props.email.emailBreaches} />}
            <GoogleSearch email={props.email.email} />
        </div>
    );
};
export default Email;
