import React, { useEffect, useState } from "react";
import UserEventModel from "../../../domain/entities/admin/models/UserEventModel";
import { Col, Modal, Row, Table } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import GLBAOption from "../../../domain/entities/usersettings/GLBAOption";
import DPPAOption from "../../../domain/entities/usersettings/DPPAOption";
import { renderToStaticMarkup } from "react-dom/server";
import AccountHistoryDetails from "./AccountHistoryDetails/AccountHistoryDetails";
import { bool } from "yup";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";
import Question from "../../../domain/entities/usersettings/Question";

type Props = {
  userEvents: UserEventModel[];
  GLBAOptions: GLBAOption[];
  DPPAOptions: DPPAOption[];
  companies: CompanyNameModel[];
  questions: Question[];
}

type State = {
  showDetailsModel: boolean,
  selectedEvent?: UserEventModel
}


const UserEventsHistory: React.FC<Props> = ({ userEvents, GLBAOptions, DPPAOptions, companies, questions }: Props) => {

  const [state, setState] = useState<State>({ showDetailsModel: false, selectedEvent: undefined })
  const selectEvent = (event: UserEventModel) => {
    setState({ selectedEvent: event, showDetailsModel: true });
  }

  return (
    <div className="account-history">
      <Row className="option-title">
        <Col md="auto" className="p-0 section-title">Account History</Col>
        <Col><hr /></Col>
      </Row>
      <div className="">
        <Table borderless striped>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Admin</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {userEvents.map(e =>
              <tr key={Math.random()}>
                <td>{new Date(e.date).toLocaleString("en-US")}</td>
                <td>{e.typeName}</td>
                <td>{`${e.adminFirstName} ${e.adminLastName}`}</td>
                <td>{e.details && <button className="btn" onClick={() => selectEvent(e)}> Show Details </button>}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>


      <Modal dialogClassName="account-history-modal" show={state.showDetailsModel}>
        <Modal.Body className="">
          <div className="">
            <Row className="">
              <Col className="p-0"><span className="account-history-modal-title">{state.selectedEvent?.typeName}</span></Col>
              <Col md="auto" className="close-modal-icon"><span onClick={() =>{setState({...state,showDetailsModel:false})}}>x</span></Col>
            </Row>
            <Row>
              <p className="account-history-detail-modal-body"><AccountHistoryDetails event={state.selectedEvent} DPPAOptions={DPPAOptions} GLBAOptions={GLBAOptions} companies={companies} questions={questions} /></p>
            </Row>
            <Row className="">              
              <Col className="p-0"><a onClick={() =>{setState({...state,showDetailsModel:false})}} className="btn">Ok</a></Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserEventsHistory;
