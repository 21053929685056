import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import ErrorAlert from "../../components/ErrorAlert";
import FloatingLabelControl from "../../components/FloatingLabelControl";
import SuccessAlert from "../../components/SuccessAlert";
import SocialConnectionViewModel from "../../view-model/usersettings/SocialConnectionViewModel";

type CreateSocialConnectionComponentProps = {
  id: string | undefined;
  closeModal?: () => void;
};

type CreateSocialConnectionComponentState = {
  id: string | null;
  username: string;
  mediaName: string;
  password: string;

  validation: any;
  isLoading: boolean;
  isSuccess: boolean;
  isShowError: boolean;
  errorMessages: string[];
};

const CreateSocialConnection: React.FC<CreateSocialConnectionComponentProps> = (
  props: CreateSocialConnectionComponentProps
) => {
  const { viewModel, subscription } = useViewModel(SocialConnectionViewModel, [
    new UserSettingApi(new HttpClient()),
  ]);

  const [state, setState] = useState<CreateSocialConnectionComponentState>({
    id: "",
    username: "",
    mediaName: "",
    password: "",

    validation: {},
    isSuccess: false,
    isLoading: false,
    isShowError: false,
    errorMessages: [],
  });

  const id = props.id;

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );

    if (id) viewModel.onIdQueryChanged(id);
    viewModel.listSocialTypes();
    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="case-modal-header">
        <h2 className="new-case-title">Add Connection</h2>
        <img
          className="modal-close-icon"
          src="/images/close.svg"
          alt=""
          onClick={props.closeModal}
        />
      </div>
      <div className="">
        <div className="case-form col-12 mb-3">
          <div className="input-group-meta form-group modal-select-input form-floating">
            <select
              name="mediaName"
              id="mediaName"
              value={state.mediaName}
              className="form-select form-control"
              onChange={(e: React.FormEvent<HTMLSelectElement>): void => {
                viewModel.onMediaNameQueryChanged(e.currentTarget.value);
              }}
            >
              {viewModel.mediaTypesList.map((socialType) => {
                return (
                  <option key={socialType.id} value={socialType.id}>
                    {socialType.name}
                  </option>
                );
              })}
            </select>
            <label className="form-label">Social Media</label>
          </div>
        </div>

        <div className="case-form col-12">
          <div className="input-group-meta form-group form-floating">
            <input autoComplete="new-password"
              type="text"
              placeholder="Username"
              name="username"
              id="username"
              onChange={viewModel.onQueryChanged}
              value={state.username}
              className={`form-control ${state.validation.username
                  ? "case-form-input is-invalid"
                  : "case-form-input"
                }`}
            />
            <label className="case-form-label">Username</label>

            {state.validation.username && (
              <div className="invalid-feedback">
                {state.validation.username}
              </div>
            )}
          </div>
        </div>

        <div className="case-form col-12">
          <div className="input-group-meta form-group mb-30 form-floating">
            <input
              type="password"
              placeholder="Password"
              name="password"
              id="password"
              onChange={viewModel.onQueryChanged}
              value={state.password}
              className={`form-control ${state.validation.password
                  ? "case-form-input is-invalid"
                  : "case-form-input"
                }`}
            />
            <label className="case-form-label">Password</label>

            {state.validation.password && (
              <div className="invalid-feedback">
                {state.validation.password}
              </div>
            )}
          </div>
        </div>

        <TailSpin
          wrapperClass="cover-spin"
          visible={state.isLoading}
          height={50}
          width="50"
          color="#FF5733"
          ariaLabel="loading"
        />

        {state.isShowError &&
          state.errorMessages.map((error, i) => {
            return (
              <div
                key={i}
                className="row my-3 text-danger justify-content-center"
              >
                {error}
              </div>
            );
          })}

        <div className="case-modal-footer">
          <button className="case-close-modal-btn" onClick={props.closeModal}>
            <p className="close-modal-btn-content">Cancel</p>
          </button>
          <button
            type="button"
            className="confirm-modal-btn"
            onClick={(): Promise<void> => viewModel.onClickAdd()}
          >
            {id && <p className="confirm-modal-btn-content">Edit Connection</p>}
            {!id && (
              <p className="confirm-modal-btn-content">Create Connection</p>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSocialConnection;
