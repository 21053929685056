import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Nav, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import InternetResultModel from "../../domain/entities/searchoutput/InternetResultModel";
import Emails from "../components/search-result/emails/Emails";
import Header, { HeaderInfo } from "../components/search-result/header/Header";
import InternetSearches from "../components/search-result/InternetSearches";
import Relatives from "../components/search-result/Relatives";
import EducationSvgIcon from "../components/svg-icons/EducationSvgIcon";
import EmailSvgIcon from "../components/svg-icons/EmailSvgIcon";
import InternetSearchSvgIcon from "../components/svg-icons/InternetSearchSvgIcon";
import PhoneSvgIcon from "../components/svg-icons/PhoneSvgIcon";
import RelativesSvgIcon from "../components/svg-icons/RelativesSvgIcon";
import SocialMediaSvgIcon from "../components/svg-icons/SocialMediaSvgIcon";
import WorkSvgIcon from "../components/svg-icons/WorkSvgIcon";
import SocialResults from "../components/search-result/SocialResults/SocialResults";
import SocialPlatformProfileModel from "../../domain/entities/searchoutput/SocialPlatformProfileModel";
import NameModel from "../../domain/entities/generic/NameModel";
import AddressModel from "../../domain/entities/searchoutput/AddressModel";
import RelativeModel from "../../domain/entities/searchoutput/RelativeModel";
import DateOfBirthModel from "../../domain/entities/searchoutput/DateOfBirthModel";
import EducationModel from "../../domain/entities/searchoutput/EducationModel";
import ExperienceModel from "../../domain/entities/searchoutput/ExperienceModel";
import EmailModel from "../../domain/entities/searchoutput/EmailModel";
import ImageModel from "../../domain/entities/searchoutput/ImageModel";
import PhoneModel from "../../domain/entities/searchoutput/PhoneModel";
import TLOTokenModel from "../../domain/entities/searchoutput/TLOTokenModel";
import UsernameModel from "../../domain/entities/searchoutput/UsernameModel";
import { useDispatch } from "react-redux";
import useViewModel from "../../infrastructure/hooks/useViewModel";
import HomeViewModel from "../view-model/searchoutput/HomeViewModel";
import SearchOutputApi from "../../data/searchoutput/SearchOutputApi";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import { useNavigate, useParams } from "react-router";
import { setError } from "../../infrastructure/redux/actions/error";
import HomeViewModelContext from "../../infrastructure/Contexts/HomeViewModelContext";
import { TailSpin } from "react-loader-spinner";
import Addresses from "../components/search-result/Addresses";
import Phones from "../components/search-result/Phones";
import Education from "../components/search-result/Education";
import Work from "../components/search-result/Work";
import AddressSvgIcon from "../components/svg-icons/AddressSvgIcon";
import SearchApi from "../../data/search/SearchApi";
import { searchDetailsAction } from "../../infrastructure/redux/actions/searchDetails";
import PDFSearchResults from "../components/search-result-pdf/search-result-pdf";
import { setReRunSearch } from "../../infrastructure/redux/actions/reRunSearch";

type SearchOutputHomeState = {
  pictureUrl: string;
  name: NameModel;
  age: string;
  caseName: string;
  caseId: number;
  headerModel: HeaderInfo;
  addresses: AddressModel[];
  relatives: RelativeModel[];
  dateOfBirths: DateOfBirthModel[];
  educations: EducationModel[];
  experiences: ExperienceModel[];
  emails: EmailModel[];
  images: ImageModel[];
  names: NameModel[];
  phones: PhoneModel[];
  socialResults: SocialPlatformProfileModel[];
  tLOTokens: TLOTokenModel[];
  usernames: UsernameModel[];
  internetResults: InternetResultModel[];

  socialMediaTabOpen: boolean;
  selectedSocialMediaTab: string;

  isLoading: boolean;
  isDownloading: boolean;
  isSuccess: boolean;
  isShowError: boolean;
  isShowPDF: boolean;
  errorMessages: string[];

  showAddModal: boolean;
  clickEmail: boolean;
  isExpiredSearchResult: boolean;
};


const SearchResult: React.FC = () => {

  const dispatch = useDispatch();
  const { viewModel, subscription } = useViewModel(HomeViewModel, [new SearchOutputApi(new HttpClient()), new SearchApi(new HttpClient())]);

  const [state, setState] = useState<SearchOutputHomeState>({
    pictureUrl: "", name: {} as NameModel, age: "",
    caseId: 0, caseName: "", addresses: [], relatives: [], dateOfBirths: [], educations: [], experiences: [], emails: [],
    images: [], names: [], phones: [], socialResults: [], tLOTokens: [], usernames: [], internetResults: [], socialMediaTabOpen: false,
    selectedSocialMediaTab: "", isSuccess: false, isLoading: false, isDownloading: false, isShowError: false, errorMessages: [],
    showAddModal: viewModel.showAddModal, headerModel: {}, clickEmail: false, isShowPDF: false, isExpiredSearchResult: false
  });

  const [displayScrollView, setdisplayScrollView] = useState(false);
  const scrollHandler = () => window.scrollY > 200 ? setdisplayScrollView(true) : setdisplayScrollView(false);
  const scrollToTop = () => window.scrollTo(0, 0);

  const { id } = useParams();

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );

    window.addEventListener('scroll', scrollHandler);

    if (id) {
      Promise.all([viewModel.loadSearchResult(Number.parseInt(id))]).then(
        (res) => {
          for (let i = 0; i < res.length; i++) {
            if (res[i].statusCode != 200 && res[i].statusCode != 423) {
              dispatch(setError({ errorCode: res[i].statusCode }));
              break;
            }
          }
        }
      );
    } else {
      dispatch(
        setError({
          errorCode: 404,
        })
      );
    }

    if (id) {
      viewModel.getSearchDetails(Number.parseInt(id)).then(searchDetails => {
        searchDetails != null ? dispatch(searchDetailsAction(searchDetails)) : setState({ ...state, isShowError: true });
      }).catch(e => setState({ ...state, isShowError: true }));
    }

    return () => {
      subscriber.unsubscribe();
    };
  }, []);

    const navigate = useNavigate();

    const rerunSearch = async (id: number) => {
        dispatch(setReRunSearch(state.headerModel.searchDetails as unknown as ReRunSearchState));
        navigate('/');
    }

  const socialMediaCount = state.socialResults.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) ?? 0

  return (
      <>
          {state.isExpiredSearchResult ? <div className="title text-center">This search result is expired</div> :
              <HomeViewModelContext.Provider value={viewModel}>
                  {displayScrollView && window.innerWidth >= 768 && <div className={"scroll-view-background box-shadow " + (viewModel.isPrintView ? "printable-view" : '')}></div>}
                  {!state.isLoading && !state.isShowError && (
                      <>
                          <Container className="search-result">
                              <Row className={"header-component " + (displayScrollView && window.innerWidth >= 768 ? "scroll-view" : '')}>
                                  <Header headerInfo={{ ...state.headerModel, onRerunSearch: rerunSearch }} />
                              </Row>
                              <Row>
                                  {!viewModel.isPrintView && (
                                      <Tab.Container id="tabs" defaultActiveKey={state.clickEmail ? "emails" : "addressHistory"}>
                                          <Nav variant="pills">
                                              <Row className="container-fluid bg-white p-0 box-shadow nav-row">
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="addressHistory"><AddressSvgIcon />Address History <span className="nav-item-span">({state.addresses.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="work"><WorkSvgIcon />Work <span className="nav-item-span">({state.experiences.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="education"><EducationSvgIcon />Education <span className="nav-item-span">({state.educations.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="relatives"><RelativesSvgIcon />Relatives <span className="nav-item-span">({state.relatives.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="phones"><PhoneSvgIcon />Phones <span className="nav-item-span">({state.phones.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="emails"><EmailSvgIcon />Emails <span className="nav-item-span">({state.emails.length})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="socialMedia" ><SocialMediaSvgIcon />Social Media <span className="nav-item-span">({socialMediaCount})</span></Nav.Link></Nav.Item></Col>
                                                  <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="internetSearches"><InternetSearchSvgIcon />Internet Searches <span className="nav-item-span">({state.internetResults.length})</span></Nav.Link></Nav.Item></Col>
                                              </Row>
                                          </Nav>
                                          <Tab.Content>
                                              <Tab.Pane eventKey="addressHistory"><Addresses addresses={state.addresses} /></Tab.Pane>
                                              <Tab.Pane eventKey="work"><Work workData={state.experiences} /></Tab.Pane>
                                              <Tab.Pane eventKey="education"><Education educationData={state.educations} /></Tab.Pane>
                                              <Tab.Pane eventKey="relatives"><Relatives relatives={state.relatives} /></Tab.Pane>
                                              <Tab.Pane eventKey="phones"><Phones phones={state.phones.map(p => p.phoneNumber)} /></Tab.Pane>
                                              <Tab.Pane eventKey="emails"><Emails emails={state.emails} removeEmail={viewModel.removeEmail} /></Tab.Pane>
                                              <Tab.Pane eventKey="socialMedia"><SocialResults searchRequestId={id ? +id : 0} platforms={state.socialResults} /></Tab.Pane>
                                              <Tab.Pane eventKey="internetSearches"><InternetSearches searches={state.internetResults} /></Tab.Pane>
                                          </Tab.Content>
                                      </Tab.Container>)}

                                  {viewModel.isPrintView && (
                                      <>
                                          <Row className="p-0 printable-view-section"><Addresses addresses={state.addresses} /></Row>
                                          <Row className="p-0 printable-view-section"><Work workData={state.experiences} /></Row>
                                          <Row className="p-0 printable-view-section"><Education educationData={state.educations} /></Row>
                                          <Row className="p-0 printable-view-section"><Relatives relatives={state.relatives} /></Row>
                                          <Row className="p-0 printable-view-section"><Phones phones={state.phones.map(p => p.phoneNumber)} /></Row>
                                          <Row className="p-0 printable-view-section"><Emails emails={state.emails} removeEmail={viewModel.removeEmail} /></Row>
                                          <Row className="p-0 printable-view-section"><SocialResults searchRequestId={id ? +id : 0} platforms={state.socialResults} /></Row>
                                          <Row className="printable-view-section"><InternetSearches searches={state.internetResults} /></Row>
                                      </>
                                  )}
                              </Row>
                              {displayScrollView && <button className="slide-up-btn" onClick={scrollToTop} ><img src="/images/slide-up-orange.png" /></button>}
                          </Container>
                          {state.isShowPDF && (<div className="pdf-container">
                              <PDFSearchResults addresses={state.addresses} experiences={state.experiences} educations={state.educations}
                                  relatives={state.relatives} phones={state.phones} emails={state.emails} socialResults={state.socialResults}
                                  internetResults={state.internetResults} headerModel={state.headerModel} />
                          </div>)}
                      </>)
                  }

                  {state.isLoading && (
                      <TailSpin
                          wrapperClass="cover-spin"
                          visible={state.isLoading}
                          height={50}
                          width="50"
                          color="#FF5733"
                          ariaLabel="loading"
                      />
                  )}

                  {state.isDownloading && (
                      <TailSpin
                          wrapperClass="cover-spin"
                          visible={state.isDownloading}
                          height={50}
                          width="50"
                          color="#FF5733"
                          ariaLabel="loading"
                      />
                  )}

                  <Modal show={state.isShowError}>
                      <Modal.Body className="modal-card warning-modal-card error-modal">
                          <div>
                              <Row className="warning-modal-header">
                                  <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                  <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                  <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                              </Row>
                              <Row>
                                  <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                              </Row>
                              <Row className="warning-modal-btn-row">
                                  <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                              </Row>
                          </div>
                      </Modal.Body>
                  </Modal>

              </HomeViewModelContext.Provider>
          }
      </>
  );
};

export default SearchResult;
