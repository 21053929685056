import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";

type Props = {
    isChecked: boolean;
    isPendingRequestExist: boolean;
    onClick: () => void;
}

const AuthorizedManagerRequestButton: React.FC<Props> = (props: Props) => {

    const renderTooltipRequest = (p: any) => (<Tooltip id="button-tooltip" {...p}>Request Authorized Manager</Tooltip>);
    const renderTooltipPending = (p: any) => (<Tooltip id="button-tooltip" {...p}>Request Authorized Manager Pending...</Tooltip>);

    return (<>
        {!props.isChecked && !props.isPendingRequestExist &&
            <Col md={"auto"} onClick={props.onClick} className="authorized-manager-request p-0">
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderTooltipRequest}>
                    <div><img className="details-img" src="/images/details.svg" /></div>
                </OverlayTrigger>
            </Col>
        }
        {props.isPendingRequestExist &&
            <Col md={"auto"} className="authorized-manager-request p-0 cursor-not-allowed">
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderTooltipPending}>
                    <div><img className="details-img" src="/images/details.svg" /></div>
                </OverlayTrigger>
            </Col>
        }
        {props.isChecked &&
            <Col md={"auto"} className="authorized-manager-request p-0 cursor-not-allowed">
                <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderTooltipRequest}>
                    <div><img className="details-img" src="/images/details.svg" /></div>
                </OverlayTrigger>
            </Col>
        }
    </>);
}

export default AuthorizedManagerRequestButton;