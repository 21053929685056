import React from "react";
import PhoneModel from "../../../domain/entities/searchoutput/PhoneModel";
import Phone from "./Phone";
import PhoneList from "./PhoneList";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type PhonesProps = {
  phones: PhoneModel[];
};

const Phones: React.FC<PhonesProps> = ({ phones }) => {
  return (
      <div id="__phone">

          <SocialResultSectionTitle title="Phone" iconUrl="/images/phone.svg" />

          {phones && phones.length > 0 && (
              <>
                  {" "}
                  {phones
                      .filter((v) => v.moriartyOutput)
                      .map((phoneItem) => (
                          <Phone phone={phoneItem} key={Math.random()} />
                      ))}
                  {
                      <PhoneList
                          phones={phones
                              .filter((phoneItem) => !phoneItem.moriartyOutput)
                              .map((phoneItem) => phoneItem.phoneNumber)}
                      />
                  }
              </>
          )}
          {!phones ||
              (phones.length == 0 && (
                  <h2 className="title text-center">No Records found</h2>
              ))}
      </div>
  );
};
export default Phones;
