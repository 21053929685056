import React from "react";
import { Col, Row } from "react-bootstrap";
import { HeaderInfo } from "./PDFHeader";
import PDFRequestDetail from "./PDFRequestDetail";


const PDFRequestDetails: React.FC<HeaderInfo> = (props: HeaderInfo) => {

    return (
        <Row className="request-details-info">
            <Row className="title">
                <Col md={"auto"} className="request-details p-0">Request Details</Col>
            </Row>
            <Row className="details p-0">
                <PDFRequestDetail title="Address" value={props.address}><img width='30px' height='18px' src="/images/search-results/home.png" /></PDFRequestDetail>
                <PDFRequestDetail title="Phone" value={props.phone}><img width='30px' height='18px' src="/images/search-results/phone.png" /></PDFRequestDetail>
                <PDFRequestDetail title="Email" value={props.email}><img width='30px' height='18px' src="/images/search-results/email.png" /></PDFRequestDetail>
            </Row>
        </Row>
    );
};

export default PDFRequestDetails;