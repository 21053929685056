import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import IUserRoleRepo from '../../domain/repositories/admin/IUserRoleRepo';
import HelperMethods from '../../presentation/utils/HelperMethods';
import UserRoleModel from '../../domain/entities/admin/models/UserRoleModel';

export default class UserRoleApi implements IUserRoleRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    IsUserHasRole(model: UserRoleModel): Promise<Result<string | boolean>> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/UserRole` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<boolean>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    AddRole(model: UserRoleModel): Promise<Result<string>> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        return this.httpClient.httpFetch(`/api/UserRole`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    DeleteRole(model: UserRoleModel): Promise<Result<string>> {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        return this.httpClient.httpFetch(`/api/UserRole`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}

