import React from "react";
import { Row, Col, Button } from 'react-bootstrap';
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";
import InternetSearchSvgIcon from "../../svg-icons/InternetSearchSvgIcon";

export type Props = {
    result: SocialResultModel;
    platformIconUrl: string;
    platformName: string;

}


const PDFSocialSearchResult: React.FC<Props> = ({ result, platformIconUrl, platformName }: Props) => {

    return <Row className="social-result-card box-shadow bg-white">
        <Row className="my-2">
            <Col className={`platform col-auto ${platformName?.replaceAll(" ", "-")}`}>
                <img
                    className="platform-icon p-0 me-1"
                    src={platformIconUrl}
                    alt="img"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/search-results/defaultsocialicon.png";
                    }}
                />
                {platformName ?? result.url.split('.com')[0]}
            </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
            <Button href={result.url} target="_blank" className="social-view-button py-1 facebook-search">
                <img width='30px' height='18px' src="/images/search-results/search.png" />

                Search Facebook For Subject
            </Button>
        </Row>
    </Row>;
}

export default PDFSocialSearchResult;
