export default class HelperMethods {

    static getCopyOf<T>(array: T[]): T[] {
        return JSON.parse(JSON.stringify(array));
    }

    static onlyUnique(value: string, index: number, array: string[]) {
        return array.indexOf(value) === index && value;
    }

    static toQueryString(queryObj : any){
        const validProps = Object.keys(queryObj).filter(p => queryObj[p] != null && queryObj[p] != undefined && queryObj[p] !== "");
        if(validProps.length < 1)
            return "";
        return "?" + validProps.map(p => `${p}=${this.formatParameter(queryObj[p])}`).reduce((a,v) => a+"&"+v)
    }

    static formatParameter(obj : any)
    {
        if(obj instanceof Date){
            const date = obj as Date;
            return `${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}`
        }
        return obj;
    }

}
