import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel";
import { SET_User_Profile } from "./actionTypes";

const setUserProfile = (payload: UserAccountModel): ActionType => {
    return {
        type: SET_User_Profile,
        payload: payload
    }
}

export default setUserProfile;