import React, { useContext } from "react";
import { Row, Col } from 'react-bootstrap';
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";
import HomeViewModelContext from "../../../infrastructure/Contexts/HomeViewModelContext";
import TimeSvgIcon from "../svg-icons/TimeSvgIcon";
import WorkSvgIcon from "../svg-icons/WorkSvgIcon";
import ResultCount from "./ResultCount";

export type Props = {
    workData: ExperienceModel[];
}


const Work: React.FC<Props> = ({workData}: Props) => {

    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    return (
        <>
        <Row className="d-flex">
            <ResultCount count={workData.length ?? 0} text={isPrintView ? "Work" : null}/>
        </Row>
        <Row className="work p-0">
            {workData.map(e => 
            <Col className="col-4" key={Math.random()}>
                <Row className="work-card box-shadow bg-white">
                    <Row className="work-title">
                        <Col className="col-auto img-box mt-2">
                            <WorkSvgIcon />
                        </Col>
                        {e.companyName}
                    </Row>
                    <hr/>
                    <Row className="work-data">
                        <Col className="col-auto p-0">
                            <img src="/images/settings.jfif" />
                        </Col>
                        <Col xs="auto" className="work-data-header p-0">Role:</Col>
                        <Col className="px-1">{e.titleRole ?? <span className="not-available">Not Available</span>}</Col>
                    </Row>
                    <Row className="work-data">
                        <Col className="col-auto p-0">
                            <img src="/images/employee-title.jfif" />
                        </Col>
                        <Col xs="auto" className="work-data-header p-0">Title:</Col>
                        <Col className="px-1">{e.titleName ?? <span className="not-available">Not Available</span>}</Col>
                    </Row>
                    <Row className="work-data">
                        <Col className="col-auto img-box-clear mt-2">
                            <TimeSvgIcon />
                        </Col>
                        <Col xs="auto" className="work-data-header p-0">From:</Col>
                        <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                        <Col xs="auto" className="work-data-header p-0">To:</Col>
                        <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                    </Row>

                </Row>
            </Col>)} 
        </Row>
        </>
    );
};

export default Work;
