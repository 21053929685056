import React from "react";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";
import Relative from "./Relative";
import SocialResultSectionTitle from "./SearchResultSectionTitle";

type RelativesProps = {
  relatives: RelativeModel[];
};

const Relatives: React.FC<RelativesProps> = ({ relatives }) => {
  return (
      <div id="__relatives">

          <SocialResultSectionTitle title="Relatives" iconUrl="/images/relatives.svg" />

          {relatives && relatives.length > 0 && (
              <>
                  {" "}
                  {relatives
                      .map((RelativeItem) => (
                          <Relative relative={RelativeItem} key={Math.random()} />
                      ))}
              </>
          )}
          {!relatives ||
              (relatives.length == 0 && (
                  <h2 className="title text-center">No Records found</h2>
              ))}
      </div>
  );
};
export default Relatives;
