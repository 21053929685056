import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SearchDetailsModel from "../../../../domain/entities/search/SearchDetailsModel";
import SocialPlatformProfileModel from "../../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";
import { RootState } from "../../../../infrastructure/redux/reducer";
import HelperMethods from "../../../utils/HelperMethods";
import UserNameFilter from "./UserNameFilter";

type filtersProps = {
    searchRequestId: number,
    platforms: SocialPlatformProfileModel[],
    onFilterChange: (filteredData: SocialPlatformProfileModel[]) => void;
}

export type UserName = { username: string, selected: boolean };

const Filters: React.FC<filtersProps> = (props: filtersProps) => {

    const FORM_RESULT = 'Form-Only Results';
    const OTHER_USER_NAME = 'Other';
    const [state, setState] = useState({ selectedUserNames: '', isAllResult: false, allUserNames: [] as unknown as UserName[], filteredData: props.platforms, formUserNames: [] as unknown as UserName[], _formOnlyUserNames: [] as string[] });

    const searchDetails = useSelector((s: RootState) => s.searchDetails as SearchDetailsModel);

    useEffect(() => {
        const allValidHasDataUserNames = getAllValidHasDataUserNames(props.platforms);
        const _formOnlyUserNames = getValidFormUserNamesHasData(allValidHasDataUserNames, getFormUserNames(searchDetails))
        const _apiUserNames = getValidApiUserNames(props.platforms);
        const allFormUserNames = getAllFormUserNames(_formOnlyUserNames, _apiUserNames);
        run(true, '', allValidHasDataUserNames, allFormUserNames, _formOnlyUserNames);
    }, []);

    useEffect(() => props.onFilterChange(state.filteredData), [state.filteredData]);

    const getAllFormUserNames = (formOnlyUserNames: string[], apiUserNames: string[]): UserName[] => {
        return formOnlyUserNames
            .concat(apiUserNames)
            .filter(HelperMethods.onlyUnique)
            .sort((a: string, b: string) => a.localeCompare(b))
            .map(u => { return { username: u, selected: false } });
    }

    const getValidFormUserNamesHasData = (allValidHasDataUserNames: UserName[], formOnlyUserNames: string[]): string[] => {
        //intersection with valid and has data usernames list
        return allValidHasDataUserNames.filter(all => formOnlyUserNames.includes(all.username)).map(f => f.username);
    }

    const getFormUserNames = (data: SearchDetailsModel): string[] => {
        const formUserNames = HelperMethods.getCopyOf(data.userNames || []);
        data.emails?.forEach(e => formUserNames.push(e.split('@')?.at(0) || ''));
        return formUserNames;
    }

    const getValidApiUserNames = (platforms: SocialPlatformProfileModel[]): string[] => {
        const apiUsernames: string[] = [];
        platforms.forEach(p => {
            p.resultsWithData.forEach(d =>
            {
                const userName = getApiUserName(d);
                if (userName)
                    apiUsernames.push(userName);
            })
            p.resultsWithNoData.forEach(d =>
            {
                const userName = getApiUserName(d);
                if (userName)
                    apiUsernames.push(userName);
            });
        });
        return apiUsernames;
    }

    const getApiUserName = (socialResult: SocialResultModel) => {
        if (isValidUserName(socialResult) && socialResult.rulesBasedValidation?.includes('API'))
            return socialResult.username || OTHER_USER_NAME;
        if (socialResult.rulesBasedValidation?.includes('API'))
            return OTHER_USER_NAME;
        return null;
    }

    const getAllValidHasDataUserNames = (platforms: SocialPlatformProfileModel[]): UserName[] => {
        return platforms
            .map(platform => platform?.resultsWithData?.map(r => r.username && isValidUserName(r) ? r.username : OTHER_USER_NAME)
            .concat(platform?.resultsWithNoData?.map(r => r.username && isValidUserName(r) ? r.username : OTHER_USER_NAME)))
            .flat(1)
            .filter(HelperMethods.onlyUnique)
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((p: string) => { return { username: p, selected: false } });
    }

    const isValidUserName = (socialResult: SocialResultModel): boolean => {
        return Number.isNaN(parseInt(socialResult.username))
            && !socialResult.username?.includes(`${searchDetails.firstName}.${searchDetails.lastName}.`)
            && !socialResult.username?.includes(`${searchDetails.firstName}-${searchDetails.lastName}-`)
            && !socialResult.username?.includes(`${searchDetails.firstName}_${searchDetails.lastName}_`)
            && socialResult.socialPlatform?.name?.toLowerCase() != 'linkedin';
    };

    const filterAllResultData = (media: SocialPlatformProfileModel[], selectedUserNames: string) => {
        if (!selectedUserNames)
            return [];

        return media.map(m =>
        {
            return {
                ...m,
                resultsWithData: m.resultsWithData.filter(d => validUserNameAndSelected(d, selectedUserNames) || notValidUserNameAndOtherIsSelected(d, selectedUserNames)),
                resultsWithNoData: m.resultsWithNoData.filter(d => validUserNameAndSelected(d, selectedUserNames) || notValidUserNameAndOtherIsSelected(d, selectedUserNames))
            }
        });
    }

    const validUserNameAndSelected = (socialResult: SocialResultModel, selectedUserNames: string) => {
        return socialResult.username && isValidUserName(socialResult) && selectedUserNames?.includes(`${socialResult.username},`);
    }

    const notValidUserNameAndOtherIsSelected = (socialResult: SocialResultModel, selectedUserNames: string) => {
        return (!socialResult.username || !isValidUserName(socialResult)) && selectedUserNames?.includes(`${OTHER_USER_NAME},`);
    }

    const filterFormData = (media: SocialPlatformProfileModel[], selectedUserNames: string) => {
        if (!selectedUserNames)
            return [];

        return media.map(m => {
            return {
                ...m,
                resultsWithData: m.resultsWithData.filter(d => isApiResultAndSelected(d, selectedUserNames) || isFormInputUserNameAndSelected(d, selectedUserNames)),
                resultsWithNoData: m.resultsWithNoData.filter(d => isApiResultAndSelected(d, selectedUserNames) || isFormInputUserNameAndSelected(d, selectedUserNames))
            }
        });
    }

    const isApiResultAndSelected = (socialResult: SocialResultModel, selectedUserNames: string) => {
        return socialResult.username && isValidUserName(socialResult) && socialResult.rulesBasedValidation?.includes('API') && selectedUserNames?.includes(`${socialResult.username},`)
            || (!socialResult.username || !isValidUserName(socialResult)) && socialResult.rulesBasedValidation?.includes('API') && selectedUserNames?.includes(`${OTHER_USER_NAME},`);
    }

    const isFormInputUserNameAndSelected = (socialResult: SocialResultModel, selectedUserNames: string) => {
        return socialResult.username && state._formOnlyUserNames.includes(socialResult.username) && selectedUserNames?.includes(`${socialResult.username},`);
    }

    const resetFilters = (isAllResult: boolean, allUseNames: UserName[], formUserNames: UserName[]): string => {
        let selectedUsernames = '';
        allUseNames.forEach(u => u.selected = true);
        formUserNames.forEach(u => u.selected = true);
        isAllResult ? allUseNames.forEach(u => selectedUsernames += `${u.username},`) : formUserNames.forEach(u => selectedUsernames += `${u.username},`);
        return selectedUsernames;
    }

    const run = (isAllResult = state.isAllResult, selectedUserNames = state.selectedUserNames, allUseNames = state.allUserNames, formUserNames = state.formUserNames, _formOnlyUserNames = state._formOnlyUserNames) => {

        const isAllResultChanged = isAllResult != state.isAllResult;
        const originalPlatformsCopy = HelperMethods.getCopyOf(props.platforms);

        if (isAllResultChanged)
            selectedUserNames = resetFilters(isAllResult, allUseNames, formUserNames);

        const filteredData = isAllResult ? filterAllResultData(originalPlatformsCopy, selectedUserNames) : filterFormData(originalPlatformsCopy, selectedUserNames);

        setState({ ...state, selectedUserNames: selectedUserNames, isAllResult: isAllResult, filteredData: filteredData, allUserNames: allUseNames, formUserNames: formUserNames, _formOnlyUserNames: _formOnlyUserNames });
    }

    const isAllResultChange = (e: React.FormEvent) => {
        const checked = (e.currentTarget as HTMLInputElement).checked;
        checked ? run(false) : run(true);
    }

    return (
        <Row className="justify-content-end">
            <Col md="auto"><UserNameFilter userNames={state.isAllResult ? state.allUserNames : state.formUserNames} previousSelectedUserNames={state.selectedUserNames} onFilterChange={(selectedUserNames: string) => run(state.isAllResult, selectedUserNames)} /></Col>
            <Col md="auto" className="form-result-title align-self-center"><span>{FORM_RESULT}</span></Col>
            <Col md="auto" className="p-0 align-self-center"><Form.Switch className="form-result-toggle" onChange={isAllResultChange} /></Col>
        </Row>
    );
}

export default Filters;
