import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import Result from "../../../domain/common/Result";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import React from "react";
import { toast } from "react-toastify";

export default class CommunicationViewModel implements IBaseViewModel {

    public sendSearchRequestsCompletionEmail: boolean;
    public originalSendSearchRequestsCompletionEmail: boolean;
    public isLoading: boolean;
    public isShowError: boolean;
    public disabled: boolean;

    private topic?: string;
    private subject?: Subject<any>;
    private userSettingRepository: UserSettingRepository;

    public constructor(userSettingRepository: UserSettingRepository) {
        this.sendSearchRequestsCompletionEmail = false;
        this.originalSendSearchRequestsCompletionEmail = false;
        this.isLoading = false;
        this.isShowError = false;
        this.userSettingRepository = userSettingRepository;
        this.disabled = true;
    }

    public onChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.sendSearchRequestsCompletionEmail = input.currentTarget.checked;

        if (this.sendSearchRequestsCompletionEmail == this.originalSendSearchRequestsCompletionEmail)
            this.disabled = true;
        else
            this.disabled = false;

        this.notifyViewAboutChanges();
    }

    public onSave = (): void => {
        this.isLoading = true;
        this.notifyViewAboutChanges();
        this.userSettingRepository.updateAccountCommunication(this.sendSearchRequestsCompletionEmail).then(result => {
            this.isShowError = false;
            this.isLoading = false;
            this.disabled = true;
            this.originalSendSearchRequestsCompletionEmail = this.sendSearchRequestsCompletionEmail;
            this.notifyViewAboutChanges();
            this.notify();
        }).catch(e => {
            this.isShowError = true;
            this.isLoading = false;
            this.notifyViewAboutChanges();
        });
    };

    public setValue = (sendSearchRequestsCompletionEmail: boolean) => {
        this.originalSendSearchRequestsCompletionEmail = sendSearchRequestsCompletionEmail;
        this.sendSearchRequestsCompletionEmail = sendSearchRequestsCompletionEmail;
        this.notifyViewAboutChanges();
    }

    public notify = () => {
        toast.success("Updated successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };
   
    private notifyViewAboutChanges = (): void => {

        const data = {
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            disabled: this.disabled,
            sendSearchRequestsCompletionEmail: this.sendSearchRequestsCompletionEmail
        };

        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject;
    };
}
