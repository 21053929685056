import React from "react";
import { Col, Row } from "react-bootstrap";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import { RegisterComponentState } from "../../views/Register";
import TermsOfService from "../../views/TermsAndConditions";

type Props = {
    onNext: () => void;
    introStatement: React.ReactNode;
    state: RegisterComponentState;
    viewModel: RegisterViewModel;
}


const AcceptTerms: React.FC<Props> = ({ state, viewModel, introStatement, onNext }) => {

    return <>
        <div className="registration accept-terms main-page">
            <div className="section-wrapper">
                {introStatement}
                <Row className="current-step"><img className="p-0" src="/images/accept_terms.svg" /></Row>
                <Row>
                    <Col md="auto" className="p-0 section-title">Terms of Service</Col>
                    <Col><hr/></Col>
                </Row>
                <Row>{<TermsOfService collapsed={true} />}</Row>
                <br/>
                <Row className="input-mb">
                    <Col md="auto" className="p-0"><input className="p-0" type="checkbox" id="isTermsOfServiceChecked" onChange={viewModel.onCheckboxChanged} checked={state.isTermsOfServiceChecked} /></Col>
                    <Col><label htmlFor="isTermsOfServiceChecked">I have read and agree to the Terms of Service</label></Col>
                </Row>
                <Row>
                    <Col md="auto" className="p-0 section-title">Privacy Policy</Col>
                    <Col><hr /></Col>
                </Row>
                <Row><PrivacyPolicy collapsed={true} /></Row>
                <br/>
                <Row>
                    <Col md="auto" className="p-0"><input className="p-0" type="checkbox" id="isPrivacyPolicyChecked" onChange={viewModel.onCheckboxChanged} checked={state.isPrivacyPolicyChecked} /></Col>
                    <Col><label htmlFor="isPrivacyPolicyChecked">I have read and agree to the Privacy Policy</label></Col>
                </Row>
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={viewModel.onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto"><button disabled={!state.isTermsOfServiceChecked || !state.isPrivacyPolicyChecked} onClick={onNext} className="btn next-btn">Next &nbsp;&nbsp;&nbsp; <span>&rarr;</span></button></Col>
                </Row>
            </div >
        </div>
    </>
};

export default AcceptTerms;
