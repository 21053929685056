import ChangePasswordModel from "../../../domain/entities/auth/structures/ChangePasswordModel";
import AuthRepository from "../../../domain/repositories/auth/IAuthRepository";
import FormValidator from "../../utils/FormValidator";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";

export default class ChangePasswordViewModel implements IBaseViewModel {
  //#region props
  public password: string;
  public oldPassword: string;
  public confirmPassword: string;
  //#endregion

  public validation: any;
  public isLoading: boolean;
  public isShowError: boolean;
  public isSuccess: boolean;
  public errorMessages: string[];
  public startPasswordType: boolean;

  private topic?: string;
  private subject?: Subject<any>;
  private authRepository: AuthRepository;

  public constructor(authRepository: AuthRepository) {
    this.password = "";
    this.oldPassword = "";
    this.confirmPassword = "";
    this.validation = {};
    this.isLoading = false;
    this.isSuccess = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.startPasswordType = false;
    this.authRepository = authRepository;
  }

  public onQueryChanged = (e: React.FormEvent): void => {
    if (e.currentTarget.id == "oldPassword") {
      const input = e as React.FormEvent<HTMLInputElement>;
      this.oldPassword = input.currentTarget.value;
    }
    if (e.currentTarget.id == "password") {
      this.startPasswordType = true;
      const input = e as React.FormEvent<HTMLInputElement>;
      this.password = input.currentTarget.value;
    }
    if (e.currentTarget.id == "confirmPassword") {
      this.startPasswordType = true;
      const input = e as React.FormEvent<HTMLInputElement>;
      this.confirmPassword = input.currentTarget.value;
    }
    this.notifyViewAboutChanges();
  };

  onChangePasswordChanged(): void {
    this.notifyViewAboutChanges();
  }

  public onClick = async (): Promise<void> => {
    if (!this.validateChangePasswordForm()) {
      this.notifyViewAboutChanges();
      return;
    }

    try {
      const model = {} as ChangePasswordModel;
      model.NewPassword = this.password;
      model.OldPassword = this.oldPassword;

      this.isLoading = true;
      this.notifyViewAboutChanges();

      const result = await this.authRepository.changePassword(model);
      this.isLoading = false;
      this.isSuccess = result.isSuccess;
      this.isShowError = !result.isSuccess;
      this.errorMessages.push(result.error);
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
    }

    this.notifyViewAboutChanges();
  };

  private validateChangePasswordForm = (): boolean => {
    let isShowError = false;
    this.errorMessages = [];

    if (!this.oldPassword) {
      isShowError = true;
      this.validation.password = "old password cannot be empty";
    }
    if (!this.password) {
      isShowError = true;
      this.validation.password = "password cannot be empty";
    }
    if (!FormValidator.isValidPassword(this.password)) {
      isShowError = true;
      this.validation.password = "weak password";
    }
    if (!this.confirmPassword) {
      isShowError = true;
      this.validation.confirmPassword = "confirm password cannot be empty";
    }
    if (this.password !== this.confirmPassword) {
      isShowError = true;
      this.validation.password = "password does not match";
    }

    return !isShowError;
  };

  private notifyViewAboutChanges = (): void => {
    const data = {
      password: this.password,
      oldPassword: this.oldPassword,
      confirmPassword: this.confirmPassword,

      validation: this.validation,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      startPasswordType: this.startPasswordType,
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
  };
}
