import React from "react";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";

type RelativeProps = {
  relative: RelativeModel;
};

const Relative: React.FC<RelativeProps> = ({ relative }) => {
  const { name, age, dateOfBirth, dateOfDeath } =
    relative;

  return (
      <div className="education-box-body">
          <div className="address-inner-content">
              <img
                  src="/images/person.svg"
                  className="output-tab-content-icon"
                  alt="img"
              />
              <p className="address-text">
                  {name.firstName} {name.middleName} {name.lastName}
              </p>
          </div>
          <div className="education-card-body">
              <div className="education-inner-card-body">
                  {dateOfBirth && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">Date Of Birth: </p>
                          <p className="education-inner-text-card-body">{dateOfBirth}</p>
                      </div>
                  )}
                  {age && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">Age: </p>
                          <p className="education-inner-text-card-body">{age}</p>
                      </div>
                  )}
                  {dateOfDeath && (
                      <div className=" education-inner-text-div-card-body">
                          <p className="education-inner-title-card-body">Date Of Death: </p>
                          <p className="education-inner-text-card-body">{dateOfDeath}</p>
                      </div>
                  )}
              </div>
          </div>
      </div>
  );
};
export default Relative;
