import React from "react";
import { Accordion } from 'react-bootstrap';
import EmailSvgIcon from "../../svg-icons/EmailSvgIcon";
import EmailBreachModel from "../../../../domain/entities/searchoutput/EmailBreachModel";


const EmailBreaches: React.FC<{breaches: EmailBreachModel[]}> = (props: {breaches: EmailBreachModel[]}) => {

    return (
        <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
                <Accordion.Header><span className="img-box"><EmailSvgIcon /></span><span className="breaches-title">Email Breaches:</span></Accordion.Header>
                <Accordion.Body>
                    <table>
                        <tr><td className="table-header">Website</td><td className="table-header">Breach Date</td></tr>
                        {props.breaches?.map(breach => <tr key={Math.random()}><td>{breach.website}</td><td>{breach.breachDate.toString().split('T')[0]}</td></tr>)}
                    </table>
                    {props.breaches?.length == 0 && <div className="not-available breaches-not-available">Not Available</div>}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default EmailBreaches;
