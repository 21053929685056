import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, TableChangeState } from "react-bootstrap-table-next";
import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import UserAccountModel from "../../../domain/entities/admin/models/UserAccountModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Modal, Row } from "react-bootstrap";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import AllUsersViewModel from "../../view-model/admin/AllUsersViewModel";
import { TailSpin } from "react-loader-spinner";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

type UsersAccountsListState = {
    statusList: AccountStatusModel[],
    currentPageData: UserAccountModel[]

    searchByUsername: string,
    searchByName: string,
    searchByCompanyName: string,
    searchByStatus: string,

    pageIndex: number,
    pageSize: number,
    totalCount: number,
    isShowError: boolean,
    isLoading: boolean,

    sortDirection: string | null,
    sortField: string | null
};

const AllUsers: React.FC = () => {

    const { viewModel, subscription } = useViewModel(AllUsersViewModel, [new UserAccountApi(new HttpClient())]);
    const [state, setState] = useState<UsersAccountsListState>({
        statusList: viewModel.statusList,
        currentPageData: viewModel.currentPageData,

        searchByUsername: viewModel.searchByUsername,
        searchByName: viewModel.searchByName,
        searchByCompanyName: viewModel.searchByCompanyName,
        searchByStatus: viewModel.searchByStatus,

        pageIndex: viewModel.pageIndex,
        pageSize: viewModel.pageSize,
        totalCount: viewModel.totalCount,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        sortDirection: viewModel.sortDirection,
        sortField: viewModel.sortField
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
              ...state,
              ...d.data,
            });
          });

        viewModel.init();
    }, []);

    
    const columns: ColumnDescription[] = [
        {
            dataField: "username",
            text: "Email",
            formatter: (cell, row) => (<a className="username-link" href={`/admin/user/${row.userId}`}>{cell}</a >),
            sort: true,
        },
        {
            dataField: "name",
            text: "Name",
            formatter: (_,row) => `${row.firstName} ${row.lastName}`,
            sort: true,
        },
        {
            dataField: "companyName",
            text: "Employer Name",
            sort: true,
        },
        {
            dataField: "lastLogin",
            text: "Last Login Date",
            formatter: (cell, row) => row.lastLogin ? new Date(row.lastLogin).toLocaleDateString() : '',
            sort: true,
        },
        {
            dataField: "userId",
            text: "Actions",
            formatter: (c) => (<Row>
                <Col><a href={`/admin/user/${c}/search-requests`} className="btn next-btn">Show Search Requests</a></Col>
                <Col><a href={`/admin/user/${c}/view-permissions`} className="btn next-btn">Show View Permissions</a></Col>
                </Row>),
        }
    ];

    const onTableChange = (type: any, newState: TableChangeState<any>) => 
        {
            if(type === "sort")
                viewModel.updateSorting(newState.sortField, newState.sortOrder);
            else if (type === "pagination")
            viewModel.updatePagination(newState.page - 1, newState.sizePerPage);
        viewModel.updateCurrentPageData();
        };
    const onPageSizeChange = (pageSize: number, page: number) => viewModel.updatePagination(page-1, pageSize);
    const search = () => viewModel.updateSearchData();

    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - All Users</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                className="avoid-nav-bar"
                bodyClassName="toast-message"
            />
            <div>
                <div className="admin-page-title">All Users</div>
                <Row className="all-users-filters">
                    <Row>
                        <Col className=""> <input autoComplete="new-password" id="searchByUsername" placeholder="Email" className="form-control" type="text" value={state.searchByUsername} onChange={viewModel.onQueryChanged} /> </Col>
                        <Col className=""> <input autoComplete="new-password" id="searchByName" placeholder="Name" className="form-control" type="text" value={state.searchByName} onChange={viewModel.onQueryChanged} /> </Col>
                        <Col className=""> <input autoComplete="new-password" id="searchByCompanyName" placeholder="Employer Name" className="form-control" type="text" value={state.searchByCompanyName} onChange={viewModel.onQueryChanged} /> </Col>
                        <Col className=""> <select placeholder="User Account Status" className="form-control" value={state.searchByStatus} onChange={viewModel.onStatusQueryChanged} > <option value={""}>Status</option> {state.statusList.map(s => (<option key={s.id} value={s.name}>{s.name}</option>))} </select> </Col>
                    </Row>
                    <Row>
                        <Col><button className="btn next-btn" onClick={search}>Search</button></Col>
                    </Row>
                </Row>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="caseId"
                        bordered={false}
                        data={state.currentPageData}
                        columns={columns}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: state.pageIndex + 1,
                            sizePerPage: state.pageSize,
                            totalSize: state.totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10},
                                { text: "25", value: 25},
                                { text: "50", value: 50},
                                { text: "100", value: 100},
                              ]
                        })}
                        onTableChange={onTableChange}                        
                    />
                </div>
            </div>
            <TailSpin
              wrapperClass="cover-spin"
              visible={state.isLoading}
              height={50}
              width="50"
              color="#FF5733"
              ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AllUsers;
