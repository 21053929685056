import React from "react";
 
type GoogleSearchProps = { email: string }

const GoogleSearch: React.FC<GoogleSearchProps> = (props: GoogleSearchProps) => {

    const username = props.email.split('@')[0];
    const google_search_url = 'https://www.google.com/search?q="' + username + '"';
    const google_search_url_display = 'google.com/search?q="' + username + '"';
    const google_search_title = 'Run Google Search on Username: ';

    return (
        <div style={{ fontSize: '10pt', paddingLeft: '18px' }}>
            <span style={{ color: 'dodgerblue' }} > {google_search_title} </span>
            <a href={google_search_url} target="_blank" rel="noreferrer" style={{ fontWeight: '700', color: 'black' }} > {google_search_url_display} </a>
        </div>
    );
};
export default GoogleSearch;
