import React from "react";

const EmailSvgIcon: React.FC = () => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 0.375H1.25C0.951631 0.375 0.665483 0.493526 0.454505 0.704505C0.243526 0.915483 0.125 1.20163 0.125 1.5V10.5C0.125 10.7984 0.243526 11.0845 0.454505 11.2955C0.665483 11.5065 0.951631 11.625 1.25 11.625H14.75C15.0484 11.625 15.3345 11.5065 15.5455 11.2955C15.7565 11.0845 15.875 10.7984 15.875 10.5V1.5C15.875 1.20163 15.7565 0.915483 15.5455 0.704505C15.3345 0.493526 15.0484 0.375 14.75 0.375ZM13.5125 1.5L8 5.31375L2.4875 1.5H13.5125ZM1.25 10.5V2.01187L7.67938 6.46125C7.77354 6.52657 7.8854 6.56158 8 6.56157C8.1146 6.56158 8.22647 6.52657 8.32063 6.46125L14.75 2.01187V10.5H1.25Z" fill="#76787B" />
        </svg>
    );
};

export default EmailSvgIcon;