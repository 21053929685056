import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import GlbaDppaChangeRequestModel from "../../../domain/entities/usersettings/GlbaDppaChangeRequestModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import GlbaDppaOptions from "../../components/GlbaDppaOptions";


const GlbaDppaChangeRequest: React.FC = () => {

    const [state, setState] = useState({ repo: new UserSettingApi(new HttpClient()), alreadyRequestedChange: false, glbaSelectedOptionId: 0, dppaSelectedOptionId: 0, error: '', disabled: false, isLoading: true, isShowError: false });

    useEffect(() => {

        state.repo.getGlbaDppaChangeRequest().then(r => {

            const result = r.value as GlbaDppaChangeRequestModel;

            if (!r.isSuccess) {
                setState({ ...state, isShowError: true, isLoading: false });
                return;
            }
                
            if (result?.dppaNewSelectedOptionId && result?.glbaNewSelectedOptionId) {
                const glbaSelectedOptionId: number = result.glbaNewSelectedOptionId || 0;
                const dppaSelectedOptionId: number = result.dppaNewSelectedOptionId || 0;

                setState({
                    ...state,
                    isLoading: false,
                    alreadyRequestedChange: r.isSuccess,
                    glbaSelectedOptionId: glbaSelectedOptionId,
                    dppaSelectedOptionId: dppaSelectedOptionId
                });
            }
            else {

                state.repo.getAccountGlbaDppa().then(g => {
                    const accountResult = g.value as GlbaDppaChangeRequestModel;
                    setState({
                        ...state,
                        isShowError: !g.isSuccess,
                        isLoading: false,
                        glbaSelectedOptionId: accountResult?.glbaNewSelectedOptionId || 0,
                        dppaSelectedOptionId: accountResult?.dppaNewSelectedOptionId || 0
                    });
                } )
            }  
        })
    }, []);

    const onChangeGlba = (id: number) => {
        setState({ ...state, glbaSelectedOptionId: id });
    }

    const onChangeDppa = (id: number) => {
        setState({ ...state, dppaSelectedOptionId: id });
    }

    const onSave = () => {
        setState({ ...state, isLoading: true })
        state.repo.addGlbaDppaChangeRequest({ glbaNewSelectedOptionId: state.glbaSelectedOptionId, dppaNewSelectedOptionId: state.dppaSelectedOptionId })
            .then(r => {
                if (r.isSuccess) {
                    notify();
                    setState({ ...state, disabled: true, isLoading: false, isShowError: !r.isSuccess });
                }
                    
                else
                    setState({ ...state, error: 'Server Error!', isLoading: false, isShowError: !r.isSuccess });
            });
    }

    const notify = () => {
        toast.success("GLBA/DPPA change request is added successfully", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    return (
        <div className="glba-dppa-change-request">
            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                className="avoid-nav-bar"
                bodyClassName="toast-message"
            />
            <GlbaDppaOptions
                glbaSelectedOption={state.glbaSelectedOptionId}
                dppaSelectedOption={state.dppaSelectedOptionId}
                onChangeGlba={(id: number) => onChangeGlba(id)}
                onChangeDppa={(id: number) => onChangeDppa(id)}
                disabled={state.disabled}
            />
            <button disabled={state.disabled} onClick={onSave} className="btn next-btn">Save</button>
            {state.alreadyRequestedChange &&
                <>
                    <div className="glpa-dppa-change-request-modal-card">
                    </div>
                    <div className="glpa-dppa-change-request-modal-body">
                        <Row><p className="modal-intro">Your Request is Under Review</p></Row>
                        <Row><p className="modal-info">You can&apos;t create a new change request while there&apos;s an active request in process. You can come back when the current request is verefied</p></Row>
                    </div>
                </>
            }
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GlbaDppaChangeRequest;
