import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import FormValidator from "../../utils/FormValidator";
import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import { toast } from "react-toastify";
import CompanyDetailsModel, { PointOfContact } from "../../../domain/entities/admin/models/CompanyDetailsModel";
import ICompanyRepo from "../../../domain/repositories/admin/ICompanyRepo";
import IUserAccountRepo from "../../../domain/repositories/admin/IUserAccountRepo";

const ActiveStatus = 1;
const NumberOfRequiredInputs = 14;

export const INVOICE = 'Invoice';
export const NONE = 'None';

export default class AddUpdateCompanyViewModel implements IBaseViewModel {
    public company: CompanyDetailsModel;
    public statusList: AccountStatusModel[];
    public validation: any;
    public domainFormValues: string[];
    public domains: string[];
    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public formIsValid: boolean;
    public subject?: Subject<any>;
    public topic?: string;
    private companyRepository: ICompanyRepo;
    private userAccountRepo: IUserAccountRepo;
    private touchedRequiredInputs: string;
    public billingPocSameAsAccountPoc: boolean;

    public constructor(companyRepository: ICompanyRepo, userAccountRepo: IUserAccountRepo) {
        this.company = { statusId: ActiveStatus, invoiceTypeId: 2 } as CompanyDetailsModel;
        this.statusList = [];
        this.domainFormValues = [""];
        this.domains = [""];
        this.validation = {};
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.formIsValid = false;
        this.companyRepository = companyRepository;
        this.userAccountRepo = userAccountRepo;
        this.touchedRequiredInputs = '';
        this.billingPocSameAsAccountPoc = false;

        this.userAccountRepo.ListAccountStatus().then(s => {
            s.isSuccess && s.value ? this.statusList = s.value : this.isShowError = true;
            this.notifyViewAboutChanges();
        })
    }

    public onCompanyNameChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('companyName') ? this.touchedRequiredInputs += ',companyName' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.name = input.currentTarget.value;

        if (!this.company.name.replaceAll(' ', ''))
            this.validation.companyName = 'Name can not be empty';
        else
            delete this.validation.companyName;

        this.notifyViewAboutChanges();
    }

    public onCompanyAddressChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('companyAddress') ? this.touchedRequiredInputs += ',companyAddress' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.address = input.currentTarget.value;

        if (!this.company.address.replaceAll(' ', ''))
            this.validation.companyAddress = 'Address can not be empty';
        else
            delete this.validation.companyAddress;

        this.notifyViewAboutChanges();
    }

    public onEmployerIdentificationNumberChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.employerIdentificationNumber = input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onCityChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.city = input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onStateChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.state = input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onZipCodeChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.zipCode = input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onDomainAdded = () => {
        let error = false;
        this.validation.domains = [];
        if (this.domainFormValues) {
            let index = 0;
            this.domainFormValues.forEach((formDomain) => {
                if (!formDomain || formDomain.includes(' ')) {
                    error = true;
                    this.validation.domains[index] = "Domain is not valid";
                    this.notifyViewAboutChanges();
                }

                const duplicateIndex = this.validateDuplicates(this.domainFormValues, formDomain);
                if (formDomain.replaceAll(' ', '') && duplicateIndex != -1) {
                    this.validation.domains[this.domainFormValues.lastIndexOf(duplicateIndex)] = "Duplicated domain";
                    error = true;
                    this.notifyViewAboutChanges();
                }
                index++;
            });
        }
        if (!error) {
            this.domainFormValues.push("");
            this.notifyViewAboutChanges();
        }
    }

    public onDomainChanged = (formDomain: string, index: number) => {        
        !this.touchedRequiredInputs.includes('domains') ? this.touchedRequiredInputs += ',domains' : null;
        this.domainFormValues[index] = formDomain;
        this.domains[index] = formDomain;
        this.validateDomains();
        this.notifyViewAboutChanges();
    }

    public onDomainRemoved = (index: number) => {
        this.domains.splice(index, 1);
        this.domainFormValues.splice(index, 1);
        this.validateDomains();
        this.notifyViewAboutChanges();
    }

    private validateDuplicates = (list: string[], element: string) => {
        const checkDuplicates = list.filter(e => e == element);
        if (checkDuplicates?.length <= 1)
            return -1;
        const duplicateIndex = checkDuplicates[checkDuplicates.length - 1];
        return duplicateIndex;
    }

    public validateDomains = () => {
        this.validation.domains = [];
        let isShowError = false;
        if (this.domains) {
            let i = 0;
            this.validation.domains = [];
            this.domains.forEach((domain) => {
                if (!domain.replaceAll(' ', ''))
                    this.validation.domains[i] = "Domain can not be empty";
                if (domain && domain.includes(' ')) {
                    isShowError = true;
                    this.validation.domains[i] = "Domain not valid";
                }

                const duplicateIndex = this.validateDuplicates(this.domainFormValues, domain);
                if (domain.replaceAll(' ', '') && duplicateIndex != -1) {
                    this.validation.domains[this.domainFormValues.lastIndexOf(duplicateIndex)] = "Duplicated domain";
                    isShowError = true;
                }
                i++;
            });
        }
    }

    public onBillingPocSameAsAccountPocChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.billingPocSameAsAccountPoc = input.currentTarget.checked;
        this.company.billingPointOfContact = {} as PointOfContact;

        let firstName = '';
        let lastName = '';
        let email = '';
        let phone = '';

        if (this.billingPocSameAsAccountPoc && this.company.accountPointOfContact) {
            firstName = this.company.accountPointOfContact.firstName;
            lastName = this.company.accountPointOfContact.lastName;
            email = this.company.accountPointOfContact.email;
            phone = this.company.accountPointOfContact.phone;
        }

        this.onBillingPointOfContactFirstNameChange({ currentTarget: { value: firstName } } as React.FormEvent<HTMLInputElement>);
        this.onBillingPointOfContactLastNameChange({ currentTarget: { value: lastName } } as React.FormEvent<HTMLInputElement>);
        this.onBillingPointOfContactEmailChange({ currentTarget: { value: email } } as React.FormEvent<HTMLInputElement>);
        this.onBillingPointOfContactPhoneChange({ currentTarget: { value: phone } } as React.FormEvent<HTMLInputElement>);
    }

    public onAccountPointOfContactNameChange = (e: React.FormEvent) => {
        this.billingPocSameAsAccountPoc ? this.onBillingPointOfContactFirstNameChange(e) : null;

        !this.touchedRequiredInputs.includes('accountPointOfContactName') ? this.touchedRequiredInputs += ',accountPointOfContactName' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.accountPointOfContact)
            this.company.accountPointOfContact = {} as PointOfContact;
        this.company.accountPointOfContact.firstName = input.currentTarget.value;

        if (!this.company.accountPointOfContact.firstName.replaceAll(' ', ''))
            this.validation.accountPointOfContactName = 'First Name can not be empty';
        else
            delete this.validation.accountPointOfContactName;

        this.notifyViewAboutChanges();
    }

    public onAccountPointOfContactLastNameChange = (e: React.FormEvent) => {
        this.billingPocSameAsAccountPoc ? this.onBillingPointOfContactLastNameChange(e) : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.accountPointOfContact)
            this.company.accountPointOfContact = {} as PointOfContact;
        this.company.accountPointOfContact.lastName = input.currentTarget.value;
        this.notifyViewAboutChanges();
    }


    public onAccountPointOfContactEmailChange = (e: React.FormEvent) => {
        this.billingPocSameAsAccountPoc ? this.onBillingPointOfContactEmailChange(e) : null;

        !this.touchedRequiredInputs.includes('accountPointOfContactEmail') ? this.touchedRequiredInputs += ',accountPointOfContactEmail' : null;

        const input = e as React.FormEvent<HTMLInputElement>;

        if (!this.company.accountPointOfContact)
            this.company.accountPointOfContact = {} as PointOfContact;
        this.company.accountPointOfContact.email = input.currentTarget.value;

        if (!this.company.accountPointOfContact.email.replaceAll(' ', ''))
            this.validation.accountPointOfContactEmail = 'Email can not be empty';

        else if (!FormValidator.isValidEmail(this.company.accountPointOfContact.email))
            this.validation.accountPointOfContactEmail = "Email is not valid";
        else
            delete this.validation.accountPointOfContactEmail;

        this.notifyViewAboutChanges();
    }

    public onAccountPointOfContactPhoneChange = (e: React.FormEvent) => {
        this.billingPocSameAsAccountPoc ? this.onBillingPointOfContactPhoneChange(e) : null;

        !this.touchedRequiredInputs.includes('accountPointOfContactPhone') ? this.touchedRequiredInputs += ',accountPointOfContactPhone' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.accountPointOfContact)
            this.company.accountPointOfContact = {} as PointOfContact;
        this.company.accountPointOfContact.phone = input.currentTarget.value;

        if (!this.company.accountPointOfContact.phone.replaceAll(' ', ''))
            this.validation.accountPointOfContactPhone = 'Phone can not be empty';

        else if (!FormValidator.isValidRegistrationPhone(this.company.accountPointOfContact.phone))
            this.validation.accountPointOfContactPhone = "Phone is not valid";
        else
            delete this.validation.accountPointOfContactPhone;

        this.notifyViewAboutChanges();
    }

    public onBillingPointOfContactFirstNameChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('billingPointOfContactFirstName') ? this.touchedRequiredInputs += ',billingPointOfContactFirstName' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.billingPointOfContact)
            this.company.billingPointOfContact = {} as PointOfContact;
        this.company.billingPointOfContact.firstName = input.currentTarget.value;

        if (!this.company.billingPointOfContact.firstName.replaceAll(' ', ''))
            this.validation.billingPointOfContactFirstName = 'First Name can not be empty';
        else
            delete this.validation.billingPointOfContactFirstName;

        this.notifyViewAboutChanges();
    }

    public onBillingPointOfContactLastNameChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('billingPointOfContactLastName') ? this.touchedRequiredInputs += ',billingPointOfContactLastName' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.billingPointOfContact)
            this.company.billingPointOfContact = {} as PointOfContact;
        this.company.billingPointOfContact.lastName = input.currentTarget.value;

        if (!this.company.billingPointOfContact.lastName.replaceAll(' ', ''))
            this.validation.billingPointOfContactLastName = 'Last Name can not be empty';
        else
            delete this.validation.billingPointOfContactLastName;

        this.notifyViewAboutChanges();
    }

    public onBillingPointOfContactEmailChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('billingPointOfContactEmail') ? this.touchedRequiredInputs += ',billingPointOfContactEmail' : null;

        const input = e as React.FormEvent<HTMLInputElement>;

        if (!this.company.billingPointOfContact)
            this.company.billingPointOfContact = {} as PointOfContact;
        this.company.billingPointOfContact.email = input.currentTarget.value;

        if (!this.company.billingPointOfContact.email.replaceAll(' ', ''))
            this.validation.billingPointOfContactEmail = 'Email can not be empty';

        else if (!FormValidator.isValidEmail(this.company.billingPointOfContact.email))
            this.validation.billingPointOfContactEmail = "Email is not valid";
        else
            delete this.validation.billingPointOfContactEmail;

        this.notifyViewAboutChanges();
    }

    public onBillingPointOfContactPhoneChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('billingPointOfContactPhone') ? this.touchedRequiredInputs += ',billingPointOfContactPhone' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        if (!this.company.billingPointOfContact)
            this.company.billingPointOfContact = {} as PointOfContact;
        this.company.billingPointOfContact.phone = input.currentTarget.value;

        if (!this.company.billingPointOfContact.phone.replaceAll(' ', ''))
            this.validation.billingPointOfContactPhone = 'Phone can not be empty';

        else if (!FormValidator.isValidRegistrationPhone(this.company.billingPointOfContact.phone))
            this.validation.billingPointOfContactPhone = "Phone is not valid";
        else
            delete this.validation.billingPointOfContactPhone;

        this.notifyViewAboutChanges();
    }

    public onBillingEmailChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.billingEmail = input.currentTarget.value;

        if (this.company.billingEmail && !FormValidator.isValidEmail(this.company.billingEmail))
            this.validation.billingEmail = "Email is not valid";
        else
            delete this.validation.billingEmail;

        this.notifyViewAboutChanges();
    }

    public onBillingAddressChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.billingAddress = input.currentTarget.value;

        this.notifyViewAboutChanges();
    }

    public onBillingCityChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.billingCity = input.currentTarget.value;

        this.notifyViewAboutChanges();
    }

    public onBillingStateChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.billingState = input.currentTarget.value;

        this.notifyViewAboutChanges();
    }

    public onBillingZipCodeChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.billingZipCode = input.currentTarget.value;

        this.notifyViewAboutChanges();
    } 

    public onSalesRepresentativeNameChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('salesRepresentitaveName') ? this.touchedRequiredInputs += ',salesRepresentitaveName' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.salesRepresentitaveName = input.currentTarget.value;

        if (!this.company.salesRepresentitaveName.replaceAll(' ', ''))
            this.validation.salesRepresentitaveName = 'Name can not be empty';

        else
            delete this.validation.salesRepresentitaveName;

        this.notifyViewAboutChanges();
    }

    public onSalesRepresentativeEmailChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('salesRepresentitaveEmail') ? this.touchedRequiredInputs += ',salesRepresentitaveEmail' : null;

        const input = e as React.FormEvent<HTMLInputElement>;

        this.company.salesRepresentitaveEmail = input.currentTarget.value;

        if (!this.company.salesRepresentitaveEmail.replaceAll(' ', ''))
            this.validation.salesRepresentitaveEmail = 'Email can not be empty';

        if (!FormValidator.isValidEmail(this.company.salesRepresentitaveEmail))
            this.validation.salesRepresentitaveEmail = "Email is not valid";
        else
            delete this.validation.salesRepresentitaveEmail;

        this.notifyViewAboutChanges();
    }

    public onPricePerSearchChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('pricePerSearch') ? this.touchedRequiredInputs += ',pricePerSearch' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.pricePerSearch = +input.currentTarget.value;

        if (!this.company.pricePerSearch)
            this.validation.pricePerSearch = 'Price/Search can not be empty';
        else
            delete this.validation.pricePerSearch;

        this.notifyViewAboutChanges();
    }

    public onStatusChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.statusId = +input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onInvoiceTypeChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.invoiceTypeId = +input.currentTarget.value;
        this.notifyViewAboutChanges();
    }

    public onSignedChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.signedContract = input.currentTarget.checked;
        this.notifyViewAboutChanges();
    }

    public onNetsuiteExternalIdChange = (e: React.FormEvent) => {
        !this.touchedRequiredInputs.includes('netsuiteExternalId') ? this.touchedRequiredInputs += ',netsuiteExternalId' : null;

        const input = e as React.FormEvent<HTMLInputElement>;
        this.company.netsuiteExternalId = input.currentTarget.value;

        if (!this.company.netsuiteExternalId.replaceAll(' ', ''))
            this.validation.netsuiteExternalId = 'Netsuite External ID can not be empty';
        else
            delete this.validation.netsuiteExternalId;

        this.notifyViewAboutChanges();
    }


    public onDataChanged = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

    public getData = (companyId: number) => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            this.companyRepository.getCompany(companyId).then(result => {

                if (result.isSuccess) {
                    this.company = result.value as CompanyDetailsModel ?? {} as CompanyDetailsModel;
                    this.domainFormValues = this.company.domains.split(',');
                    this.domains = this.company.domains.split(',');
                }

                this.isLoading = false;
                this.isShowError = !result.isSuccess;
                this.errorMessages.push(result.error);
                this.notifyViewAboutChanges();
            });
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    };

    public saveChanges = async () => {

        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            let result;

            this.company.domains = this.domains[0];
            if (this.domains.length > 1)
                for (let i = 1; i < this.domains.length; i++)
                    this.company.domains += "," + this.domains[i];

            if (this.company?.id)
                result = await this.companyRepository.updateCompany(this.company);
            else
                result = await this.companyRepository.addCompany(this.company);

            this.isLoading = false;

            if (result.isSuccess) {
                !this.company.id ? this.reset() : null;
                this.notify();
            }

            this.notifyViewAboutChanges();
        }
        catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    }

    public reset = () => {
        this.formIsValid = false;
        this.validation = {};
        this.errorMessages = [];
        this.notifyViewAboutChanges();
    }

    public notify = () => {
        toast.success(`Saved Successfully`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    public allRequiredInputsAreTouched = () => this.touchedRequiredInputs.split(',')?.length - 1 == NumberOfRequiredInputs;

    private validateRegisterForm = () => {
        if (!this.company.id && this.allRequiredInputsAreTouched() && (Object.keys(this.validation).length == 1 && this.validation.domains && this.validation.domains?.length == 0))
            this.formIsValid = true;
        else if (this.company.id && (Object.keys(this.validation).length == 0 || Object.keys(this.validation).length == 1 && this.validation.domains && this.validation.domains?.length == 0))
            this.formIsValid = true;
        else
            this.formIsValid = false;
    };

    private notifyViewAboutChanges = () => {
        this.validateRegisterForm();
        const data = {
            company: this.company,
            statusList: this.statusList,
            domainFormValues: this.domainFormValues,
            validation: this.validation,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            formIsValid: this.formIsValid,
            billingPocSameAsAccountPoc: this.billingPocSameAsAccountPoc
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string) {
        this.subject = subject;
        this.topic = topicName;
    }

}
