import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import RegistrationDataRepository from '../../domain/repositories/registrationData/IRegistrationDataRepo';
import GlbaDppaOptionsModel from '../../domain/entities/auth/models/GlbaDppaOptionsModel';
import Question from '../../domain/entities/usersettings/Question';

export default class RegistrationDataApi implements RegistrationDataRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    Questions = (): Promise<Result<Question[]>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/GenericData/Questions`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<Question[]>(await res.json());
            else if (res.status == 401)
                return Result.Fail<Question[]>('Unauthorized', 401);

            return Result.Fail<Question[]>('Server Error', res.status);

        }).catch(e => {
            return Result.Fail<Question[]>(e.message, 500);
        });
    }

    GetGlbaDppaData = (): Promise<Result<GlbaDppaOptionsModel>> => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch('/api/GenericData/DPPAGLBA', requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaOptionsModel>(await res.json());
                return Result.Fail<GlbaDppaOptionsModel>("Server Error", res.status);
            })
            .catch((e) => {
                return Result.Fail<GlbaDppaOptionsModel>(e.message, 500);
            });
    };
}
