import React, { useEffect, useState } from "react";
import SearchViewModel from "../../view-model/search/SearchViewModel";
import "react-datepicker/dist/react-datepicker.css";
import { TailSpin } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import SuccessAlert from "../../components/SuccessAlert";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import SearchApi from "../../../data/search/SearchApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useLocation } from "react-router";
import FloatingLabelControl from "../../components/FloatingLabelControl";
import { Steam } from "react-bootstrap-icons";
import CreateSearch from "./CreateSearch";
type SearchComponentState = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ageRangeFrom: string;
  ageRangeTo: string;
  dateOfLoss: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  employer: string;
  caseId: string;
  newCaseName: string;
  email: string[];
  phone: string[];
  emailFormValues: string[];
  phoneFormValues: string[];

  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: string[];
  isSuccess: boolean;
};

const CreateSearchPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Social E-Profiler - New Search</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>
      <CreateSearch />
    </>
  );
};

export default CreateSearchPage;
