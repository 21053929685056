import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import CompanyApi from "../../../data/admin/CompanyApi";
import AccountRequestApi from "../../../data/admin/AccountRequestApi";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";
import PendingAccountRequestDetailsModel from "../../../domain/entities/admin/models/PendingAccountRequestDetailsModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import AccountRequestViewModel from "../../view-model/admin/AccountRequestViewModel";
import LinkedToCompany from "../../components/person-info-edit/LinkedToCompany";

type AccountRequestState = {
    accountRequest: PendingAccountRequestDetailsModel;
    companiesNames: CompanyNameModel[];
    autoDetectedCompanyId: number;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    showAccountRequestApprovalModal: boolean;
    showAccountRequestDenialModal: boolean;
    denialReason: string;
    denialNote: string;
    actionSuccedded: boolean;
    disabledBtns: boolean;
    validation: any;
};

const AccountRequest: React.FC = () => {

    const { id } = useParams();

    const { viewModel, subscription } = useViewModel(AccountRequestViewModel, [
        new AccountRequestApi(new HttpClient()),
        new CompanyApi(new HttpClient())
    ]);
    
    const [state, setState] = useState<AccountRequestState>({
        accountRequest: viewModel.accountRequest,
        companiesNames: viewModel.companiesNames,
        autoDetectedCompanyId: viewModel.autoDetectedCompanyId,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        showAccountRequestApprovalModal: false,
        showAccountRequestDenialModal: false,
        denialReason: '',
        denialNote: '',
        actionSuccedded: false,
        disabledBtns: true,
        validation: {}
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });

        viewModel.getData(id ? +id : 0);

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return (
        <div className="container">
            <ToastContainer
                position="top-center"
                autoClose={false}
                hideProgressBar
                newestOnTop={false}
                limit={1}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                className="avoid-nav-bar"
                bodyClassName="toast-message"
            />
            <div className="account-info-details">
                <Row><span className="admin-page-title"><a href="/admin/pendingRegistration"><BackArrowSvgIcon /></a>&nbsp;&nbsp;Account Request</span></Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Personal Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">First Name</span></Col>
                            <Col><span className="account-request-title">Last Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.firstName}</span></Col>
                            <Col><span className="value">{state.accountRequest.lastName}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Phone</span></Col>
                            <Col><span className="account-request-title">Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.phoneNumber}</span></Col>
                            <Col><span className="value">{state.accountRequest.email}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Alternative Contact</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">First Name</span></Col>
                            <Col><span className="account-request-title">Last Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.alternativeFirstName || '--'}</span></Col>
                            <Col><span className="value">{state.accountRequest.alternativeLastName || '--'}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Phone</span></Col>
                            <Col><span className="account-request-title">Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.alternativePhoneNumber || '--'}</span></Col>
                            <Col><span className="value">{state.accountRequest.alternativeEmail || '--'}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Account/Password Recovery</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Security Question</span></Col>
                            <Col><span className="account-request-title">Answer</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.securityQuestion}</span></Col>
                            <Col><span className="value">{state.accountRequest.answer}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Secondary Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.secondaryEmail}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Company Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Manager Name</span></Col>
                            <Col><span className="account-request-title">Employer Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.managerName}</span></Col>
                            <Col><span className="value">{state.accountRequest.employerName}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Employer Address</span></Col>
                            <Col><span className="account-request-title">Employer City</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.employerAddress}</span></Col>
                            <Col><span className="value">{state.accountRequest.employerCity}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Employer State</span></Col>
                            <Col><span className="account-request-title">Employer Address Zip Code</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.employerState}</span></Col>
                            <Col><span className="value">{state.accountRequest.employerZIPCode}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Claim Office</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.claimOffice}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">GLBA/DPPA</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">GLBA</span></Col>
                            <Col><span className="account-request-title">DPPA</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{state.accountRequest.glbaOption}</span></Col>
                            <Col><span className="value">{state.accountRequest.dppaOption}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <LinkedToCompany selectedCompanyId={state.autoDetectedCompanyId} onChange={viewModel.onCompanyChanged} disabled={state.actionSuccedded} companyNames={state.companiesNames} />
                <Row className="account-request-btns d-flex">
                    <button disabled={state.actionSuccedded} className="btn previous-btn" onClick={() => viewModel.onDenyBtnClick()}>Deny</button>
                    <button disabled={state.actionSuccedded || state.disabledBtns}  className="btn next-btn" onClick={() => viewModel.onApproveBtnClick()}>Approve</button>
                </Row>
            </div>
            <Modal show={state.showAccountRequestApprovalModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Approve Account</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideAccountRequestApprovalModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to approve this account?</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideAccountRequestApprovalModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={viewModel.onAccountRequestApprove} className="btn next-btn">Approve</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={state.showAccountRequestDenialModal}>
                <Modal.Body className="modal-card deny-account-modal-card">
                    <div className="deny-account-modal">
                        <Row className="approve-modal-header">
                            <Col ><span className="cancel-modal-title">Deny Account</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideAccountRequestDenialModal}>x</span></Col>
                        </Row>
                        <Row><span className="row-title">Denial Reason</span></Row>
                        <Row><textarea className="form-control" id="denialReason" value={state.denialReason} onChange={viewModel.onDataChanged} /></Row>
                        <Row><span className="row-title">Note</span></Row>
                        <Row><textarea className="form-control" id="denialNote" value={state.denialNote} onChange={viewModel.onDataChanged} /></Row>
                        <Row className="deny-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideAccountRequestDenialModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={viewModel.onAccountRequestDeny} className="btn next-btn">Confirm Denial</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AccountRequest;