import React from "react";
import { Col, Row } from "react-bootstrap";
import AddUpdateCompanyViewModel from "../../view-model/admin/AddUpdateCompanyViewModel";
import { CompanyState } from "../../views/admin/AddUpdateCompany";


type Props = {
    state: CompanyState,
    viewModel: AddUpdateCompanyViewModel
}

const BillingInfo: React.FC<Props> = ({ state, viewModel }) => {

    const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Billing Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Name"
                    name="billingEmail"
                    id="billingEmail"
                    onChange={viewModel.onBillingEmailChange}
                    value={state.company.billingEmail}
                    className={`form-control ${state.validation.billingEmail ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email</label>
                {state.validation.billingEmail && (
                    <div className="invalid-feedback">
                        {state.validation.billingEmail}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Address"
                    name="billingAddress"
                    id="billingAddress"
                    onChange={viewModel.onBillingAddressChange}
                    value={state.company.billingAddress}
                    className={`form-control ${state.validation.billingAddress ? "is-invalid" : ""}`}
                />
                <label className="form-label">Address</label>
                {state.validation.billingAddress && (
                    <div className="invalid-feedback">
                        {state.validation.billingAddress}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="City"
                    name="city"
                    id="city"
                    onChange={viewModel.onBillingCityChange}
                    value={state.company.billingCity}
                    className={`form-control ${state.validation.billingCity ? "is-invalid" : ""}`}
                />
                <label className="form-label">City</label>
                {state.validation.billingCity && (
                    <div className="invalid-feedback">
                        {state.validation.billingCity}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <select
                    name="state"
                    id="state"
                    onChange={viewModel.onBillingStateChange}
                    value={state.company.billingState}
                    className={`form-control ${state.validation.billingState ? "is-invalid" : ""}`}>
                    <option value={0}></option>
                    {states.map(s => <option key={Math.random()} value={s}>{s}</option>)}

                </select>
                <label className="form-label">State</label>
                {state.validation.billingState && (
                    <div className="invalid-feedback">
                        {state.validation.billingState}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input" md="6">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Address Zip Code"
                    name="zipCode"
                    id="zipCode"
                    onChange={viewModel.onBillingZipCodeChange}
                    value={state.company.billingZipCode}
                    className={`form-control ${state.validation.billingZipCode ? "is-invalid" : ""}`}
                />
                <label className="form-label">Address Zip Code</label>
                {state.validation.billingZipCode && (
                    <div className="invalid-feedback">
                        {state.validation.billingZipCode}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default BillingInfo;
