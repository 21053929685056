const translations: {[key: string]: string} = {
    WelcomMessage: "Welcome to Home",
    NotAllowedAccess: "User not allowed to access this resource",
    NotFoundAccess: "Resource not found",
    ServerError: "Server Error please contact our admin",
    ListingErrorHeaderMessage: "Retrieve data error",
    ListingErrorBodyMessage: "Please check your connection.",
    ChangePasswordHeaderMessage: "Change Password Successfully.",
    ChangePasswordBodyMessage: "Now you can login to your account using the new password.",
    ChangePasswordErrorrBodyMessage: "The password you entered might not be correct.",
    ForgetPasswordHeaderMessage: "Forget Password Successfully.",
    ForgetPasswordBodyMessage: "Please check your email inbox to change your password.",
    RegistrationHeaderMessage: "Registration Successfully.",
    RegistrationBodyMessage: "Please check your email inbox to activate your account.",
    ResetPasswordHeaderMessage: "Reset Password Successfully.",
    ResetPasswordBodyMessage: "Now you can login to your account using the new password.",
    SearchHeaderMessage: "Search Request in progress.",
    SearchBodyMessage: "Will notify you via email once the search is done.",
    UserProfileHeaderMessage: "Profile updated Successfully.",
    UserProfileBodyMessage: "Now you can check your new settings.",
    GenericErrorHeaderMessage: "Error.",
    GenericErrorBodyMessage: "Please check your connection.",
    ConfirmEmailErrorHeaderMessage: "Error.",
    ConfirmEmailErrorBodyMessage: "link is not valid.",
    ConfirmEmailHeaderMessage: "Email has been confirmed.",
    ConfirmEmailBodyMessage: "you can login now.",
}

export default translations;