import React, { useContext, useEffect, useState } from "react";
import { Row } from 'react-bootstrap';
import ResultCount from "../ResultCount";
import SocialPlatformProfileModel from "../../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import HomeViewModelContext from "../../../../infrastructure/Contexts/HomeViewModelContext";
import SocialResultsWithData from "./SocialResultWithData";
import SocialResultsWithNoData from "./SocialResultWithNoData";
import SocialSearchResult from "./SocialSearchResult";
import Filters from "./Filters";
import HelperMethods from "../../../utils/HelperMethods";
import MasonryView from "../../MasonryView";

export type Props = {
    platforms: SocialPlatformProfileModel[];
    searchRequestId: number;
}


const SocialResults: React.FC<Props> = ({ platforms, searchRequestId }: Props) => {

    const [platformsToDisplay, setPlatformsToDisplay] = useState(platforms);
    const isPrintView = useContext(HomeViewModelContext)?.isPrintView ?? false;

    const changeDisplayedData = (filtered: SocialPlatformProfileModel[]) => setPlatformsToDisplay(filtered);
    
    return (
        <div className="social-results">
            <Row>
                <ResultCount count={platformsToDisplay.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) ?? 0} text={isPrintView ? "Social Results" : null}>
                    <Filters searchRequestId={searchRequestId} platforms={platforms} onFilterChange={changeDisplayedData} />
                </ResultCount>
            </Row>

            <div className="social-results-container">
                <MasonryView columnCount={3}>
                    {HelperMethods.getCopyOf(platformsToDisplay).map(p => {

                        const platformIconUrl = p.socialPlatform?.iconUrl ?? "/icons/default-social-icon.svg";
                        const searchResult = p.resultsWithNoData?.find(r => r.url.includes('https://www.facebook.com/search/people?'));

                        if(searchResult)
                            p.resultsWithNoData = p.resultsWithNoData.filter(r => !r.url.includes('https://www.facebook.com/search/people?'));                                    

                        return (                    
                        <>
                            {p.resultsWithData && p.resultsWithData.length > 0 && p.resultsWithData.map(r => 
                                <SocialResultsWithData result={r} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()}/>
                            )}
                            
                            {p.resultsWithNoData && p.resultsWithNoData.length > 0 && 
                                <SocialResultsWithNoData results={p.resultsWithNoData} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()}/>
                            }

                            {searchResult && (
                                <SocialSearchResult result={searchResult} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()}/>
                            )}
                        </>);
                    })}
                </MasonryView>
                
            </div>            

        </div>
    );
};

export default SocialResults;
