import * as actionTypes from '../actions/actionTypes';

const initialState: ErrorState = {
    errorCode: null
};

const reducer = (state: ErrorState = initialState, action: ActionType): ErrorState => {
    switch (action.type) {
        case actionTypes.SET_ERROR:
            return {
                ...state,
                errorCode: action.payload.errorCode
            };

        default: return state;
    }
};

export default reducer;