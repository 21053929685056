import React, { useEffect, useState } from "react";


type Props = {
    details: any;
}

const RegistrationResponse: React.FC<Props> = ({ details }: Props) => {

    const isApproved = details["IsApprove"];
    const denyReason = details["DenyReason"];
    const denyNote = details["DenyNote"];

    return (
        <div>
            <div className={`admin-response ${isApproved?"approve":"deny"}`} >{isApproved?"Approved":"Denied"}</div>
            <div>{denyReason && (<><b>Deny Reason : </b> {denyReason} </>) }</div>
            <div>{denyNote && (<><b>Deny Note : </b> {denyNote} </>) }</div>
        </div>
    );
};

export default RegistrationResponse;
