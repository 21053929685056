import React from "react";

const AddressSvgIcon: React.FC = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.72782 11.5321L7.00001 17.75L12.2744 11.5295C12.3023 11.4964 12.4898 11.2499 12.4898 11.2499L12.4908 11.2483C13.391 10.0624 13.8772 8.61389 13.875 7.125C13.875 5.30164 13.1507 3.55295 11.8614 2.26364C10.5721 0.974328 8.82337 0.25 7.00001 0.25C5.17664 0.25 3.42796 0.974328 2.13865 2.26364C0.849335 3.55295 0.125007 5.30164 0.125007 7.125C0.122896 8.6145 0.609508 10.0636 1.51019 11.2499C1.51019 11.2499 1.69788 11.4964 1.72782 11.5321ZM2.53789 10.5364C2.52003 10.5128 2.508 10.497 2.50782 10.4969C1.77156 9.52708 1.37362 8.34261 1.37501 7.125C1.37501 5.63316 1.96764 4.20242 3.02253 3.14752C4.07742 2.09263 5.50816 1.5 7.00001 1.5C8.49185 1.5 9.92259 2.09263 10.9775 3.14752C12.0324 4.20242 12.625 5.63316 12.625 7.125C12.6264 8.34195 12.2288 9.5258 11.493 10.4951C11.4927 10.4956 11.3462 10.6892 11.3188 10.7237L7.00001 15.8174L2.68694 10.7309C2.66321 10.7013 2.58242 10.595 2.53789 10.5364ZM5.26384 9.72334C5.77775 10.0667 6.38194 10.25 7 10.25C7.82851 10.2491 8.62282 9.91951 9.20867 9.33367C9.79451 8.74782 10.1241 7.95351 10.125 7.125C10.125 6.50694 9.94172 5.90275 9.59834 5.38884C9.25496 4.87494 8.76691 4.4744 8.19589 4.23788C7.62487 4.00135 6.99653 3.93947 6.39034 4.06005C5.78415 4.18063 5.22733 4.47825 4.79029 4.91529C4.35325 5.35233 4.05563 5.90915 3.93505 6.51534C3.81447 7.12153 3.87635 7.74987 4.11288 8.32089C4.3494 8.89191 4.74994 9.37996 5.26384 9.72334ZM5.95831 5.566C6.26665 5.35997 6.62916 5.25 7 5.25C7.49711 5.25055 7.97371 5.44827 8.32522 5.79978C8.67674 6.15129 8.87446 6.62789 8.875 7.125C8.875 7.49584 8.76503 7.85835 8.55901 8.1667C8.35298 8.47504 8.06014 8.71536 7.71753 8.85728C7.37492 8.99919 6.99792 9.03632 6.63421 8.96397C6.27049 8.89163 5.9364 8.71305 5.67418 8.45083C5.41195 8.1886 5.23338 7.85451 5.16103 7.4908C5.08868 7.12708 5.12581 6.75008 5.26773 6.40747C5.40964 6.06486 5.64996 5.77202 5.95831 5.566Z" fill="#76787B" />
        </svg>
    );
};

export default AddressSvgIcon;
