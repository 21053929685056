import React from 'react';
import { Alert } from "react-bootstrap";
import useTranslation from "../../infrastructure/hooks/useTranslate";

type ErrorAlertProps = {
    headerMessage: string,
    bodyMessage: string
}

function ErrorAlert (props: ErrorAlertProps){
        const {t} = useTranslation();
        return (
            <Alert variant="danger">
                <Alert.Heading>{t(props.headerMessage)}</Alert.Heading>
                <p>
                    {t(props.bodyMessage)}
                </p>
            </Alert>
        );
}
export default ErrorAlert;