import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import GlbaDppaOptions from "../../components/GlbaDppaOptions";
import RegisterViewModel from "../../view-model/auth/RegisterViewModel";
import { RegisterComponentState } from "../../views/Register";

type Props = {
    onPrevious: () => void;
    introStatement: React.ReactNode;
    state: RegisterComponentState;
    viewModel: RegisterViewModel;
}

const GlbaDppa: React.FC<Props> = ({ state, viewModel, introStatement, onPrevious }) => {

    return <>
        <div className="registration glba-dppa main-page">
            <div className="section-wrapper">
                <Row className="ml-n-25px">{introStatement}</Row>
                <Row className="current-step"><img className="p-0" src="/images/glba_dppa.svg" /></Row>
                <Row>
                    <GlbaDppaOptions
                        glbaSelectedOption={state.GLBAOptionId}
                        dppaSelectedOption={state.DPPAOptionId}
                        onChangeGlba={(id: number) => viewModel.onInputChanged('GLBAOptionId', id)}
                        onChangeDppa={(id: number) => viewModel.onInputChanged('DPPAOptionId', id)}
                    />
                </Row>
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={viewModel.onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto">
                        <a onClick={onPrevious} className="btn previous-btn"><span>&larr;</span> &nbsp;&nbsp;&nbsp; Previous</a>
                        <a className="btn next-btn" onClick={(): Promise<void> => viewModel.onClickRegister()}>Register</a>
                    </Col>
                </Row>
            </div>
            <Modal show={state.showGlbaDppaWarningModal}>
                <Modal.Body className="modal-card warning-modal-card">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Warning</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideGlbaDppaWarningModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">You have selected{state.glbaDppaSelectedOptionWarning}that won&apos;t let you benefit from the search requests featue. Are you sure you want to continue?</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideGlbaDppaWarningModal} className="btn previous-btn">No, I will change my selection</a></Col>
                            <Col className="p-0"><a onClick={viewModel.register} className="btn next-btn">Yes, I want to continue</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
};

export default GlbaDppa;
